import React from 'react';

import { Table, Input, Button, Space, Popconfirm, Spin, Skeleton, Select } from 'antd';
import { SearchOutlined, EditTwoTone, DeleteTwoTone, ReloadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import { StoreProductContext } from '../../context/StoreProductcontext';

import { delete_store_product_api } from '../../handler/api_handler';
const { Option } = Select;

const columns = [
	{
		title: 'Id',
		dataIndex: 'id'
	},
	{
		title: 'Name',
		dataIndex: 'name'
	},
	{
		title: 'unit',
		dataIndex: 'unit_name'
	}
];

class ProductStoreComponent extends React.Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};
	static contextType = StoreProductContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [ e.target.value ] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
					>
						Search
					</Button>
					<Button type="danger" onClick={() => this.handleReset(clearFilters)}>
						<ReloadOutlined />
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[ this.state.searchText ]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		const { classification, setStoreProductData } = this.context;

		delete_store_product_api(id)
			.then(() => {
				setStoreProductData(classification);
			})
			.catch(() => {});
	};

	render() {
		const { StoreProduct, loading, changeClassification, setRoleData, setVisibles, visible } = this.context;
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name'
			},
			{
				title: 'unit',
				dataIndex: 'unit_name'
			},

			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<Popconfirm
							title="Are you sure？"
							onConfirm={() => this.handleOk(record.id)}
							okText="Yes"
							cancelText="No"
						>
							<DeleteTwoTone twoToneColor="#d11a2a" style={{ fontSize: '20px' }} />
						</Popconfirm>
					</Space>
				)
			}
		];
		return (
			<React.Fragment>
				<div className="main_top">
					<h2>Product List</h2>
					<div className="bottom_border" />
					<Select
						defaultValue="0"
						onChange={changeClassification}
						style={{ width: '120', float: 'right' }}
					>
						<Option value="0">All Product</Option>
						<Option value="3">Raw Material</Option>
						<Option value="2">Maintenance Product</Option>
						<Option value="4">Vegetable</Option>
					</Select>
				</div>
				<div>
					<Spin spinning={loading} delay={500}>
						<Table
							columns={columns}
							dataSource={StoreProduct}
							pagination={false}
							bordered
							style={{ width: '800px' }}
						/>
					</Spin>
				</div>
			</React.Fragment>
		);
	}
}

export default ProductStoreComponent;
