import React, { createContext, useState, useEffect } from 'react';
import {get_role_details} from '../handler/api_handler';
export const SubroleContext = createContext();

const SubroleContextProvider = (props) => {
    const [Subrole, setSubrole] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);

    useEffect(() => {
        setSubroleData();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setSubroleData = () => {
        get_role_details().then((details) => {
           
            // setSubrole(details.length === undefined ?[details] :details);
            setSubrole(details.data);

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <SubroleContext.Provider value={{ Subrole, loading, setSubroleData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </SubroleContext.Provider>
    );
};

export default SubroleContextProvider;