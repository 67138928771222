import React, { Component } from 'react';
import { Button, Row, Col, Select } from 'antd';
import { IncentiveMasterContext } from '../../context/IncentiveMasterContext';
import Rolecomponent from './Rolecomponent';

import { CheckCircleOutlined} from '@ant-design/icons';

class IncentiveMasterComponent extends Component {

	static contextType = IncentiveMasterContext;

	render() {

		const { Option } = Select;

		let { Branch, Listing, onBranchChange , BranchId} = this.context;

		return (
			<React.Fragment>
				<div className="main_top">
					<h2>Incentive Master</h2>
					<div className="bottom_border"></div>
				</div>
				<Select
					showSearch
					style={{ width: '200px', marginTop: '15px', marginBottom: '20px' }}
					placeholder="Select branch"
					optionFilterProp="children"
					onChange={onBranchChange}
					value={BranchId}
				>
					{
						Branch.map((branchData) => <Option value={branchData.id}>{branchData.name}</Option>)
					}
				</Select>

				<Row className="incentive_block">
					{
						Listing.map((role, roleInd) => <Col span={13}> <Rolecomponent role={role} roleInd={roleInd} /> </Col>)
					}
					<div className="incentive_button">
						<Button onClick={() => { this.context.updateValue() }}> <CheckCircleOutlined /> Submit </Button>
					</div>

				</Row>

			</React.Fragment>
		);
	}
}

export default IncentiveMasterComponent;