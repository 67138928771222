import React, { createContext, useState, useEffect } from "react";
import { get_attendance_details } from "../handler/api_handler";
import { getAttendanceDetails } from "../handler/LocalDB_handler/AttendanceServiceHandler/";

import moment from "moment";
export const AttendanceContext = createContext();

const AttendanceContextProvider = (props) => {
  const [Attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [updateData, setUpdateData] = useState([]);

  useEffect(() => {
    setAttendanceDataApi();
  }, []);

  const setVisibles = () => {
    setVisible(!visible);
  };

  //   const setAttendanceData = () => {
  //     getAttendanceDetails()
  //       .then((details) => {
  //         setAttendance(details.length === undefined ? [details] : details);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  const setAttendanceDataApi = () => {
    get_attendance_details(moment(new Date()).format("YYYY-MM-DD"))
      .then((details) => {
        setAttendance(details.data);
        setLoading(false);
      })
      .catch((err) => {});
  };
  return (
    <AttendanceContext.Provider
      value={{
        Attendance,
        loading,

        setVisibles,
        visible,
        updateData,
        setUpdateData,
        setAttendanceDataApi,
      }}
    >
      {props.children}
    </AttendanceContext.Provider>
  );
};

export default AttendanceContextProvider;
