import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio, DatePicker, Select, Switch, Drawer, Row, Col } from 'antd';
import { post_user_details } from '../../../handler/api_handler';
import { message, InputNumber } from 'antd';
import { PlusOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

var role = 0;
const onChange = e => {
    role = e.target.value;
};

const Usermodel = ({ visible, onCreate, onCancel, roles, store, onClose }) => {
    const [form] = Form.useForm();
    const [userstatus, setuserstatus] = useState(0);

    const { TextArea } = Input;

    const userstatuschange = (id) => {
        setuserstatus(id);
    }

    const children = [];
    for (let i = 0; i < roles.length; i++) {
        children.push(<Option key={roles[i]['id']}>{roles[i]['name']}</Option>);
    }

    return (
        <Drawer
            title="Add User"
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
            width={720}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={onCancel} style={{ marginRight: 8 }}>
                        <CloseCircleOutlined /> Cancel
              </Button>
                    <Button onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields();
                                onCreate(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }} type="primary">
                        <CheckCircleOutlined /> Submit
              </Button>
                </div>
            }
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                className="edit_form"
            >

                <Row style={{marginBottom: '0'}}>
                    <Col span={12}>
                        <Form.Item
                            name="first_name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the name of staff!',
                                    whitespace: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="email"
                            label="Email address"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter the mail address of staff!',
                                    whitespace: true,
                                    type: "email",
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{marginBottom: '0'}}>
                    <Col span={12}>
                        <Form.Item
                            name="phone"
                            label="Phone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the mobile number!',
                                    whitespace: true,
                                }
                            ]}
                        >
                            <Input style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="address"
                            label="Address"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter the mobile number!',
                                    whitespace: true,
                                }
                            ]}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{marginBottom: '0'}}>
                    <Col span={12}>
                        <Form.Item
                            name="date_of_joining"
                            label="DOJ"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the DOJ of User!',
                                },
                            ]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="aadhaar_number"
                            label="Aadhaar Number"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter the aadhaar number!',
                                    whitespace: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{marginBottom: '0'}}>
                    <Col span={12}>
                        <Radio.Group onChange={onChange} name="role" value={userstatus}>
                            <Radio name="role" value={0} onClick={() => userstatuschange(0)}>Is super admin</Radio>
                            <Radio name="role" value={1} onClick={() => userstatuschange(1)}>Is admin</Radio>
                        </Radio.Group>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="status"
                            label="Status"
                        >
                            <Switch defaultChecked />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Drawer>
    );
};


const AddOfficeusersModel = (props) => {
    const [visible, setVisible] = useState(false);

    const onCreate = (values) => {
        values.user_role = role;
        post_user_details(values).then(() => {
            props.setUserData();
            message.success("Added successfully");
        }).catch((err) => {
            // message.error(err['phone'][0]);
            // if(err.phone) {
            //     message.error(err.phone);
            // }
            console.log(err);
        });
        setVisible(false);
    };

    const onClose = () => {
        setVisible(false);
    };

    console.log('Props data', props);

    return (
        <div>
            <Button className="addbtn" style={{ marginTop: 0, marginRight: '0' }}
                type="primary"
                onClick={() => {
                    setVisible(true);
                }}
            ><PlusOutlined />
                Add User
            </Button>
            <Usermodel
                visible={visible}
                onClose={onClose}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible(false);
                }}
                roles={props.roles}
                store={props.store}
                setUserData={props.setUserData}
            />
        </div>
    );
};

export default AddOfficeusersModel;