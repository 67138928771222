import React, { createContext, useState, useEffect } from "react";
import {
  get_daily_sheet_structire_api,
  get_product_inventory_control_api,
  post_product_inventory_control_create_api,
  get_electric_bill_meter_list_list_api,
  create_electric_bill_meter_list_list_api,
  bank_cash_received_details_list_api,
  get_cash_management,
} from "../handler/api_handler";
import moment from "moment";
import { message } from "antd";

export const DailysheetContext = createContext();

const DailysheetContextProvider = (props) => {
  const [date, setdate] = useState(moment().format("YYYY-MM-DD"));
  const [drawerVisible, setdrawerVisible] = useState(false);
  const [formStructure, setformStructure] = useState([]);
  const [Mainformindex, setMainformindex] = useState(0);
  const [drawerTitle, setdrawerTitle] = useState("");
  const [APImethod, setAPImethod] = useState("");
  const [drawerList, setdrawerList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [Bankcash, setBankcash] = useState([]);

  const [Cashmanagementdata, setCashmanagementdata] = useState({});
  const [
    CashmanagementdrawerVisible,
    setCashmanagementdrawerVisible,
  ] = useState(false);

  const [drawerdualVisible, setdrawerdrawerdualVisible] = useState(false);
  const [
    OilconsumptionDrawerVisibile,
    setOilconsumptionDrawerVisibile,
  ] = useState(false);
  const [freebilldrawerVisible, setfreebilldrawerVisible] = useState(false);
  const [CreditsalsedrawerVisible, setCreditsalsedrawerVisible] = useState(
    false
  );

  const [
    CreditStatementdrawerVisible,
    setCreditStatementdrawerVisible,
  ] = useState(false);

  const [BankcashdrawerVisible, setBankcashdrawerVisible] = useState(false);

  const [EbdrawerVisibile, setEbdrawerVisibile] = useState(false);
  const [Eblist, setEblist] = useState([]);
  const [FoodwastageDrawerVisibile, setFoodwastageDrawerVisibile] = useState(
    false
  );

  const [
    DepartmentsalescountDrawerVisibile,
    setDepartmentsalescountDrawerVisibile,
  ] = useState(false);
  const [CashhandoverDrawerVisibile, setCashhandoverDrawerVisibile] = useState(
    false
  );

  const [Denominationopen, setDenominationopen] = useState(false);
  const [updateDenominationopen, setupdateDenominationopen] = useState(false);

  const [Pettycashopen, setPettycashopen] = useState(false);
  const [updatePettycashopen, setupdatePettycashopen] = useState(false);

  useEffect(() => {
    callDailysheetapi();
    bankCashApi();
    getCashmanagement();
  }, []);

  const callDailysheetapi = () => {
    get_daily_sheet_structire_api(date)
      .then((res) => {
        setformStructure(res.data.data);
        setLoading(false);
      })
      .catch(() => {});
  };

  const Ebbill = () => {
    get_electric_bill_meter_list_list_api(
      moment(new Date()).format("YYYY-MM-DD")
    )
      .then((res) => {
        setEblist(res.data);
      })
      .catch(() => {});
  };

  const getCashmanagement = () => {
    console.log("Entered cash management", date);
    get_cash_management(date)
      .then((res) => {
        console.log("Cash mng data", res.data);
        setCashmanagementdata(res.data);
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  };

  const bankCashApi = () => {
    bank_cash_received_details_list_api(moment(new Date()).format("YYYY-MM-DD"))
      .then((res) => {
        setBankcash(res.data);
      })
      .catch(() => {});
  };

  const EblistCreate = (date, data) => {
    create_electric_bill_meter_list_list_api(date, data)
      .then((res) => {
        get_daily_sheet_structire_api(date)
          .then((res) => {
            setformStructure(res.data.data);
            setLoading(false);
          })
          .catch(() => {});
        setEbdrawerVisibile(!EbdrawerVisibile);
      })
      .catch(() => {});
  };

  const createRawOperationalProduct = () => {
    return new Promise((resolve, reject) => {
      post_product_inventory_control_create_api(date, drawerList)
        .then(() => {
          get_daily_sheet_structire_api(date)
            .then((res) => {
              setformStructure(res.data.data);
              setdrawerVisible(!drawerVisible);
            })
            .catch((err) => {});
        })
        .catch(({ response }) => {
          const { data, status } = response;
          if (status === 400) {
            console.log(data);
            reject(data);
            //   message.error(data.message);
          }
        });
    });
  };

  const getDrawerList = (id) => {
    get_product_inventory_control_api(date, id)
      .then((res) => {
        setdrawerVisible(!drawerVisible);
        setdrawerList([]);
        setdrawerList(res.data);
      })
      .catch((err) => {});
  };
  const changeTargets = (index, event) => {
    const updatedArray = [...drawerList];
    updatedArray[index].closing_stock = event.target.value;
    setdrawerList(updatedArray);
  };
  const changeTargetsEb = (index, event) => {
    const updatedArray = [...Eblist];
    updatedArray[index].closing_reading = event.target.value;
    setEblist(updatedArray);
  };
  return (
    <DailysheetContext.Provider
      value={{
        Eblist,
        Ebbill,
        EblistCreate,
        changeTargetsEb,
        callDailysheetapi,
        FoodwastageDrawerVisibile,
        CreditsalsedrawerVisible,
        setCreditsalsedrawerVisible,
        setFoodwastageDrawerVisibile,
        EbdrawerVisibile,
        setEbdrawerVisibile,
        loading,
        Bankcash,
        CashhandoverDrawerVisibile,
        setCashhandoverDrawerVisibile,
        setLoading,
        BankcashdrawerVisible,
        setBankcashdrawerVisible,
        OilconsumptionDrawerVisibile,
        setOilconsumptionDrawerVisibile,
        drawerdualVisible,
        setdrawerdrawerdualVisible,
        date,
        CreditStatementdrawerVisible,
        setCreditStatementdrawerVisible,
        createRawOperationalProduct,
        setdate,
        formStructure,
        setformStructure,
        Mainformindex,
        setMainformindex,
        drawerVisible,
        setdrawerVisible,
        drawerTitle,
        setdrawerTitle,
        APImethod,
        setAPImethod,
        drawerList,
        getDrawerList,
        changeTargets,
        bankCashApi,
        freebilldrawerVisible,
        setfreebilldrawerVisible,
        DepartmentsalescountDrawerVisibile,
        setDepartmentsalescountDrawerVisibile,
        Denominationopen,
        setDenominationopen,
        updateDenominationopen,
        setupdateDenominationopen,
        Pettycashopen,
        setPettycashopen,
        updatePettycashopen,
        setupdatePettycashopen,
        CashmanagementdrawerVisible,
        setCashmanagementdrawerVisible,
        getCashmanagement,
        Cashmanagementdata,
      }}
    >
      {" "}
      {props.children}{" "}
    </DailysheetContext.Provider>
  );
};

export default DailysheetContextProvider;
