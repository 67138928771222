import React, { createContext, useState, useEffect } from 'react';
// import { getProductPrices } from '../handler/LocalDB_handler/ProductPriceServiceHandler/'
export const ProductpriceContext = createContext();

const ProductpriceContextProvider = (props) => {
    const [ProductPrice, setProductPrice] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);

    useEffect(() => {
        setProductPriceData();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setProductPriceData = () => {
        // getProductPrices().then((details) => {

        //     setProductPrice(details.length === undefined ?[details] :details);

        //     setLoading(false);
        // }).catch((err) => {
        //     console.log(err);
        // })
        setLoading(false);
        setProductPrice([{ "id": "123", "name": "demo", "MRP": "123", "Selling_price": "12", "Buying_price": "11", "status": true }]);
        // return [{ "id": "123", "Name": "123", "MRP": "", "Selling_price": "", "Buying_price": "", "status": "" }];
    }
    return (
        <ProductpriceContext.Provider value={{ ProductPrice, loading, setProductPriceData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </ProductpriceContext.Provider>
    );
};

export default ProductpriceContextProvider;