import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Select, Table, Input, Button, Space, Spin, Row, Col, } from 'antd';
import Highlighter from 'react-highlight-words';
import { SalarylistContext } from '../../context/SalarylistContext';
import { delete_user_details } from '../../handler/api_handler';
import SalaryDetailModel from '../SalaryDetails/SalaryDetailModel';
import SaleryInputComponent from './SaleryInputComponent';
import SalaryUpdateModal from './SalaryUpdateModal';

class SalaryComponent extends Component {

	state = {
		searchText: '',
		searchedColumn: ''
	};

	static contextType = SalarylistContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"

					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" >
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		delete_user_details(id).then(() => {
			this.context.setUserData();
		}).catch(() => {

		})
	};

	setdata = () => {
		console.log('Updated');
	};

	render() {
		const { Option } = Select;

		const { Branch, BranchId, onBranchChange, User, loading } = this.context;

		const columns = [
			{
				title: 'Staff Name',
				dataIndex: 'first_name',
				key: 'id',
			},
			{
				title: 'Branch Name',
				dataIndex: 'branch_name',
				key: 'id',
			},
			{
				title: 'Role/Designation',
				key: 'id',
				dataIndex: 'employee_role_data',
				render: (text, record) => (
					<React.Fragment>
						{ record.employee_role_data.map(value => value.name).join(',')}
					</React.Fragment>
				)
			},
			{
				title: 'Working Hours',
				key: 'id',
				dataIndex: 'work_hours',
				render: (text, record) => (
					<React.Fragment>
						{(record.work_hours !== null) ? record.work_hours : "NA"}
					</React.Fragment>
				)
			},
			{
				title: 'Salary / Day',
				key: 'id',
				dataIndex: 'per_day',
				render: (text, record) => (
					<React.Fragment>
						{(record.per_day !== null) ? record.per_day : "NA"}
					</React.Fragment>
				)
			},
			{
				title: 'OT Salary / Hr',
				key: 'id',
				dataIndex: 'ot_per_hour',
				render: (text, record) => (
					<React.Fragment>
						{(record.ot_per_hour !== null) ? record.ot_per_hour : "NA"}
					</React.Fragment>
				)
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<SalaryUpdateModal record={record} />
					</Space>
				)
			},
			{
				title: 'History',
				dataIndex: 'histrory',
				key: 'id',
				render: (text, record) => (
					<Space>
						<SalaryDetailModel salary_history={record.salary} />
					</Space>
				)
			}
		];

		return (
			<React.Fragment>
				<div className="main_top">
					<h2>salary list</h2>
					<div className="bottom_border"></div>
				</div>
				<Select
					showSearch
					style={{ width: 200, marginBottom: '10px' }}
					placeholder="Select branch"
					optionFilterProp="children"
					onChange={onBranchChange}
					value={BranchId}
				>
					<Option value={0}>All branch</Option>
					{
						Branch.map((branchData) => <Option value={branchData.id}>{branchData.name}</Option>)
					}
				</Select>

				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={User} pagination={false} bordered style={{width: '100%'}}/>
				</Spin>
			</React.Fragment>
		);
	}
}

export default SalaryComponent;