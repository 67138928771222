import React from 'react';

import { Table, Button, Select, Spin , Input , Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { MappingContext } from '../../context/Mappingproductcontext';

import { put_product_mapping_details } from '../../handler/api_handler';


import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';


const { Option } = Select;

class ProductMappingComponent extends React.Component {

	state = {
		selectedRowKeys: [], // Check here to configure the default column
		loading: false,
		searchText: '',
		searchedColumn: '',
	};

	static contextType = MappingContext;

	start = () => {
		const { setProductData, classification } = this.context;
		console.log('selectedRowKeys', this.state);
		this.setState({ loading: true });
		console.log(this.state);
		put_product_mapping_details(classification, { product: this.state.selectedRowKeys })
			.then((response) => {
				setProductData(classification);
			})
			.catch((err) => {});
		// ajax request after empty completing
		setTimeout(() => {
			this.setState({
				selectedRowKeys: [],
				loading: false
			});
		}, 1000);
	};

	onSelectChange = (selectedRowKeys) => {
		// alert(selectedRowKeys)
		console.log('selectedRowKeys changed: ', selectedRowKeys);
		this.setState({ selectedRowKeys });
	};


	// Search Data Start

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		  <div style={{ padding: 8 }}>
			<Input
			  ref={node => {
				this.searchInput = node;
			  }}
			  placeholder={`Search ${dataIndex}`}
			  value={selectedKeys[0]}
			  onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			  onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  style={{ marginBottom: 8, display: 'block' }}
			/>
			<Space>
			  <Button
				type="primary"
				onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
				icon={<SearchOutlined />}
				size="small"
				style={{ width: 90 }}
			  >
				Search
			  </Button>
			  <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
				Reset
			  </Button>
			  <Button
				type="link"
				size="small"
				onClick={() => {
				  confirm({ closeDropdown: false });
				  this.setState({
					searchText: selectedKeys[0],
					searchedColumn: dataIndex,
				  });
				}}
			  >
				Filter
			  </Button>
			</Space>
		  </div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
		  record[dataIndex]
			? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
			: '',
		onFilterDropdownVisibleChange: visible => {
		  if (visible) {
			setTimeout(() => this.searchInput.select(), 100);
		  }
		},
		render: text =>
		  this.state.searchedColumn === dataIndex ? (
			<Highlighter
			  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			  searchWords={[this.state.searchText]}
			  autoEscape
			  textToHighlight={text ? text.toString() : ''}
			/>
		  ) : (
			text
		  ),
	  });
	
	  handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
		  searchText: selectedKeys[0],
		  searchedColumn: dataIndex,
		});
	  };
	
	  handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	  };

	// Search Data End

	render() {


		const columns = [
			{
				title: 'Id',
				dataIndex: 'id'
			},
			{
				title: 'Name',
				dataIndex: 'name',
				key : 'name',
				...this.getColumnSearchProps('name')
			},
			{
				title: 'Department',
				dataIndex: 'department_name'
			},
			{
				title: 'unit',
				dataIndex: 'unit_name'
			}
		];

		const { loading, selectedRowKeys } = this.state;
		const { Product, changeClassification, visible } = this.context;

		console.log(Product, 'check');
		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange.bind(this)
		};
		const hasSelected = selectedRowKeys.length > 0;
		return (
			<div>
				<div className="main_top" style={{display: 'flex', justifyContent: 'space-between'}}>
					<Select
						defaultValue="0"
						style={{ width: '120', marginRight: '15px' }}
						onChange={changeClassification}
					>
						<Option value="0">All Product</Option>
						<Option value="1">Sales Product</Option>
						<Option value="3">Raw Material</Option>
						<Option value="2">Maintenance Product</Option>
						<Option value="4">Vegetable</Option>
					</Select>
					<Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
						<PlusOutlined /> Map Product
					</Button>

				</div>
				<span style={{ marginLeft: 8 }}>
						{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
					</span>
				<Spin spinning={visible}>
					<Table rowSelection={rowSelection} columns={columns} dataSource={Product} />
				</Spin>
			</div>
		);
	}
}

export default ProductMappingComponent;
