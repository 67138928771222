import React, { useState, useEffect } from 'react';
import { DatePicker, Select, Tabs, Spin } from 'antd';
import { get_store_details, get_sales_count_create_api } from '../../handler/api_handler';
import Producttable from "./deptsalescounttable";
import moment from "moment";
import { notEmpty } from "../../handler/helpers";

function Productlist(props) {

    //Data
    const Dateformat = "YYYY-MM-DD";
    const Today = moment().format(Dateformat);
    const Yesterday = moment().subtract(1, 'days').format(Dateformat);

    //State management
    const [SelectedDate, setSelectedDate] = useState(Yesterday);
    const [SelectedBranch, setSelectedBranch] = useState("");
    const [BranchList, setBranchList] = useState([]);
    const [SelectedTab, setSelectedTab] = useState(3);
    const [Listing, setListing] = useState([]);
    const [loading, setloading] = useState(false);

    // Component
    const { Option } = Select;
    const { TabPane } = Tabs;

    // Method
    const onDatechange = (value, event) => {
        let date = moment(value).format(Dateformat);
        setSelectedDate(date);
    }

    const onTabchange = (key) => {
        setSelectedTab(key);
    }

    const ProductApicall = (date, branch, classification) => {
        setloading(true);
        get_sales_count_create_api(branch,date).then(({ data }) => {
            setListing(data);
            setloading(false);
        }).catch(() => {
            setloading(false);
        });
    }

    const onBranchchange = (branchid) => {
        setSelectedBranch(branchid)
    }

    //Hooks
    useEffect(() => {
        get_store_details().then(({ data }) => {
            setBranchList(data);
            setSelectedBranch(data[0].id);
        }).catch((err) => {

        });
    }, []);

    useEffect(() => {
        if (notEmpty(SelectedBranch)) {
            ProductApicall(SelectedDate, SelectedBranch, SelectedTab)
        }
    }, [SelectedDate, SelectedBranch, SelectedTab]);

    return (
        <React.Fragment>
            <div className="main_top">
                <h2>Sales count</h2> 
                <div className="bottom_border"></div>
            </div>

            <DatePicker
                defaultValue={moment(SelectedDate, Dateformat)} format={Dateformat}
                onChange={(value, event) => onDatechange(value, event)}
            />

            <Select
                value={SelectedBranch}
                showSearch
                style={{ width: 150, margin: 20 }}
                onChange={onBranchchange}
            >
                <Option value="">Select Branch</Option>
                {BranchList.map((item) => <Option value={item.id}>{item.name}</Option>)}
            </Select>

            {/* <Tabs defaultActiveKey={SelectedTab} onChange={onTabchange} className="Tab_dailysheet">
                <TabPane tab="Raw Material" key="3"></TabPane>
                <TabPane tab="Operational Product" key="2"></TabPane>
                <TabPane tab="Vegetable Product" key="4"></TabPane>
            </Tabs> */}

            <Producttable Listing={Listing} style={{ marginTop: 50 }} />
        </React.Fragment>
    );
}

export default Productlist;