import React, { useContext } from 'react';

import { Drawer, Button, Input, Select, Table } from 'antd';
import moment from 'moment';

class EbdrawerForm extends React.Component {
	state = [];

	showDrawer = () => {
		this.setState({
			visible: true
		});
	};

	onClose = () => {
		console.log(this.props);
		this.props.setEbdrawerVisibile(!this.props.visible);
	};
	validateFields = () => {
		const validatedData = this.props.Eblist.map((validate) => {
			return Boolean(parseInt(validate.closing_reading) || parseInt(validate.closing_reading) === 0)
				? ''
				: (validate.error = 'please fill the data num type only supported');
		});
		this.setState(validatedData);
		const data = validatedData.filter((data) => data !== '');
		console.log(data.length);
		return data.length;
	};
	submit = () => {
		if (this.validateFields() == 0) {
			this.props.EblistCreate(moment(new Date()).format('YYYY-MM-DD'), this.props.Eblist);
		}
	};

	columns = [
		{
			title: 'Meater name',
			dataIndex: 'meter_name'
		},
		{
			title: 'Opening reading',
			dataIndex: 'opening_reading'
		},

		{
			title: 'Closing reading',
			dataIndex: 'closedstock',
			render: (text, record, index) => (
				<React.Fragment>
					<Input
						value={this.props.Eblist[index].closing_reading}
						onChange={this.props.changeTargetsEb.bind(this, index)}
					/>
					<span style={{ color: 'red' }}>{this.state[index]}</span>
				</React.Fragment>
			)
		}
	];

	render() {
		return (
			<React.Fragment>
				<Drawer
					title={`Eb reading `}
					width={'750'}
					onClose={this.onClose}
					visible={this.props.visible}
					bodyStyle={{ paddingBottom: 80 }}
					footer={
						<div
							style={{
								textAlign: 'right'
							}}
						>
							<Button onClick={this.onClose} style={{ marginRight: 8 }}>
								Cancel
							</Button>
							<Button onClick={this.submit} type="primary">
								{this.props.APImethod}
							</Button>
						</div>
					}
				>
					<Table columns={this.columns} pagination={false} dataSource={this.props.Eblist} />
				</Drawer>
			</React.Fragment>
		);
	}
}

export default EbdrawerForm;
