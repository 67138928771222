import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Radio, Select, DatePicker } from 'antd';
// import {addWrongbills} from '../../../handler/LocalDB_handler/WrongbillServiceHandler'
import { get_attendance_details, post_Wrongbill_details } from '../../../handler/api_handler';
import { message } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const Wrongbillmodel = ({ User, visible, onCreate, onCancel, Orderstatus }) => {
  const [form] = Form.useForm();
  console.log(User,"my check")
  return (
    <Modal
      visible={visible}
      title="Create a new Wrongbill"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form className="edit_form"
        form={form}
        layout="horizontal"
        name="form_in_modal"

      >
        <Form.Item
          name="bill_no"
          label="Bill No"
          rules={[
            {
              required: true,
              message: 'Please input the name of bill no!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="wrong_amount"
          label="Wrong amount"
          rules={[
            {
              required: true,
              message: 'Please input the amount!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="correct_amount"
          label="Correct amount"
          rules={[
            {
              required: true,
              message: 'Please input the amount!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="billed_by"
          label="Billed by"
          rules={[
            {
              required: true,
              message: 'Please input the status!',
            },
          ]}
        >
          <Select
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          >

            {User.map(data=>{
              return <Option value={data.id}>{data.username}</Option>
            })}
          </Select>
        </Form.Item>



        <Form.Item
          name="date"
          label="Billed On"

        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: 'Please input the description!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <p>Hello</p>
           <p>{this.props.Orderstatus}</p> */}
        {/* <p>Hello {Orderstatus}</p> */}
       

     

      </Form>
      
    </Modal>
  );
};


const AddWrongbillmodel = (props) => {
  console.log('User data', props.User)
  const [visible, setVisible] = useState(false);

  const onCreate = (values) => {
    post_Wrongbill_details(values).then(() => {
      message.success("Added successfully");
      props.setdata();
    }).catch((err) => {

    });
    setVisible(false);
  };
  useEffect(() => {
    console.log('This propslsdlkfjlsjf', props);
}, [])

  return (
    
    <div className="main_top">
      <h2>Wrong Bill</h2>
      <Button className="addbtn" style={{ marginTop: 0, marginRight: '0' }}
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      ><PlusOutlined />
        Add Wrongbill
      </Button>
      <Wrongbillmodel
        visible={visible}
        Orderstatus={props.Orderstatus}
        onCreate={onCreate}
        User={props.User}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

export default AddWrongbillmodel;