import React, { createContext, useState, useEffect } from 'react';
import {get_operational_product_details,get_product_type,get_product_unit} from '../handler/api_handler';
export const OperationalproductContext = createContext();

const OperationalproductContextProvider = (props) => {
    const [Operationalproduct, setOperationalproduct] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [unit, setUnit] = useState([]);
    const [department,setdepartment] = useState([]);


    useEffect(() => {
        setOperationalproductData();
        get_product_dept();
        get_product_units();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const get_product_dept=()=>{
        get_product_type().then((res)=>{
          setdepartment(res.data)
        }).catch((err)=>{})
    }

    const get_product_units=()=>{
        get_product_unit().then((res)=>{
            setUnit(res.data)
        }).catch((err)=>{

        })
    }

    const setOperationalproductData = () => {
        get_operational_product_details().then((details) => {
           
            // setOperationalproduct(details.length === undefined ?[details] :details);
            setOperationalproduct(details.data);

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <OperationalproductContext.Provider value={{ Operationalproduct, unit,department,loading, setOperationalproductData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </OperationalproductContext.Provider>
    );
};

export default OperationalproductContextProvider;