import React, { createContext, useState, useEffect } from 'react';
import { DateFormat } from '../handler/helpers';
import { get_vendor_list_api , branch_product_inventory_list_api } from '../handler/api_handler';
export const VegInventoryListContext = createContext();


const VegInventoryListContextProvider = (props) => {

    var DateObj = new Date();   
    
    const [Listing, setListing] = useState([]);
    const [VendorListingdata,setVendorListingdata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [classification, setClassification] = useState(0);
    const [startDate,setstartDate] = useState(DateFormat(DateObj));
    const [endDate,setendDate] = useState(DateFormat(DateObj));
    
    useEffect(() => {
        getVendorList();
        setListingData(startDate,endDate);
    }, []);

    const getVendorList = () => {
        get_vendor_list_api().then(({ data }) => {
            setVendorListingdata(data);
        }).catch(() => {

        });
    }

    const setVisibles = () => {
        setVisible(!visible);
    }
    const changeClassification = (data) => {
        setClassification(data);
        setListingData(data);
    }

    const setListingData = (start,stop) => {
        setLoading(true);
        let Url = `branch_product_inventory_list/2/?start=${start}&stop=${stop}`;
        branch_product_inventory_list_api(Url).then(({ data }) => {
            setListing(data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }

    const OndateRangechange = (start,stop) => {
        setstartDate(start);setendDate(stop);
        setListingData(start,stop);
    }

    return (
        <VegInventoryListContext.Provider value={{ 
            classification,changeClassification, Listing, loading, setListingData, setVisibles, 
            visible, updateData, setUpdateData , startDate,setstartDate,endDate,setendDate , OndateRangechange, VendorListingdata
        }}>
            {props.children}
        </VegInventoryListContext.Provider>
    );
};

export default VegInventoryListContextProvider;