import React, { createContext, useState, useEffect } from 'react';
import { get_attendance_details } from '../handler/api_handler';
import moment from 'moment';
export const Admindailysheetcontext = createContext();


const AdmindailysheetcontextProvider = (props) => {

    const [Data,setData] = useState(0);

    return (
        <Admindailysheetcontext.Provider value={{ Data }}>
            {props.children}
        </Admindailysheetcontext.Provider>
    );
};

export default AdmindailysheetcontextProvider;