import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  InputNumber,
} from "antd";
import { create_product } from "../../../handler/api_handler";
import { message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const Operationalproductmodel = ({
  visible,
  onCreate,
  onCancel,
  unit,
  department,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Create Maintenance Product"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        className="edit_form"
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <Row style={{ marginBottom: "0" }}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input the name of Maintenance Product!",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="unit" label="unit">
              <Select>
                {unit.map((data) => {
                  return <Option value={data.id}>{data.name}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: "0" }}>
          {/* <Col span={12}>
						<Form.Item name="department" label="department">
							<Select>
								{department.map((data) => {
									return <Option value={data.id}>{data.name}</Option>;
								})}
							</Select>
						</Form.Item>
					</Col> */}
          <Col span={12}>
            <Form.Item
              name="reorder_level"
              label="reorder level"
              rules={[
                {
                  required: true,
                  message: "Please input reorder level!",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const AddOperationalproductmodel = (props) => {
  const [visible, setVisible] = useState(false);

  const onCreate = (values) => {
    values.classification = 2;

    create_product(values)
      .then(() => {
        alert("Sdsdsdsd");
        message.success("Added successfully");
        props.setdata();
      })
      .catch((err) => {
        alert(err);
      });
    setVisible(false);
  };

  return (
    <div className="main_top">
      <h2>Maintenance product</h2>
      <div className="bottom_border"></div>
      <Button
        className="addbtn"
        style={{ marginTop: 0, marginRight: "0" }}
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      >
        <PlusOutlined />
        Add Maintenance Product
      </Button>
      <Operationalproductmodel
        visible={visible}
        unit={props.unit}
        department={props.department}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

export default AddOperationalproductmodel;
