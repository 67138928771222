import React, { useContext, useState, useEffect } from 'react';
import { Form, Input, Button, Space, Select, Row, Col, DatePicker, Table } from 'antd';
import Formfiled from "./Formfiled";
import { VegInventoryContext } from '../../context/VegInventoryContext';
import moment from 'moment';
// import '../../src/css/Inventory.css';
import { PlusCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { uptoCurrentdate } from '../../../src/handler/Datetimehelper.js';

const { Option } = Select;

const productlist = [{ id: 1, name: "Product One" }, { id: 2, name: "Product Two" }];

const InventoryComponent = (props) => {

    const Contextdata = useContext(VegInventoryContext);

    const [form] = Form.useForm();
    const dateFormat = 'YYYY-MM-DD';

    useEffect(() => {
        Contextdata.Addnewrow();
    }, []);

    const onFinish = values => {
    };

    const Initialvalue = { sights: [{ new_quantity_in_hand: "12", product_name: 1, quantity: "12", stock_in_hand: "10" }, { new_quantity_in_hand: "12", product_name: 1, quantity: "12", stock_in_hand: "10" }] }

    const handleChange = () => {
        form.setFieldsValue({ sights: [] });
    };

    const Formsubmit = () => {
        Contextdata.InventorySubmit();
    }

    function handleChangeDate(data, dateString) {
        Contextdata.setCurrentdate(dateString);
    }

    return (
        <React.Fragment>

            <div className="main_top">
                <h2>Veg Inward</h2>
                <div className="bottom_border"></div>
            </div>

            <Select
                style={{ width: '12%' , marginRight: 10}}
                value={Contextdata.Selectedvendor}
                onChange={Contextdata.Onchangevendor}
            >
                <Option value={""}> Select Vendor </Option>
                {
                    Contextdata.VendorListingdata.map((element) => <Option value={element.id}>{element.name}</Option>)
                }
            </Select>

            <DatePicker
                disabledDate={uptoCurrentdate}
                onChange={handleChangeDate}
                defaultValue={moment(Contextdata.Currentdate, dateFormat)}
                format={dateFormat}
            />

            <table className="inventory_table">
                <tr style={{ textAlign: 'center' }}>
                    <th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>Product Name</th>
                    <th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>Stock In Hand</th>
                    <th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>In Stock</th>
                    <th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>Rate / product</th>
                    <th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>Updated Quantity</th>
                    {/* <th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>Action</th> */}
                </tr>
                {
                    Contextdata.Lising.map((item, index) => <Formfiled item={item} key={index} index={index} />)
                }
            </table>

            <div style={{ width: '100%', float: 'left', backgroundColor: '#fff', padding: '20px' }}>
                <div className="edit_button_block">
                    {/* <Button onClick={Contextdata.Addnewrow} style={{ marginRight: '20px' }}><PlusCircleOutlined /> Add Row </Button> */}
                    <Button onClick={Formsubmit}><CheckCircleOutlined /> Save </Button>
                </div>
            </div>

        </React.Fragment>
    );
}

export default InventoryComponent;