import React, { createContext, useState, useEffect } from 'react';
import { get_store_details } from '../handler/api_handler';
export const AttendancereportContext = createContext();

const AttendancereportContextProvider = (props) => {
    const [Attendancereport, setAttendancereport] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [Store, setStore] = useState([]);

    useEffect(() => {
        // alert("yfuy")
        setAttendancereportData();
        setStoreData()
    }, []);



    const setVisibles = () => {
        setVisible(!visible);
    }

    const setStoreData = () => {
        // alert("uiguig")
        get_store_details()
            .then((details) => { setStore(details.data) })
            .catch((err) => console.log(err))
    }

    const setAttendancereportData = () => {

    }

    
    return (
        <AttendancereportContext.Provider
            value={{
                Attendancereport,
                loading,
                setAttendancereportData,
                setVisibles,
                visible,
                updateData,
                setUpdateData,
                Store
            }}>
            {props.children}
        </AttendancereportContext.Provider>
    );
};

export default AttendancereportContextProvider;