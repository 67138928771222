import React, { Component } from 'react';
import { SearchOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Select,Table, Input, Button, Space, Popconfirm, Spin, Skeleton } from 'antd';
import Highlighter from 'react-highlight-words';
import { Officeuserscontext } from '../../context/Officeuserscontext';
import AddOfficeusersModel from './AddOfficeusersModel';
import { delete_user_details } from '../../handler/api_handler';
import UpdateOfficeusersModel from './UpdateOfficeusersModel'
import moment from 'moment';


class OfficeusersComponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};

	static contextType = Officeuserscontext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"

					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" >
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
					text
				)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		delete_user_details(id).then(() => {
			// alert("check component")
			this.context.setUserData();
		}).catch(() => {

		})
	};
	
	setdata = () => {
		console.log('Updated');
	};

	render() {
		const { Option } = Select;

		// console.log('context data', this.context);
		
		const { Branch,BranchId, onBranchChange,User, setUserData, loading, Role, setVisibles, visible, Store } = this.context;
		
		
		const columns = [
			{
				title: 'Staff Name',
				dataIndex: 'first_name',
				key: 'id',
			},
			{
				title: 'Role/Designation',
				key: 'id',
				dataIndex: 'employee_role_data',
				render: (text, record) => (
					<React.Fragment>
						{ record.user_role == 0 ? 'Super admin' : 'Admin'}
					</React.Fragment>
				)

			},
			{
				title: 'D.O.J',
				dataIndex: 'formatted_date_of_joining',
				key: 'id',
			},
			{
				title: 'Email',
				dataIndex: 'email',
				key: 'id',
			},
			{
				title: 'Phone',
				dataIndex: 'phone',
				key: 'id',
			},
			{
				title: 'Address',
				dataIndex: 'address',
				key: 'id',
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<UpdateOfficeusersModel user_data={record} setUserData={setUserData} />
						{/* <Popconfirm
							title="Are you sure？"
							onConfirm={() => this.handleOk(record.id)}
							okText="Yes"
							cancelText="No"
						>
							<DeleteTwoTone style={{ fontSize: '20px' }} />
						</Popconfirm> */}
					</Space>
				)
			}
		];
		return (
			<React.Fragment>
				<div className="main_top">
					<h2>Office users</h2>
					<div className="bottom_border"></div>
					<AddOfficeusersModel roles={Role} store={Store} setUserData={setUserData} />
				</div>


				{/* <Select
					showSearch
					style={{ width: 200, marginBottom: '10px' }}
					placeholder="Select branch"
					optionFilterProp="children"
					onChange={onBranchChange}
					value={BranchId}
				>
					{
						Branch.map((branchData) => <Option value={branchData.id}>{branchData.name}</Option>)
					}
				</Select> */}

				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={User} bordered style={{ width: '100%'}} pagination={false} />
				</Spin>
			</React.Fragment>
		);
	}
}

export default OfficeusersComponent;
