import React, { Component } from 'react';
import { SearchOutlined, EyeOutlined,EyeInvisibleOutlined, DeleteTwoTone } from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { BranchContext } from '../../context/Branchcontext';
import AddBranchmodels from '../Branch/AddBranchmodels/';
// import {deleteBranchs} from '../../handler/LocalDB_handler/BranchServiceHandler'
import { update_branch_status , delete_branch_details} from '../../handler/api_handler';
import Updatemodelcomponent from '../../components/Branch/UpdateBranchModel'


class Branchcomponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};
	static contextType = BranchContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [ e.target.value ] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"

					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" >
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[ this.state.searchText ]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		delete_branch_details(id).then(() => {
			this.context.setBranchData();
		}).catch(() => {

		})
	};

	handleStatus = (id, status) => {
		update_branch_status(id,status).then(() => {
			this.context.setBranchData();
		}).catch(() => {

		})
	}

	render() {
		const { Branch,loading,setBranchData,storeid,setVisibles,visible } = this.context;
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',

				// ...this.getColumnSearchProps('name')
			},
			{
				title: 'Branch',
				dataIndex: 'branch',
				key: 'branch',

				// ...this.getColumnSearchProps('store')
			},
			// {
			// 	title: 'description',
			// 	dataIndex: 'description',
			// 	key: 'description',
			// 	...this.getColumnSearchProps('description')
			// },
			// {
			// 	title: 'status',
			// 	dataIndex: 'status',
			// 	key: 'id',
			// 	render: (text, record) => (
			// 		<Space>
			// 			<Popconfirm
			// 				title="Are you sure to change status？"
			// 				onConfirm={() => this.handleStatus(record.id,record.status)}
			// 				okText="Yes"
			// 				cancelText="No"
			// 			>
			// 				{record.status ? (
			// 					<EyeOutlined style={{ fontSize: '20px', color: 'blue' }} />
			// 				) : (
			// 					<EyeInvisibleOutlined style={{ fontSize: '20px', color: 'red' }} />
			// 				)}
			// 			</Popconfirm>
			// 		</Space>
			// 	)
			// },

			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<Updatemodelcomponent Branch_data={record} store={storeid} setdata={setBranchData} />
						{/* <Popconfirm
							title="Are you sure？"
							onConfirm={() => this.handleOk(record.id)}
							okText="Yes"
							cancelText="No"
						>
							<DeleteTwoTone style={{ fontSize: '20px' }} />
						</Popconfirm> */}
					</Space>
				)
			}
		];
		return (
			<React.Fragment>
				<AddBranchmodels  store={storeid}  setdata={setBranchData}/>
				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={Branch} bordered />
				</Spin>
			</React.Fragment>
		);
	}
}

export default Branchcomponent;
