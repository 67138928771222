import React, { Component } from 'react';
import { SearchOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin, Skeleton } from 'antd';
import Highlighter from 'react-highlight-words';
import { RawproductContext } from '../../context/Rawproductcontext';
import AddRawproductmodels from '../Rawproduct/AddRawproductModel/';
// import {deleteRawproducts} from '../../handler/LocalDB_handler/RawproductServiceHandler'
// import { get_product_type } from '../../handler/api_handler';
import Updatemodelcomponent from '../../components/Rawproduct/UpdateRawproductModel';
import { ReloadOutlined } from '@ant-design/icons';

class Rawproductcomponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};
	static contextType = RawproductContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [ e.target.value ] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
					>
						Search
					</Button>
					<Button type="danger" onClick={() => this.handleReset(clearFilters)}>
						<ReloadOutlined />
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[ this.state.searchText ]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		// delete_Rawproduct_details(id)
		// 	.then(() => {
		// 		this.context.setRawproductData();
		// 	})
		// 	.catch(() => {});
	};

	render() {
		const { Rawproduct, loading, setRawproductData, setVisibles, visible,unit,department } = this.context;
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'unit',
				dataIndex: 'unit_name',
				key: 'unit_name',
			},

			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<Updatemodelcomponent Rawproduct_data={record} setdata={setRawproductData} unit={unit} 
                        department={department}
                        /> 
					</Space>
				)
			}
		];
		return (
			<React.Fragment>

				<AddRawproductmodels unit={unit} department={department} setdata={setRawproductData} />
				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={Rawproduct} pagination={false} bordered style={{ width: '800px'}}  />
				</Spin>
			</React.Fragment>
		);
	}
}

export default Rawproductcomponent;
