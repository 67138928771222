import React, { useState, Component } from 'react';
import { Button, Modal, Form, Input, Radio, Space, Row, Col, TimePicker } from 'antd';
import { EditTwoTone, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { message } from 'antd';
const onFinish = () => {};
class UpdateAttendancemodel extends Component {
	constructor(props) {
		super(props);
		this.formRef = React.createRef();
	}

	state = {
		visible: false
	};
	onFill = () => {
		console.log('updateData', this.props.updateData);

		if (this.formRef.current !== null) {
			this.formRef.current.setFieldsValue(this.props.updateDat);
		}
	};

	setVisible() {
		this.setState({ visible: !this.state.visible });
	}

	componentDidUpdate(prevProps, prevState) {
		console.log(this.props.updateData);
		const Settimeout = this.onFill;
		setTimeout(function() {
			Settimeout();
		}, 100);
	}

	onCreate = (value) => {};
	render() {
		console.log(this.props.updateData, 'check');
		return (
			<div>
				<Modal
					title="Edit Punch Time"
					visible={this.props.visible}
					footer={null}
					onCancel={this.props.visibledata.bind(this)}
				>
          
					<Form
						name="dynamic_form_item"
						initialValues={this.props.updateData}
						onFinish={onFinish}
						autoComplete="off"
					>
						<Form.List name="punch">
							{(fields) => (
								<React.Fragment>
									{fields.map((field) => {
										console.log(field, 'chekingded');
										return (
											<Space
												key={field.key}
												style={{ display: 'flex', marginBottom: 8 }}
												align="baseline"
											>
												<Form.Item
													{...field}
													name={[ field.name, 'punch_in' ]}
													fieldKey={[ field.fieldKey, 'punch_in' ]}
													rules={[ { required: true, message: 'Missing punch_in' } ]}
												>
													<Input placeholder="punch_in" />
												</Form.Item>
												<Form.Item
													{...field}
													name={[field.name, 'punch_out']}
													fieldKey={[ field.fieldKey, 'punch_out' ]}
													rules={[ { required: true, message: 'Missing punch_out' } ]}
												>
													<Input  placeholder="punch_out" />
												</Form.Item>
											</Space>
										);
									})}
								</React.Fragment>
							)}
						</Form.List>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default UpdateAttendancemodel;
