import React, { createContext, useState, useEffect } from 'react';
import {get_raw_product_details,get_product_type,get_product_unit} from '../handler/api_handler';
export const RawproductContext = createContext();

const RawproductContextProvider = (props) => {
    const [Rawproduct, setRawproduct] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [unit, setUnit] = useState([]);
    const [department,setdepartment] = useState([]);


    useEffect(() => {
        setRawproductData();
        get_product_dept();
        get_product_units();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const get_product_dept=()=>{
        get_product_type().then((res)=>{
          setdepartment(res.data)
        }).catch((err)=>{})
    }

    const get_product_units=()=>{
        get_product_unit().then((res)=>{
            setUnit(res.data)
        }).catch((err)=>{

        })
    }

    const setRawproductData = () => {
        get_raw_product_details().then((details) => {
           
            // setRawproduct(details.length === undefined ?[details] :details);
            setRawproduct(details.data);

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <RawproductContext.Provider value={{ Rawproduct, unit,department,loading, setRawproductData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </RawproductContext.Provider>
    );
};

export default RawproductContextProvider;