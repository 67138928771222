import React, { Component } from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import { Table, Input, Button, Space, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { Creditcustomercontext } from '../../context/Creditcustomercontext';
import AddCreditcustomermodels from '../Creditcustomer/AddCreditcustomermodels';
import UpdateCreditcustomerModel from '../Creditcustomer/UpdateCreditcustomerModel';


class Creditcustomercomponent extends Component {
	static contextType = Creditcustomercontext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"

					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" >
						<ReloadOutlined />
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	render() {
		const { Listingdata } = this.context;

		const columns = [
			{
				title: 'Customer',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Phone',
				dataIndex: 'phone',
				key: 'phone',
			},
			{
				title: 'Address',
				dataIndex: 'address',
				key: 'address',
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<UpdateCreditcustomerModel record={record}/>
					</Space>
				)
			}
		];	

		return (
			<React.Fragment>
				<AddCreditcustomermodels />
				<Spin spinning={false} delay={500}>
					<Table columns={columns} dataSource={Listingdata} pagination={false} bordered style={{width: '800px'}} />
				</Spin>
			</React.Fragment>
		);
	}
}

export default Creditcustomercomponent;
