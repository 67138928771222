import React from 'react';

function Denomination(props) {
    let { data } = props;
    return (
        <React.Fragment>
            <table className="inventory_table" style={{ padding: '10px' }}>
                <thead>
                    <tr>
                        <th>Denomination</th>
                        <th></th>
                        <th>Count</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (data.data !== undefined) ? data.data.map((item, index) =>
                            <tr>
                                <td>{item.amount}</td>
                                <td> &#215; </td>
                                <td>{item.quantity}</td>
                                <td>{Number(item.quantity) * Number(item.amount)}</td>
                            </tr>
                        ) : ""
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td>Total</td>
                        <td></td>
                        <td></td>
                        <td>{data.total}</td>
                    </tr>
                </tfoot>
            </table>
        </React.Fragment>
    );
}

export default Denomination;