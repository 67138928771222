import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Drawer, Button, Input, InputNumber, Select, Table, Form, message } from 'antd';
import { patch_foodwastage_bill_api, branch_specific_wrongbill_api } from '../../handler/api_handler';
import moment from 'moment';

class Foodwastageupdateform extends React.Component {
	constructor(props) {
		super(props);
		this.formRef = React.createRef();
	}

	state = {
		visible: false,
	};

	showDrawer = () => {
		this.setState({ visible: true });
		const Settimeout = this.onFill;
		setTimeout(function () {
			Settimeout();
		}, 100);
	};

	onClose = () => {
		this.setState({ visible: false });
	};

	onFill = () => {
		if (this.formRef.current !== null) {
			this.formRef.current.setFieldsValue(this.props.record);
		}
	};

	validateFields = () => {
		const validatedData = this.props.drawerList.map((validate) => {
			console.log('fff', typeof (parseInt(validate.closing_stock)));
			return Boolean(parseInt(validate.closing_stock) || parseInt(validate.closing_stock) === 0)
				? ''
				: (validate.error = 'please fill the data num type only supported');
		});

		this.setState(validatedData);

		const data = validatedData.filter((data) => data !== '');
		console.log(data.length);
		return data.length;
	};


	changeTargets = (index, event) => {
		const updatedArray = [...this.state.input];
		updatedArray[index].closing_stock = event.target.value;
		this.setState(updatedArray);
	};

	onUpdate = (data) => {
		data.date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		patch_foodwastage_bill_api(data).then(() => {
			this.props.callTableapi(); this.onClose();
		}).catch(({ response }) => {
			const { data, status } = response;
			if (status === 400) {
				if (data.hasOwnProperty("quantity")) {
					message.error("quantity : " + data.quantity);
				} else if (data.hasOwnProperty("mrp_price")) {
					message.error("Mrp Price : " + data.mrp_price);
				}
			}
		});
	};

	render() {

		const { Option } = Select;

		return (
			<React.Fragment>
				<EditOutlined onClick={this.showDrawer} />
				<Drawer
					title="Foodwastage Update Form"
					width={"400"}
					onClose={this.onClose}
					visible={this.state.visible}
					bodyStyle={{ paddingBottom: 80 }}
					footer={
						<div
							style={{
								textAlign: 'right'
							}}
						>
							<Button onClick={this.onClose} style={{ marginRight: 8 }}>
								Cancel
							</Button>
						</div>
					}
				>
					<Form
						ref={this.formRef}
						className="edit_form"
						onFinish={this.onUpdate}
						layout="horizontal"
						name="form_in_modal"
					>

						<Form.Item
							name="id"
							style={{ display: 'none' }}
						>
							<Input />
						</Form.Item>

						<Form.Item
							name="product"
							label="Product"
							rules={[
								{
									required: true,
									message: 'Please select item!'
								}
							]}
						>
							<Select
								defaultValue=""
							>
								<Option value="">Select Item</Option>
								{
									this.props.Allproductlist.map((item) => <Option value={item.id}>{item.name}</Option>)
								}
							</Select>
						</Form.Item>

						<Form.Item
							name="quantity"
							label="Qty"
							rules={[
								{
									required: true,
									message: 'Please input qty!'
								}
							]}
						>
							<InputNumber />
						</Form.Item>

						<Form.Item
							name="mrp_price"
							label="mrp price"
							rules={[
								{
									required: true,
									message: 'Please input mrp price!'
								}
							]}
						>
							<InputNumber />
						</Form.Item>

						<Form.Item
							name="description"
							label="Remark"
							rules={[
								{
									required: true,
									message: 'Please input remark!'
								}
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							name="wasted_by"
							label="Wasted By"
							rules={[
								{
									required: true,
									message: 'Please select!'
								}
							]}
						>
							<Select
								defaultValue=""
							>
								<Option value="" > Select  Person</Option>
								{
									this.props.Branchspecificuserlist.map((item) => <Option value={item.id}>{item.first_name}</Option>)
								}
							</Select>
						</Form.Item>


						<>

							<Button type="primary" htmlType="submit">
								Update
					</Button>
						</>
					</Form>
				</Drawer>
			</React.Fragment>
		);
	}
}

export default Foodwastageupdateform;
