import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio, Select, DatePicker } from 'antd';
// import {addFreebills} from '../../../handler/LocalDB_handler/FreebillServiceHandler'
import { post_Freebill_details } from '../../../handler/api_handler';
import { message } from 'antd';

import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const Freebillmodel = ({ visible, onCreate, onCancel, User, Freebillcustomer }) => {
  const [form] = Form.useForm();
  console.log('User det main', Freebillcustomer);
  return (
    <Modal
      visible={visible}
      title="Create a new Freebill"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form className="edit_form"
        form={form}
        layout="horizontal"
        name="form_in_modal"

      >
        <Form.Item
          name="bill_no"
          label="Bill No"
          rules={[
            {
              required: true,
              message: 'Please input the name of bill no!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="amount"
          label="amount"
          rules={[
            {
              required: true,
              message: 'Please input the amount!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="date"
          label="Billed on"
          rules={[
            {
              required: true,
              message: 'Please input the DOJ of User!',
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="billed_by"
          label="Billed by"
          rules={[
            {
              required: true,
              message: 'Please input the status!',
            },
          ]}
        >
          <Select style={{ width: 120 }}
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
            {
              User.map(users => {
               return <Option value={users.id}>{users.username}</Option>
              })
            }
          </Select>
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: 'Please input the description!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="billed_for"
          label="Billed for"
          rules={[
            {
              required: true,
              message: 'Please input the billed for!',
            },
          ]}
        >
          <Select
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
            {
              Freebillcustomer.map(freebillcustomer => {
                return <Option value={freebillcustomer.id}>{freebillcustomer.name}</Option>
                
              })
            }
          </Select>
        </Form.Item>

      </Form>
    </Modal>
  );
};

const AddFreebillmodel = (props) => {
  const [visible, setVisible] = useState(false);
console.log('User d', props.User);
  const onCreate = (values) => {
    post_Freebill_details(values).then(() => {
      message.success("Added successfully");
      props.setdata();
    }).catch((err) => {

    });
    setVisible(false);
  };

  return (
    <div className="main_top">
      <h2>Free Bill</h2>
      <Button className="addbtn" style={{ marginTop: 0, marginRight: '0' }}
        onClick={() => {
          setVisible(true);
        }}
      ><PlusOutlined />
        Add Freebill
      </Button>
      <Freebillmodel
        Freebillcustomer={props.Freebillcustomer}
        visible={visible}
        User={props.User}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

export default AddFreebillmodel;