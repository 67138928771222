import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio , InputNumber } from 'antd';
import { message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { post_branch_specific_wrongbill_api, branch_specific_wrongbill_api } from '../../handler/api_handler';

const AddBillmodel = (props) => {

	const [form] = Form.useForm();

	const onsubmit = (data) => {
		data.date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		post_branch_specific_wrongbill_api(data).then(() => {
			props.callTableapi();
			props.onChildrenDrawerClose();
			form.resetFields();
		}).catch(({ response }) => {
			const { data, status } = response;
			if (status === 400) {
				message.error(data.bill_no[0]);
			}
		});
	};

	return (
		<div className="main_top">
			<Form
				form={form}
				className="edit_form"
				onFinish={onsubmit}
				layout="horizontal"
				name="form_in_modal"
			>
				<Form.Item
					name="bill_no"
					label="bill no"
					rules={[
						{
							required: true,
							message: 'Please input the name of Bill!'
						}
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="wrong_amount"
					label="wrong amount"
				>
					<InputNumber />
				</Form.Item>

				<Form.Item
					name="correct_amount"
					label="correct amount"
					rules={[
						{
							required: true,
							message: 'Please input the correct_amount of Bill!'
						}
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="description"
					label="Remark"
					rules={[
						{
							required: true,
							message: 'Please input the description of Bill!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default AddBillmodel;
