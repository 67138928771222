import React, { useState, Component } from 'react';
import { Button, Modal, Form, Input, Radio, Switch, Select, DatePicker } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
// import {updateWrongbills} from '../../../handler/LocalDB_handler/WrongbillServiceHandler'
import { update_Wrongbill_details } from '../../../handler/api_handler';
import { message } from 'antd';

const { Option } = Select;

class UpdateWrongbillmodel extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    visible: false
  }
  onFill = () => {
    delete this.props.Wrongbill_data.updated
    delete this.props.Wrongbill_data.created

    if (this.formRef.current !== null) {
      this.formRef.current.setFieldsValue(this.props.Wrongbill_data);
    }
  };

  setVisible() {
    this.setState({ visible: !this.state.visible })
  }


  componentDidUpdate(prevProps, prevState) {
    const Settimeout = this.onFill;
    setTimeout(function () { Settimeout() }, 100);
  }

  onCreate = (value) => {
    update_Wrongbill_details(value).then((

    ) => {
      message.success("Updated successfully");
      this.props.setdata();
      this.setVisible();
    }).catch(() => { });
  }
  render() {
    console.log('User data', this.props.User);
    return (
      <div>
        <EditTwoTone
          style={{ fontSize: '20px' }}
          type="primary"
          onClick={() => {
            this.setVisible(true);
          }}
        >
          Update Wrongbill
      </EditTwoTone>
        <Modal
          title="Edit wrongbill"
          visible={this.state.visible}
          footer={null}
          onCancel={this.setVisible.bind(this)}
        >
          <Form ref={this.formRef} className="edit_form" layout="horizontal"

            {...this.layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={this.onCreate}
          //   onFinishFailed={this.onFinishFailed}
          >

            <Form.Item name="id" style={{ display: "none" }} > <Input type="hidden" /> </Form.Item>

            <Form.Item
              name="bill_no"
              label="Bill no"
              rules={[
                {
                  required: true,
                  message: 'Please input the name of Wrongbill!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="wrong_amount"
              label="Wrong amount"
              rules={[
                {
                  required: true,
                  message: 'Please input the amount!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="correct_amount"
              label="Correct amount"
              rules={[
                {
                  required: true,
                  message: 'Please input the amount!',
                },
              ]}
            >
              <Input />
            </Form.Item>



            <Form.Item
              name="billed_by"
              label="Billed by"
              rules={[
                {
                  required: true,
                  message: 'Please input the status!',
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>

                {this.props.User.map(data => {
                  return (<Option value={data.id}>{data.username}</Option>)
                })}
              </Select>
            </Form.Item>


            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: 'Please input the description!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item {...this.tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
        </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default UpdateWrongbillmodel;