import React, { createContext, useState, useEffect } from 'react';
import {get_complaint_category_details} from '../handler/api_handler'

export const ComplaintCategoryContext = createContext();

const ComplaintCategoryContextProvider = (props) => {
    const [Product, setProduct] = useState("");
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        setProductData();
    }, [])

    
    const setProductData = () => {
        get_complaint_category_details().then((response) => {

            setProduct(response.data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        })
        
    }


    return (
        <ComplaintCategoryContext.Provider value={{ Product, setProductData,loading}}>
            {props.children}
        </ComplaintCategoryContext.Provider>
    );
};

export default ComplaintCategoryContextProvider;