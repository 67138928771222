import React, { useState } from 'react';
import { Button, Modal, Form, Input, Select } from 'antd';
// import {addComplaints} from '../../../handler/LocalDB_handler/ComplaintServiceHandler'
import { message } from 'antd';
import {post_complaint_details} from '../../../handler/api_handler';

const {Option} = Select;

const ComplaintModel = ({ visible, onCreate, onCancel,status,type }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Create a new Complaint"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
       
      >
        <Form.Item
          name="title"
          label="title"
          rules={[
            {
              required: true,
              message: 'Please input the title of Complaint!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="description"
         
        >
          <Input />
        </Form.Item>
       
        {/* <Form.Item
          name="complainted_by"
          label="complainted_by"
          rules={[
            {
              required: true,
              message: 'Please input the complainted_by of Complaint!',
            },
          ]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          name="complaint_type"
          label="complaint_type"
          rules={[
            {
              required: true,
              message: 'Please input the complaint_type of Complaint!',
            },
          ]}
        >
          <Select>
           {type.map((data)=>{
              return (
                <Option value={data.id}>{data.name}</Option>
              )
           })}  
         </Select>
        </Form.Item>
        <Form.Item
          name="status"
          label="status"
          rules={[
            {
              required: true,
              message: 'Please input the status of Complaint!',
            },
          ]}
        >
         <Select>
           {status.map((data)=>{
              return (
                <Option value={data.id}>{data.name}</Option>
              )
           })}  
         </Select>
        </Form.Item>
        
      </Form>
    </Modal>
  );
};

const AddComplaintModel = (props) => {
  const [visible, setVisible] = useState(false);

  const onCreate = (values) => {
    post_complaint_details(values).then(() =>{
      message.success("Added successfully");
     props.setdata();
    }).catch((err) =>{

    });
    setVisible(false);
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      >
       Add Complaint
      </Button>
      <ComplaintModel
        visible={visible}
        onCreate={onCreate}
        status={props.status}
        type={props.type}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

export default AddComplaintModel;