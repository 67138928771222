import React, { createContext, useState, useEffect } from 'react';
// import { message } from 'antd';
import { get_store_details, get_state_details, get_city_details } from '../handler/api_handler'

export const StoreContext = createContext();

const StoreContextProvider = (props) => {
    const [Store,setStore] = useState("");
    const [State,setState] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updateModelVisibility,setupdateModelVisibility] = useState(false);
    const [city,setcity] = useState([]);
    const [updateStore,setupdateStore] = useState([]);

    useEffect(() => {
        setStoreData();
        setStatedetails();
    }, [])

    const setStoreData = () => {
        get_store_details().then((details) => {
            setStore(details.data);
            setLoading(false);
        }).catch((err) => {

        })
    }

    const setVisible = (data) => {
        if (!updateModelVisibility) {
            onChangeState(data.state);
            setupdateStore(data);
        }
        setupdateModelVisibility(!updateModelVisibility);
    }

    const setStatedetails = () => {
        get_state_details().then((response) => {
            setState(response.data)
        }).catch((err) => { })
    }

    const onChangeState = (id) => {
        get_city_details(id).then((response) => {
            setcity(response.data);
        }).catch((err) => { })
    }

    const onchangeStateUpdate = (state_id, id) => {
        console.log(state_id, id);
    }

    return (
        <StoreContext.Provider value={{ 
            setStoreData,
            Store,
            loading, 
            State,
            onChangeState,
            city,
            onchangeStateUpdate,
            updateModelVisibility,
            setVisible,
            updateStore,
            setcity
        }}>
            {props.children}
        </StoreContext.Provider>
    );
};

export default StoreContextProvider;