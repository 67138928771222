import React, { useState, Component } from 'react';
import { Button, Modal, Form, Input, Radio, Switch, Row, Col } from 'antd';
import { EditTwoTone, CheckCircleOutlined } from '@ant-design/icons';
// import {updateSubroles} from '../../../handler/LocalDB_handler/SubroleServiceHandler'
import { update_subrole_details } from '../../../handler/api_handler';
import { message } from 'antd';



class UpdateSubrolemodel extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

  }
  state = {
    visible: false
  }
  onFill = () => {
    delete this.props.role_data.updated
    delete this.props.role_data.created

    if (this.formRef.current !== null) {
      this.formRef.current.setFieldsValue(this.props.role_data);
    }
  };

  setVisible() {
    this.setState({ visible: !this.state.visible })
  }


  componentDidUpdate(prevProps, prevState) {
    const Settimeout = this.onFill;
    setTimeout(function () { Settimeout() }, 100);
  }

  onCreate = (value) => {
    update_subrole_details(value).then((

    ) => {
      message.success("Updated successfully");
      this.props.setdata();
      this.setVisible();
    }).catch(() => { });
  }
  render() {
    return (
      <div>
        <EditTwoTone twoToneColor="#FFA500"
          style={{ fontSize: '20px' }}
          type="primary"
          onClick={() => {

            this.setVisible(true);
          }}
        >
          Update Subrole
      </EditTwoTone>
        <Modal
          title="Edit Subrole"
          visible={this.state.visible}
          footer={null}
          onCancel={this.setVisible.bind(this)}
        >
          <Form className="edit_form" ref={this.formRef}

            {...this.layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={this.onCreate}
          //   onFinishFailed={this.onFinishFailed}
          >
            <Row>
              <Col span={12}>
                <Form.Item name="id" style={{ display: "none" }} > <Input type="hidden" /> </Form.Item>

                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input the name of Subrole!',
                        whitespace : true,
                      },
                    ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                    name="description"
                    label="description"

                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>


            {/* <Form.Item
              name="status"
              label="status"

            >
              <Switch />

            </Form.Item> */}
            <Form.Item {...this.tailLayout}>
              <div className="edit_button_block">
                <Button type="primary" htmlType="submit">
                  <CheckCircleOutlined /> Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>

      </div>
    );
  }
}

export default UpdateSubrolemodel;