import React, { useState, useEffect } from 'react';
import { Drawer, Table, Button, Popconfirm } from 'antd';
import { get_cash_handover_list, branch_specific_user_list_api,delete_cash_handover } from '../../handler/api_handler';
import Cashhandoverupdateform from './CashHandoverupdateform';
import Cashhandovercreateform from './CashHandovercreateform';
import { DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';

function onChange(pagination, filters, sorter, extra) {
	console.log('params', pagination, filters, sorter, extra);
}

function Ebbilltable(props) {
	console.log('props.visible', props.visible);

	const [ wdata, setdata ] = useState([]);
	const [ user, setuser ] = useState([]);

	const [ childrenDrawer, setchildrenDrawer ] = useState(false);

	function confirm(e) {
		console.log(e);
		delete_cash_handover(e.id).then(() =>{
			callTableapi();
		}).catch(() =>{})

		// message.success('Click on Yes');
	}
	const showChildrenDrawer = () => {
		setchildrenDrawer(true);
	};

	const onChildrenDrawerClose = () => {
		setchildrenDrawer(false);
	};

	const callTableapi = () => {
		const date = moment(new Date()).format('YYYY-MM-DD');
		get_cash_handover_list(date)
			.then((response) => {
				setdata(response.data);
			})
			.catch((err) => {});


		
	};

	const branch =()=>{
		branch_specific_user_list_api().then((res)=>{
			setuser(res.data)
		}).catch((err) => {})
	}

	const columns = [
		{
			title: 'bill_no',
			dataIndex: 'bill_no',
			width: '10%'
		},
		{
			title: 'amount',
			dataIndex: 'amount',
			width: '20%'
		},
		{
			title: 'name',
			dataIndex: 'name',
			width: '20%'
		},
		{
			title: 'Time',
			dataIndex: 'time',
			render: (text, record) => moment(record.time, 'HH:mm:ss').format(' hh:mm:ss A')
		},

		{
			title: 'Action',
			dataIndex: 'Action',
			render: (text, record) => (
				<Popconfirm
					title="Are you sure to delete this task?"
					onConfirm={confirm.bind(null, record)}
					okText="Yes"
					cancelText="No"
				>
					<DeleteOutlined />
				</Popconfirm>
			)
		}
	];

	useEffect(
		() => {
			if (props.visible) {
				callTableapi();
				branch();
			}
		},
		[ props.visible ]
	);

	return (
		<React.Fragment>
			<Button type="primary" style={{ margin: '15px', float: 'right' }} onClick={showChildrenDrawer}>
				Add
			</Button>

			<Drawer
				title="Cash Handover"
				width={750}
				closable={true}
				onClose={onChildrenDrawerClose}
				visible={childrenDrawer}
			>
				<Cashhandovercreateform user={user} callTableapi={callTableapi} onChildrenDrawerClose={onChildrenDrawerClose} />
			</Drawer>

			<Table pagination={false} columns={columns} dataSource={wdata} onChange={onChange} />
		</React.Fragment>
	);
}

export default Ebbilltable;
