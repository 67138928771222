import React, { useState, useEffect } from 'react';
import { Drawer, Table, Button, Select } from 'antd';
import { get_sales_count_create_api, branch_specific_user_list_api, get_sales_product_details, get_product_type , destroy_sales_count_create_api } from '../../handler/api_handler';
import Departmentsalescountcreateform from './Departmentsalescountcreateform';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';

function onChange(pagination, filters, sorter, extra) {
	console.log('params', pagination, filters, sorter, extra);
}


function Departmentsalescounttable(props) {

	let { Option } = Select;

	const [wdata, setdata] = useState([]);

	const [childrenDrawer, setchildrenDrawer] = useState(false);

	const [Branchspecificuserlist, setBranchspecificuserlist] = useState([]);

	const [Allproductlist, setAllproductlist] = useState([]);


	const showChildrenDrawer = () => {
		setchildrenDrawer(true);
	};

	const hideChildrenDrawer = () => {
		setchildrenDrawer(false);
	}

	const onChildrenDrawerClose = () => {
		setchildrenDrawer(false);
	}

	const DeleteApiCall = (id) => {
		destroy_sales_count_create_api(id).then(({ data }) => {
			callTableapi();
		}).catch(err => {

		})
	}

	const callTableapi = () => {

		const date = moment(new Date()).format('YYYY-MM-DD');
		get_sales_count_create_api(0,date)
			.then((response) => {
				setdata(response.data);
			})
			.catch((err) => { });

		if (Allproductlist.length === 0) {
			get_sales_product_details().then(({ data }) => {
				setAllproductlist(data);
			}).catch(() => {

			});
		}


		if (Branchspecificuserlist.length === 0) {
			branch_specific_user_list_api().then(({ data }) => {
				setBranchspecificuserlist(data);
			}).catch(() => {

			});
		}


	}

	const columns = [
		{
			title: 'Employee Name',
			dataIndex: 'employee_name',
			width: '10%',
		},
		{
			title: 'Section Name',
			dataIndex: 'section_name',
			width: '10%',
		},
		{
			title: 'Product Name',
			dataIndex: 'product_name',
			width: '10%'
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			width: '10%'
		},
		{
			title: 'Action',
			dataIndex: 'Action',
			render: (text, record) => (<DeleteOutlined onClick={() => { DeleteApiCall(record.id) }} />)
		}
	];

	useEffect(() => {
		if (props.visible) {
			callTableapi();
		}
	}, [props.visible]);

	return (
		<React.Fragment>
			<Button type="primary" style={{ "margin": "15px", float: 'right' }} onClick={showChildrenDrawer}>
				Add
			</Button>

			<Drawer
				title="Department Sales Count"
				width={500}
				closable={true}
				onClose={onChildrenDrawerClose}
				visible={childrenDrawer}
			>
				<Departmentsalescountcreateform
					childrenDrawer = {childrenDrawer}
					callTableapi={callTableapi}
					onChildrenDrawerClose={onChildrenDrawerClose}
					hideChildrenDrawer={hideChildrenDrawer}
					Branchspecificuserlist={Branchspecificuserlist}
					Allproductlist={Allproductlist}
				/>
			</Drawer>

			<Table pagination={false} columns={columns} dataSource={wdata} onChange={onChange} />
		</React.Fragment>
	);
}

export default Departmentsalescounttable;
