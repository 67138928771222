import React from 'react';
import { Drawer } from 'antd';
import Foodwastagetable from './Foodwastagetable';
import { DailysheetContext } from '../../context/Dailysheetcontext.js';

class Foodwastage extends React.Component {
	state = { visible: true, childrenDrawer: false };

	static contextType = DailysheetContext;

	showDrawer = () => {
		this.setState({
			visible: false
		});
	};

	onClose = () => {
		this.context.callDailysheetapi();
		this.props.setFoodwastageDrawerVisibile(!this.props.FoodwastageDrawerVisibile);
	};

	render() {
		return (
			<React.Fragment>
				<Drawer
					title="Foodwastage"
					width={'100%'}
					closable={true}
					onClose={this.onClose}
					visible={this.props.FoodwastageDrawerVisibile}
				>
					<div>
						<Foodwastagetable visible={this.props.FoodwastageDrawerVisibile} />
					</div>
					
				</Drawer>
			</React.Fragment>
		);
	}
}

export default Foodwastage;
