import React, { createContext, useState, useEffect } from 'react';
import {get_role_details} from '../handler/api_handler';
export const RoleContext = createContext();

const RoleContextProvider = (props) => {
    const [Role, setRole] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);

    useEffect(() => {
        setRoleData();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setRoleData = () => {
        get_role_details().then((details) => {
           
            // setRole(details.length === undefined ?[details] :details);
            setRole(details.data);

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <RoleContext.Provider value={{ Role, loading, setRoleData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </RoleContext.Provider>
    );
};

export default RoleContextProvider;