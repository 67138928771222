import React, { Component } from 'react';
import {
	SearchOutlined,
	DeleteTwoTone,
	CheckCircleTwoTone,
	CloseCircleTwoTone,
	EditOutlined,
	EditTwoTone
} from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin, Anchor, Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import { StoreContext } from '../../context/Storecontext';
import Addstoremodels from '../Store/AddStoreModel/';
import { delete_store_details, status_store_update } from '../../handler/api_handler';
import Updatestorecomponent from './UpdateStoreModel/';
import { withRouter } from 'react-router';
import { ReloadOutlined } from '@ant-design/icons';

class StoreComponent extends Component {
	static contextType = StoreContext;

	state = {
		searchText: '',
		searchedColumn: ''
	};
	handleRedirect = (e, link) => {
		console.log(link);

		this.props.history(`${link.href}`);
		e.preventDefault();
	};
	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [ e.target.value ] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
					>
						Search
					</Button>
					<Button type="danger" onClick={() => this.handleReset(clearFilters)}>
						<ReloadOutlined />
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[ this.state.searchText ]}
					autoEscapeUpdatestorecomponent
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});
	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};
	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};
	handleStatus = (id, status) => {
		status_store_update(id, status)
			.then(() => {
				this.context.setStoreData();
			})
			.catch(() => {});
	};
	handleOk = (id) => {
		delete_store_details(id)
			.then(() => {
				this.context.setStoreData();
			})
			.catch(() => {});
	};
	render() {
		const {
			Store,
			setStoreData,
			city,
			State,
			onChangeState,
			setVisible,
			updateModelVisibility,
			updateStore
		} = this.context;
		console.log(city);
		console.log(this.context);
		const { Link } = Anchor;

		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'id',
				...this.getColumnSearchProps('name')
			},
			// {
			// 	title: 'address1',
			// 	dataIndex: 'address1',
			// 	key: 'id',

			// 	...this.getColumnSearchProps('address1')
			// },
			// {
			// 	title: 'address2',
			// 	dataIndex: 'address2',
			// 	key: 'id',
			// 	...this.getColumnSearchProps('address2')
			// },
			{
				title: 'city',
				dataIndex: 'city_name',
				key: 'id',
				...this.getColumnSearchProps('city_name')
			},
			// {
			// 	title: 'district',
			// 	dataIndex: 'district',
			// 	key: 'id',
			// 	...this.getColumnSearchProps('district')
			// },
			{
				title: 'state',
				dataIndex: 'state_name',
				key: 'id',
				...this.getColumnSearchProps('state_name')
			},
			// {
			// 	title: 'latitude',
			// 	dataIndex: 'latitude',
			// 	key: 'id',
			// 	...this.getColumnSearchProps('latitude')
			// },
			// {
			// 	title: 'longitude',
			// 	dataIndex: 'longitude',
			// 	key: 'id',
			// 	...this.getColumnSearchProps('longitude')
			// },

			{
				title: 'Sub branch count',
				dataIndex: 'branch_count',
				key: 'id',
				render: (text, record) => (
					<Anchor affix={false} onClick={this.handleRedirect}>
						<Link href={`/subbranch/${record.id}/`} title={record.sub_branch_count} />
					</Anchor>
				)
			},
			// {
			// 	title: 'onboard Product',
			// 	// dataIndex: 'branch_count',
			// 	key: 'id',
			// 	render: (text, record) => (
			// 		<Anchor affix={false} onClick={this.handleRedirect}>
			// 			<Link
			// 				href={`/product_mapping/${record.id}/`}
			// 				title={<Tag color="#2db7f5">{' onboard Product '}</Tag>}
			// 			/>
			// 		</Anchor>
			// 	)
			// },
			// {
			// 	title: 'status',
			// 	dataIndex: 'status',
			// 	key: 'id',
			// 	render: (text, record) => (
			// 		<Space>
			// 			<Popconfirm
			// 				title="Are you sure to change status？"
			// 				onConfirm={() => this.handleStatus(record.id, record.status)}
			// 				okText="Yes"
			// 				cancelText="No"
			// 			>
			// 				{record.status ? (
			// 					<CheckCircleTwoTone twoToneColor="#52c41a" />
			// 				) : (
			// 					<CloseCircleTwoTone twoToneColor="#FF0000" />
			// 				)}
			// 			</Popconfirm>
			// 		</Space>
			// 	)
			// }
			,
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<a onClick={setVisible.bind(this, record)}>
							<EditTwoTone twoToneColor="#FFA500"
										 style={{ fontSize: '20px' }}
										 type="primary" />
						</a>

						<Popconfirm
							title="Are you sure to delete？"
							onConfirm={() => this.handleOk(record.id)}
							okText="Yes"
							cancelText="No"
						>
							<DeleteTwoTone twoToneColor="#d11a2a" style={{ fontSize: '20px' }} />
						</Popconfirm>
					</Space>
				)
			}
		];
		return (
			<React.Fragment>
				<Addstoremodels setdata={setStoreData} state={State} city={city} onchangeState={onChangeState} />
				<Updatestorecomponent updateModelVisibility={this.context.updateModelVisibility}/>
				<Spin spinning={this.context.loading} delay={500}>
					<Table columns={columns} dataSource={Store} />
				</Spin>
			</React.Fragment>
		);
	}
}

export default withRouter(StoreComponent);
