import React, { createContext, useState, useEffect } from 'react';
import {get_Bulkorder_details} from '../handler/api_handler';
export const BulkorderContext = createContext();

const BulkorderContextProvider = (props) => {
    const [Bulkorder, setBulkorder] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);

    useEffect(() => {
        setBulkorderData();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setBulkorderData = () => {
        get_Bulkorder_details().then((details) => {
            // setBulkorder(details.length === undefined ?[details] :details);
            setBulkorder(details.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <BulkorderContext.Provider value={{ Bulkorder, loading, setBulkorderData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </BulkorderContext.Provider>
    );
};

export default BulkorderContextProvider;