import React, { useContext } from 'react';
import { Row, Col, Select, InputNumber } from 'antd';
import { VegInventoryContext } from '../../context/VegInventoryContext';
import { DeleteOutlined, DeleteTwoTone } from '@ant-design/icons';
import { store_product_instock_count_api } from '../../handler/api_handler';
import SelectForm from './SelectForm';

const Formfiled = (props) => {
    const Contextdata = useContext(VegInventoryContext);
    const { Option } = Select;

    const Remove = () => {
        Contextdata.Removerow(props.index);
    }

    const productwiseInstockcount = (productid) => {
        store_product_instock_count_api(productid).then(({ data }) => {
            let stock_in_hand = Number(data.product_count);
            let buying_price = Number(data.mrp_price);
            let Listingdata = [...Contextdata.Lising];
            let new_quantity_in_hand = stock_in_hand + Number(Listingdata[props.index].quantity);
            Listingdata[props.index].new_quantity_in_hand = new_quantity_in_hand;
            Listingdata[props.index].stock_in_hand = stock_in_hand;
            Listingdata[props.index].buying_price = buying_price;
            Contextdata.setLising(Listingdata);
        }).catch(() => {

        });
    }

    const onProductChange = (productid) => {
        const isExist = Contextdata.Lising.some(el => el.product === productid);
        if (!isExist) {
            let Listingdata = [...Contextdata.Lising];
            Listingdata[props.index].product = productid;
            Contextdata.setLising(Listingdata);
            productwiseInstockcount(productid);
        } else {
            alert("Product already exist");
            let Listingdata = [...Contextdata.Lising];
            Listingdata[props.index] = Contextdata.InitialData;
            Contextdata.setLising(Listingdata);
        }
    }

    const onQuantirychange = (quantity) => {
        if (!isNaN(quantity)) {
            let Listingdata = [...Contextdata.Lising];
            let new_quantity_in_hand = Number(Listingdata[props.index].stock_in_hand) + Number(quantity);
            Listingdata[props.index].new_quantity_in_hand = new_quantity_in_hand;
            Listingdata[props.index].quantity = quantity;
            Contextdata.setLising(Listingdata);
        }
    }

    const onBuying_pricechange = (buying_price) => {
        if (!isNaN(buying_price)) {
            let Listingdata = [...Contextdata.Lising];
            Listingdata[props.index].buying_price = buying_price;
            Contextdata.setLising(Listingdata);
        }
    }


    return (
        <tr style={{ textAlign: 'center' }}>
            <td>{props.item.productname}</td>
            <td>{props.item.stock_in_hand}</td>
            <td><InputNumber onChange={onQuantirychange} value={props.item.quantity} /></td>
            <td><InputNumber onChange={onBuying_pricechange} value={props.item.buying_price} /></td>
            <td>{props.item.new_quantity_in_hand}</td>
            {/* <td>{Contextdata.Lising.length !== 1 ? (<DeleteTwoTone twoToneColor="#d11a2a" onClick={Remove} style={{ cursor: 'pointer', color: 'red', fontSize: '20px' }} />) : ""} </td> */}
        </tr>
    );
}

export default Formfiled;