import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio, Select } from 'antd';
import { message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { credit_sale_create_api, branch_specific_wrongbill_api } from '../../handler/api_handler';
const { Option } = Select;
const Ebbillcreateform = (props) => {
	const [ form ] = Form.useForm();
	const onsubmit = (data) => {
		data.date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		credit_sale_create_api(data)
			.then(() => {
				props.callTableapi();
				props.onChildrenDrawerClose();
				form.resetFields();
			})
			.catch(({ response }) => {
				const { data, status } = response;
				if (status === 400) {
					if (data.hasOwnProperty("bill_no")) {
						message.error("Bill No : " + data.bill_no[0]);
					} else if (data.hasOwnProperty("amount")) {
						message.error("Amount : " + data.amount[0]);
					}
				}
			});
	};
	const { customer } = props;
	return (
		<div className="main_top">
			<Form
			form={form}
			className="edit_form" onFinish={onsubmit} layout="horizontal" name="form_in_modal">
				<Form.Item
					name="customer"
					label="customer"
					rules={[
						{
							required: true,
							message: 'Please input the customer!'
						}
					]}
				>
					<Select>
						{customer.map((data) => {
							return <Option value={data.id}>{data.name}</Option>;
						})}
					</Select>
				</Form.Item>
				<Form.Item
					name="bill_no"
					label="bill no"
					rules={[
						{
							required: true,
							message: 'Please input the bill no!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="amount"
					label="Amounts"
					rules={[
						{
							required: true,
							message: 'Please input the amount of bill!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="description"
					label="Remark"
					rules={[
						{
							required: true,
							message: 'Please input the description of Bill!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default Ebbillcreateform;
