import React, { Component } from 'react';
import { Drawer } from 'antd';
import { ProfileTwoTone, HistoryOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
import { Usercontext } from '../../../context/Usercontext';

class SalaryHistorBreak extends Component {

    render() {
        return (
            <React.Fragment>
                <table>
                    <tr>
                        <td>
                            Base
                        </td>
                        <td> - </td>
                        <td>
                            &#8377; <b>{this.props.saldata.per_day} </b> / day
                        </td>
                    </tr>
                    <tr>
                        <td>
                            OT
                        </td>
                        <td> - </td>
                        <td>
                            &#8377;  <b> {this.props.saldata.ot_per_hour} </b> / hr
                        </td>
                    </tr>
                </table>
            </React.Fragment>
        );
    }
}


class SalaryDetailmodel extends Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    static contextType = Usercontext;
    state = {
        visible: false
    }


    setVisible() {
        this.setState({ visible: !this.state.visible })
    }



    render() {
        return (
            <div>
                <HistoryOutlined style={{ fontSize: '20px', color: '#52c41a' }} type="primary" onClick={() => { this.setVisible(true); }} > </HistoryOutlined>
                <Drawer
                    width={500}
                    title="Salary increment history"
                    placement="right"
                    closable={false}
                    onClose={this.setVisible.bind(this)}
                    visible={this.state.visible}
                >
                    <Timeline mode={'left'}>
                        {this.props.salary_history.map((saldata) => <Timeline.Item label={saldata.formated_date} > <SalaryHistorBreak saldata={saldata} /></Timeline.Item>)}
                    </Timeline>
                </Drawer>
            </div>
        );
    }
}

export default SalaryDetailmodel;