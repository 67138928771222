import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio } from 'antd';
import { post_branch_details } from '../../../handler/api_handler';
import { message } from 'antd';
import {PlusOutlined} from "@ant-design/icons";

const Branchmodel = ({ visible, onCreate, onCancel }) => {
	const [ form ] = Form.useForm();
	return (
		<Modal
			visible={visible}
			title="Create a new sub-branch"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form className="edit_form" form={form} layout="horizontal" name="form_in_modal">
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true,
							message: 'Please input the name of sub-branch!',
							whitespace: true,
						}
					]}
				>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const AddBranchmodel = (props) => {
	const [ visible, setVisible ] = useState(false);

	const onCreate = (values) => {
		values.store = props.store;
		post_branch_details(props.store,values)
			.then(() => {
				message.success('Added successfully');
				props.setdata();
				setVisible(false);
			})
			.catch((err) => {});
	};

	return (
		<div className="main_top">
			<h2>Branch</h2>
			<div className="bottom_border"></div>
			<Button className="addbtn" style={{marginTop: 0, marginRight: '0'}}
				type="primary"
				onClick={() => {
					setVisible(true);
				}}
			><PlusOutlined />
				Add sub-branch
			</Button>
			<Branchmodel
				visible={visible}
				onCreate={onCreate}
				onCancel={() => {
					setVisible(false);
				}}
			/>
		</div>
	);
};

export default AddBranchmodel;
