import React, { useState, useEffect } from 'react';
import { Drawer, Table, Button } from 'antd';
import { get_food_wastage_list_api, branch_specific_user_list_api , all_product_list_api } from '../../handler/api_handler';
import Foodwastageupdateform from './Foodwastageupdateform';
import Foodwastagecreateform from './Foodwastagecreateform';


import moment from 'moment';


function onChange(pagination, filters, sorter, extra) {
	console.log('params', pagination, filters, sorter, extra);
}



function Foodwastagetable(props) {

	console.log("props.visible", props.visible);

	const [wdata, setdata] = useState([]);

	const [childrenDrawer, setchildrenDrawer] = useState(false);

	const [Branchspecificuserlist, setBranchspecificuserlist] = useState([]);

	const [Allproductlist, setAllproductlist] = useState([]);

	const showChildrenDrawer = () => {
		setchildrenDrawer(true);
	};

	const onChildrenDrawerClose = () => {
		setchildrenDrawer(false);
	}

	const callTableapi = () => {

		const date = moment(new Date()).format('YYYY-MM-DD');
		get_food_wastage_list_api(date)
			.then((response) => {
				setdata(response.data);
			})
			.catch((err) => { });

		if (Allproductlist.length === 0) {
			all_product_list_api().then(({ data }) => {
				setAllproductlist(data);
			}).catch(() => {

			});
		}


		if (Branchspecificuserlist.length === 0) {
			branch_specific_user_list_api().then(({ data }) => {
				setBranchspecificuserlist(data);
			}).catch(() => {

			});
		}

	

	}

	const columns = [
		{
			title: 'Product Name',
			dataIndex: 'product_name',
			width: '10%'
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			width: '10%'
		},
		{
			title: 'MRP Price',
			dataIndex: 'mrp_price',
			width: '10%',
		},
		{
			title: 'wasted by',
			dataIndex: 'wasted_by_name'
		},
		{
			title: 'Remark',
			dataIndex: 'description'
		},
		{
			title: 'Action',
			dataIndex: 'Action',
			render: (text, record) => (<Foodwastageupdateform record={record} callTableapi={callTableapi} Branchspecificuserlist={Branchspecificuserlist} Allproductlist={Allproductlist}/>)
		}
	];

	useEffect(() => {
		if (props.visible) {
			callTableapi();
		}
	}, [props.visible]);

	return (
		<React.Fragment>
			<Button type="primary" style={{ "margin": "15px", float: 'right' }} onClick={showChildrenDrawer}>
				Add
			</Button>

			<Drawer
				title="Food Wastage"
				width={400}
				closable={true}
				onClose={onChildrenDrawerClose}
				visible={childrenDrawer}
			>
				<Foodwastagecreateform callTableapi={callTableapi} onChildrenDrawerClose={onChildrenDrawerClose} Branchspecificuserlist={Branchspecificuserlist}  Allproductlist={Allproductlist}/>

			</Drawer>

			<Table pagination={false} columns={columns} dataSource={wdata} onChange={onChange} />
		</React.Fragment>
	);
}

export default Foodwastagetable;
