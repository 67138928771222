import React, { createContext, useState, useEffect } from 'react';
// import { getTypes } from '../handler/LocalDB_handler/TypeServiceHandler/'
import {get_Type_details} from '../handler/api_handler';
export const TypeContext = createContext();

const TypeContextProvider = (props) => {
    const [Type, setType] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);

    useEffect(() => {
        setTypeData();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setTypeData = () => {
        get_Type_details().then((details) => {
           
            // setType(details.length === undefined ?[details] :details);
            setType(details.data);

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <TypeContext.Provider value={{ Type, loading, setTypeData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </TypeContext.Provider>
    );
};

export default TypeContextProvider;