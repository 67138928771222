import React, { Component } from 'react';
import { SearchOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin, Skeleton } from 'antd';
import Highlighter from 'react-highlight-words';
import { SalesproductContext } from '../../context/Salseproductcontext';
// import AddSalesproductmodels from '../Salesproduct/AddSalesproductModel/';
// import {deleteSalesproducts} from '../../handler/LocalDB_handler/SalesproductServiceHandler'
// import { get_product_type } from '../../handler/api_handler';
// import Updatemodelcomponent from '../../components/Salesproduct/UpdateSalesproductModel';
import { ReloadOutlined } from '@ant-design/icons';

class Salesproductcomponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};
	static contextType = SalesproductContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
					>
						Search
					</Button>
					<Button type="danger" onClick={() => this.handleReset(clearFilters)}>
						<ReloadOutlined />
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		// delete_Salesproduct_details(id)
		// 	.then(() => {
		// 		this.context.setSalesproductData();
		// 	})
		// 	.catch(() => {});
	};

	render() {
		const { Salesproduct, loading, setSalesproductData, setVisibles, visible, unit, department } = this.context;
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				// ...this.getColumnSearchProps('name')
			},
			{
				title: 'unit_name',
				dataIndex: 'unit_name',
				key: 'unit_name',
				// ...t,his.getColumnSearchProps('description')
			},
			{
				title: 'department_name',
				dataIndex: 'department_name',
				key: 'department_name',
				// ...this.getColumnSearchProps('description')
			},

			// {
			// 	title: 'Action',
			// 	dataIndex: 'action',
			// 	key: 'id',
			// 	render: (text, record) => (
			// 		<Space>
			// 			{/* <Updatemodelcomponent Salesproduct_data={record} setdata={setSalesproductData} unit={unit} 
			//             department={department}
			//             />  */}
			// 			{/* <Popconfirm
			// 				title="Are you sure？"
			// 				// onConfirm={() => this.handleOk(record.id)}
			// 				okText="Yes"
			// 				cancelText="No"
			// 			>
			// 				<DeleteTwoTone twoToneColor="#d11a2a" style={{ fontSize: '20px' }} />
			// 			</Popconfirm> */}
			// 		</Space>
			// 	)
			// }
		];

		return (
			<React.Fragment>
				<div className="main_top">
					<h2>Sales product</h2>
					<div className="bottom_border"></div>

					<Button
					className="addbtn"
					style={{ marginTop: 0, marginRight: '0' }}
					onClick={this.context.fetch_sync_slick_sales_product}
					>
						<ReloadOutlined /> Fetch Data
					</Button>
				</div>
				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={Salesproduct} pagination={true} bordered style={{ width: '800px' }} />
				</Spin>
			</React.Fragment>
		);
	}
}

export default Salesproductcomponent;
