import React, { Component } from 'react';
import { SearchOutlined, EyeOutlined,EyeInvisibleOutlined, DeleteTwoTone } from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin, Skeleton } from 'antd';
import Highlighter from 'react-highlight-words';
import { ProductpriceContext } from '../../context/Productpricecontext';
import {deleteRoles} from '../../handler/LocalDB_handler/RoleServiceHandler'
import Updatemodel from './UpdatePriceModel';


class Productpricecomponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};
	static contextType = ProductpriceContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [ e.target.value ] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[ this.state.searchText ]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		deleteRoles(id).then(() => {
			this.context.setRoleData();
		}).catch(() => {

		})
	};

	render() {
        const { ProductPrice,loading,setProductPriceData,setVisibles,visible } = this.context;
        console.log('price',ProductPrice)
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				width: '30%',
				...this.getColumnSearchProps('name')
			},
			{
				title: 'MRP',
				dataIndex: 'MRP',
				key: 'MRP',
				width: '20%',
				...this.getColumnSearchProps('MRP')
			},
			{
				title: 'Selling_price',
				dataIndex: 'Selling_price',
				key: 'Selling_price',
				...this.getColumnSearchProps('Selling_price')
            },
            {
				title: 'Buying_price',
				dataIndex: 'Buying_price',
				key: 'Buying_price',
				...this.getColumnSearchProps('Buying_price')
            },
            {
				title: 'status',
				dataIndex: 'status',
				key: 'id',
				render: (text, record) => (
					<Space>
						<Popconfirm
							title="Are you sure to change status？"
							onConfirm={() => this.handleStatus(record.id, record.status)}
							okText="Yes"
							cancelText="No"
						>
							{record.status ? (
								<EyeOutlined style={{ fontSize: '20px', color: 'blue' }} />
							) : (
								<EyeInvisibleOutlined style={{ fontSize: '20px', color: 'red' }} />
							)}
						</Popconfirm>
					</Space>
				)
			},

			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
                        <Updatemodel price_data={record} setdata={setProductPriceData}/>
						{/* <Updatemodelcomponent role_data={record} setdata={setRoleData} /> */}
						<Popconfirm
							title="Are you sure？"
							onConfirm={() => this.handleOk(record.id)}
							okText="Yes"
							cancelText="No"
						>
							<DeleteTwoTone style={{ fontSize: '20px' }} />
						</Popconfirm>
					</Space>
				)
			}
		];
		return (
			<React.Fragment>
				{/* <AddRolemodels  setdata={setRoleData}/> */}
				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={ProductPrice} />
				</Spin>
			</React.Fragment>
		);
	}
}

export default Productpricecomponent;
