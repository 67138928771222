import React, { Component } from 'react';
import { SearchOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin, Skeleton } from 'antd';
import Highlighter from 'react-highlight-words';
import { BulkorderContext } from '../../context/Bulkordercontext';
import AddBulkordermodels from '../Bulkorder/AddBulkorderModel';
// import {deleteBulkorders} from '../../handler/LocalDB_handler/BulkorderServiceHandler'
import { delete_Bulkorder_details } from '../../handler/api_handler';
import Updatemodelcomponent from '../Bulkorder/UpdateBulkorderModel'


class Bulkordercomponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};
	static contextType = BulkorderContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [ e.target.value ] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[ this.state.searchText ]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		delete_Bulkorder_details(id).then(() => {
			this.context.setBulkorderData();
		}).catch(() => {

		})
	};

	render() {
		const { Bulkorder,loading,setBulkorderData,setVisibles,visible } = this.context;
		const columns = [
			{
				title: 'Order id',
				dataIndex: 'order_unique_id',
				key: 'order_unique_id',
				width: '30%',
				...this.getColumnSearchProps('order_unique_id')
			},
			{
				title: 'Customer',
				dataIndex: 'customer',
				key: 'customer',
				width: '30%',
				...this.getColumnSearchProps('customer')
			},
			{
				title: 'Phone',
				dataIndex: 'customer',
				key: 'customer',
				width: '30%',
				...this.getColumnSearchProps('customer')
			},
			
			{
				title: 'Delivery date',
				dataIndex: 'delivery_date',
				key: 'delivery_date',
				width: '20%',
				...this.getColumnSearchProps('delivery_date')
			},
			{
				title: 'Total amount',
				dataIndex: 'grand_total',
				key: 'grand_total',
				...this.getColumnSearchProps('grand_total')
			},

			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<Updatemodelcomponent Bulkorder_data={record} setdata={setBulkorderData} />
						<Popconfirm
							title="Are you sure？"
							onConfirm={() => this.handleOk(record.id)}
							okText="Yes"
							cancelText="No"
						>
							<DeleteTwoTone style={{ fontSize: '20px' }} />
						</Popconfirm>
					</Space>
				)
			}
		];
		return (
			<React.Fragment>
				<AddBulkordermodels  setdata={setBulkorderData}/>
				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={Bulkorder} />
				</Spin>
			</React.Fragment>
		);
	}
}

export default Bulkordercomponent;
