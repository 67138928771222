import React from 'react';
import { Drawer } from 'antd';
import Freebilltable from './Freebilltable';
import { DailysheetContext } from '../../context/Dailysheetcontext.js';

class Ebbill extends React.Component {
	state = { visible: true, childrenDrawer: false };

	static contextType = DailysheetContext;

	showDrawer = () => {
		this.setState({
			visible: false
		});
	};

	onClose = () => {
		this.context.callDailysheetapi();
		this.props.setfreebilldrawerVisible(!this.props.freebilldrawerVisible);
	};

	render() {
		return (
			<React.Fragment>
				<Drawer
					title="Free Bill"
					width={'100%'}
					closable={true}
					onClose={this.onClose}
					visible={this.props.freebilldrawerVisible}
				>
					<div>
						<Freebilltable visible={this.props.freebilldrawerVisible} />
					</div>
					
				</Drawer>
			</React.Fragment>
		);
	}
}

export default Ebbill;
