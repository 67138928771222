import React, { createContext, useState, useEffect } from 'react';
import {get_store_details,get_incentive_master_details,update_incentive_master_details} from '../handler/api_handler';
import { message } from 'antd';

export const IncentiveMasterContext = createContext();

const IncentiveMasterContextProvider = (props) => {

    const [Branch,setBranch] = useState([]);
    const [BranchId,setBranchId] = useState('');
    const [Listing, setListing] = useState([]);

    useEffect(() => {
        setBranchData(); 
    },[]);

    useEffect(() => {
        if(BranchId !== ""){
            setListingData();
        }
    },[BranchId]);

    const setBranchData = () => {
        get_store_details().then((details) => {
            if(details.data.length !== 0){
                setBranchId(details.data[0].id);
                setBranch(details.data);
            }
        }).catch((err) => {

        })
    }

    const setListingData = () => {
        get_incentive_master_details(BranchId).then((response) => {
            setListing(response.data);
        }).catch((error) => {
        })
    }

    const onBranchChange = (ChangedBranchId) => {
        setBranchId(ChangedBranchId);
    }



    const updateIncentivevalue = (roleInd,departmentInd,value) => {
        Listing[roleInd].departments[departmentInd].incentive = value;
        let newListing = [...Listing];
        setListing(newListing);
    }

    const Datatobeupdated = () => {
        let DataArray = [];
        
        Listing.forEach((Listingelement) => { 
                Listingelement.departments.forEach((Departmentelement) => {
                    DataArray.push(Departmentelement);
                });
            });

        return DataArray;
    }

    const updateValue = () => {
        let data = Datatobeupdated();
        update_incentive_master_details(BranchId,data).then(({data}) => {
            message.success('Updated successfully');
        }).catch(({response}) => {
            if(response.status === 400){
                    alert("Enter valid number value");
            }else if(response.status === 500){
                    alert("Server Error");
            }
        });
    };

    return (
        <IncentiveMasterContext.Provider value={ 
            { Listing,
              setListingData,
              updateIncentivevalue,
              updateValue,
              Branch,
              onBranchChange,
              BranchId
            }
        }>
            {props.children}
        </IncentiveMasterContext.Provider>
    );
};

export default IncentiveMasterContextProvider;