import React, { Component } from 'react';
import {InputNumber, Col, Row} from 'antd';
import { IncentiveMasterContext } from '../../context/IncentiveMasterContext';

class Category extends Component {

    static contextType = IncentiveMasterContext;

    ChangeModel = (value) => {
        this.context.updateIncentivevalue(this.props.roleInd, this.props.departmentInd, value);
    }

    render() {
        let { departmentdata } = this.props;

        return (
            <Col span={4} >

                    <h3> {departmentdata.department_name} </h3>

                <InputNumber type="number" min={0} value={departmentdata.incentive} onChange={this.ChangeModel} />
            </Col>
        );
    }
}

export default Category;