import React, { Component } from 'react';
import { SearchOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin, Skeleton } from 'antd';
import Highlighter from 'react-highlight-words';
import { WrongbillContext } from '../../context/Wrongbillcontext';
import AddWrongbillmodels from '../Wrongbill/AddWrongbillModel/';
// import {deleteWrongbills} from '../../handler/LocalDB_handler/WrongbillServiceHandler'
import { delete_Wrongbill_details } from '../../handler/api_handler';
import Updatemodelcomponent from '../../components/Wrongbill/UpdateWrongbillModel'
import moment from 'moment';

class Wrongbillcomponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};
	static contextType = WrongbillContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
					text
				)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		delete_Wrongbill_details(id).then(() => {
			this.context.setWrongbillData();
		}).catch(() => {

		})
	};

	render() {
		const { Wrongbill, loading, setWrongbillData, setVisibles, visible, Orderstatus, User } = this.context;
		console.log("component check", User)
		const columns = [
			{
				title: 'Bill no',
				dataIndex: 'bill_no',
				key: 'bill_no',
				width: '30%',
				...this.getColumnSearchProps('bill_no')
			},
			{
				title: 'Wrong amount',
				dataIndex: 'wrong_amount',
				key: 'amount',
				width: '20%',
				...this.getColumnSearchProps('amount')
			},
			{
				title: 'Correct amount',
				dataIndex: 'correct_amount',
				key: 'amount',
				width: '20%',
				...this.getColumnSearchProps('amount')
			},
			{
				title: 'Billed by',
				dataIndex: 'billed_by_name',
				key: 'billed_by_name',
				...this.getColumnSearchProps('billed_by_name')
			},
			{
				title: 'Date',
				dataIndex: 'date',
				key: 'id',
				render: (text, record) => (
					<p>{moment(record.date).format('DD-MM-YYYY')}</p>
				)
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<Updatemodelcomponent Wrongbill_data={record} User={User} Orderstatus={Orderstatus} setdata={setWrongbillData} />
						<Popconfirm
							title="Are you sure?"
							onConfirm={() => this.handleOk(record.id)}
							okText="Yes"
							cancelText="No"
						>
							<DeleteTwoTone style={{ fontSize: '20px' }} />
						</Popconfirm>
					</Space>
				)
			}
		];
		return (
			<React.Fragment>
				<AddWrongbillmodels setdata={setWrongbillData} User={User} Orderstatus={Orderstatus} />
				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={Wrongbill} />
				</Spin>
			</React.Fragment>
		);
	}
}

export default Wrongbillcomponent;