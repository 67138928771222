import React, { useState, Component } from 'react';
import { Button, Modal, Form, Input, Select } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import { message } from 'antd';
import {update_product_details} from '../../../handler/api_handler'
const { Option } = Select;

class UpdateProductmodel extends Component {
	constructor(props) {
		super(props);
		this.formRef = React.createRef();
	}

	state = {
		visible: false
	};
	onFill = () => {
		// console.log(this.props.product_data);
		const product = this.props.product_data;

		if (this.props.product_data.product_unit.id) product.product_unit = this.props.product_data.product_unit.id;
		if (this.props.product_data.product_type.id) product.product_type = this.props.product_data.product_type.id;
		if (this.props.product_data.category.id) product.category = this.props.product_data.category.id;

	if(typeof(product.category)==="object") product.category ="";
		if (this.formRef.current !== null) {
			this.formRef.current.setFieldsValue(product);
		}
	};

	setVisible() {
		this.setState({ visible: !this.state.visible });
	}

	componentDidUpdate(prevProps, prevState) {
		const Settimeout = this.onFill;
		setTimeout(function() {
			Settimeout();
		}, 100);
	}

	onCreate = (value) => {
		update_product_details(value)
			.then(() => {
				message.success('Updated successfully');
				this.props.setdata();
				this.setVisible();
			})
			.catch(() => {});
	};
	render() {
		return (
			<div>
				<EditTwoTone
					style={{ fontSize: '20px' }}
					type="primary"
					onClick={() => {
						this.setVisible(true);
					}}
				>
					Update Role
				</EditTwoTone>
				<Modal
					title="Edit Product"
					visible={this.state.visible}
					footer={null}
					onCancel={this.setVisible.bind(this)}
				>
					<Form className="edit_form"
						ref={this.formRef}
						{...this.layout}
						name="basic"
						initialValues={{ remember: true }}
						onFinish={this.onCreate}
						layout="horizontal"
						//   onFinishFailed={this.onFinishFailed}
					>
						<Form.Item name="id" style={{ display: 'none' }}>
							{' '}
							<Input type="hidden" />{' '}
						</Form.Item>

						<Form.Item
							name="name"
							label="Name"
							rules={[
								{
									required: true,
									message: 'Please input the name of Product!'
								}
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item name="category" label="Product_category">
							<Select>
								{this.props.category.map((data) => {
									return <Option value={data.id}>{data.name}</Option>;
								})}
							</Select>
						</Form.Item>

						<Form.Item
							name="product_unit"
							label="unit"
							rules={[
								{
									required: true,
									message: 'Please input the unit of Product!'
								}
							]}
						>
							<Select>
								{this.props.unit.map((data) => {
									return <Option value={data.id}>{data.name}</Option>;
								})}
							</Select>
						</Form.Item>
						<Form.Item
							name="product_type"
							label="item_type"
							rules={[
								{
									required: true,
									message: 'Please input the item type of Product!'
								}
							]}
						>
							<Select>
								{this.props.type.map((data) => {
									return <Option value={data.id}>{data.name}</Option>;
								})}
							</Select>
						</Form.Item>

						{/* <Form.Item name="product_quantity" label="product_quantity">
							<Input />
						</Form.Item>
						<Form.Item name="description" label="description">
							<Input />
						</Form.Item> */}
						<Form.Item {...this.tailLayout}>
							<div className="edit_button_block">
								<Button type="primary" htmlType="submit">
									Submit
								</Button>
							</div>

						</Form.Item>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default UpdateProductmodel;
