import React from 'react';
import { withRouter } from "react-router";
import { Table, Input, Button, Space, Spin } from 'antd';
import { SearchOutlined, ReloadOutlined, PlusOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { CustomerListContext } from '../../context/CustomerListContext';
import { delete_store_product_api } from '../../handler/api_handler';


class customerlistcomponent extends React.Component {

    state = {
        searchText: '',
        searchedColumn: ''
    };

    static contextType = CustomerListContext;

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                    >
                        Search
					</Button>
                    <Button type="danger" onClick={() => this.handleReset(clearFilters)}>
                        <ReloadOutlined />
						Reset
					</Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleOk = (id) => {
        const { classification, setListingData } = this.context;

        delete_store_product_api(id)
            .then(() => {
                setListingData(classification);
            })
            .catch(() => { });
    };

    Daterangechange = (dates, dateStrings) => {
        this.context.OndateRangechange(dateStrings[0], dateStrings[1]);
    }

    render() {
        const { Listing, loading, setRoleData, setVisibles, visible, startDate, setstartDate, endDate, setendDate } = this.context;
        const columns = [
            {
                title: 'Customer Name',
                dataIndex: 'name'
            },
            {
                title: 'Phone',
                dataIndex: 'phone'
            },
            {
                title: 'Address',
                dataIndex: 'address'
            }
        ];

        return (
            <React.Fragment>
                <div className="main_top">
                    <h2>Credit Customer List</h2>
                    <Button className="addbtn" style={{ marginTop: 0, marginRight: '0' }}
                        onClick={() => { this.props.history.push('add') }}
                        type="primary"
                    ><PlusOutlined />Add</Button>
                </div>
                <div style={{ padding: '10px' }}>
                    <Spin spinning={loading} delay={500}>
                        <Table
                            columns={columns}
                            dataSource={Listing}
                            pagination={false}
                            bordered
                            style={{ width: '800px' }}
                        />
                    </Spin>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(customerlistcomponent);
