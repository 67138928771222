import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio, Select } from 'antd';
// import {addRoles} from '../../../handler/LocalDB_handler/RoleServiceHandler'
import { create_ebmeter_api } from '../../../handler/api_handler';
import { message } from 'antd';
import { PlusOutlined, } from '@ant-design/icons';

const { Option } = Select;

const Rolemodel = ({ visible, onCreate, subBranch,onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Create a new meter"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form className="edit_form"
        form={form}
        layout="horizontal"
        name="form_in_modal"

      >

        <Form.Item
          name="sub_branch"
          label="sub branch"
          
        >
          <Select >
            {subBranch.map((branch) =>{
              return (
                <option value={branch.id}>{branch.name}</option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="meter"
          label="Meter"
          rules={[
            {
              required: true,
              message: 'Please input the name of Meter!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="description"
        >
          <Input />
        </Form.Item>

      </Form>
    </Modal>
  );
};

const AddRolemodel = (props) => {
  const [visible, setVisible] = useState(false);

  const onCreate = (values) => {
    create_ebmeter_api(props.branchid,values).then(() => {
      message.success("Added successfully");
      props.ebMetered(props.branchid);
    }).catch((err) => {

    });
    setVisible(false);
  };

  return (
    <div className="main_top">

      <Button className="addbtn" style={{ marginTop: 0, marginRight: '0' }}
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      ><PlusOutlined />
       Add Ebmeter
      </Button>
      <Rolemodel
        visible={visible}
        subBranch={props.subBranch}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

export default AddRolemodel;