import React, { useEffect } from 'react';
import { Drawer, Button, Row, Col, Input, InputNumber } from 'antd';
import { isEmptymeanszero } from '../../../src/handler/helpers';

function Pettycash(props) {

    let { data } = props;

    return (
        <React.Fragment>

            <div className="opening_block" style={{ width: '1200px', backgroundColor: '#fff' }}>
                <Row>
                    <Col span={18}> <p>OPENING PETTY CASH</p> </Col>
                    <Col span={4}> <p>{data.opening_cash}</p> </Col>

                </Row>

                <Row style={{ marginTop: 10 }}>
                    <Col span={18}> <p>RECEIVED PETTY CASH</p> </Col>
                    <Col span={4}> <p>{data.recevied_cash}</p> </Col>
                </Row>
            </div>


            <div className="opening_block" style={{ width: '1200px', backgroundColor: '#fff', padding: '0' }}>
                <Row>
                    <Col span={24}>


                        <table className="inventory_table" style={{ width: '1200px', marginTop: '0' }}>
                            <tr style={{ textAlign: 'center' }}>
                                <th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>Remark</th>
                                <th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>Amount</th>
                            </tr>
                            {
                                (data.petty_cash_remark !== undefined) ? data.petty_cash_remark.map((item, index) => {
                                    return (
                                        <tr style={{ textAlign: 'center' }}>
                                            <td>{item.remark}</td>
                                            <td>{item.amount}</td>
                                        </tr>
                                    )
                                }) : ""
                            }
                        </table>


                    </Col>
                </Row>
            </div>

            <div className="opening_block" style={{ width: '1200px', backgroundColor: '#fff' }}>
                <Row>
                    <Col span={18}> <p>TOTAL</p> </Col>
                    <Col span={4}> <p>{data.closing_cash}</p></Col>

                </Row>
            </div>

        </React.Fragment>
    );
}

export default Pettycash;