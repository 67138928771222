import React, { createContext, useState } from 'react';

export const Adminfoodwastagecontext = createContext();

const AdminfoodwastagecontextProvider = (props) => {
    const [Data, setData] = useState("");

    return (
        <Adminfoodwastagecontext.Provider value={{ Data }}>
            {props.children}
        </Adminfoodwastagecontext.Provider>
    );
};

export default AdminfoodwastagecontextProvider;