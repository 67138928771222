import React from 'react';
// import 'antd/lib/style/themes/default.less';
import 'antd/dist/antd.css';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Button } from 'antd';
import routes from "../../router"
import logo from "../../img/logo192.png";
import { clearAuthtoken } from '../../handler/logout_handler';
import { LocalstorageAuth } from '../../handler/helpers';
import {
  DesktopOutlined,
  TeamOutlined,
  LogoutOutlined,
  DashboardFilled,
} from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;

class Darklayout extends React.Component {

  state = {
    collapsed: false,
    username: "",
  };

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  getLocaldata = () => {
    this.setState({ username: LocalstorageAuth().data.full_name });
  }

  componentDidMount() {
    this.getLocaldata();
  }



  render() {

    const { user } = this.props;
    const { SubMenu } = Menu;
    return (

      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <div className="logo"><img src={logo} alt="SIC" /></div>
          <h1 style={{ textAlign: 'center',color: '#fff'}} > {this.state.username} </h1>

          {/* {(user.data.is_incharge && routes.)} */}
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            {routes.map((data) => {
              // alert(data.incharge)
              if (user.data.is_incharge && data.incharge) {

                return (data.showmenucomponent === false) ? <> </> :

                  data.subMenu ? (<SubMenu key={'sub' + data.key} icon={<TeamOutlined />} title={data.title}>

                    {data.submenuOptions.map((option) => {
                      return (option.showmenucomponent === false) ? <> </> : <Menu.Item key={option.key}>
                        <Link onClick={() => { this.props.menuSelector(data.title) }} to={option.path}>{option.title}</Link>
                      </Menu.Item>
                    })}

                  </SubMenu>) : (<Menu.Item key={data.title} icon={<DashboardFilled />}>
                    <Link to={data.path}>{data.title}</Link>
                  </Menu.Item>)
              } else if (data.admin && user.data.is_superuser) {
                return (data.showmenucomponent === false) ? <> </> :

                  data.subMenu ? (<SubMenu key={'sub' + data.key} icon={<TeamOutlined />} title={data.title}>

                    {data.submenuOptions.map((option) => {
                      return (option.showmenucomponent === false) ? <> </> : <Menu.Item key={option.key}>
                        <Link onClick={() => { this.props.menuSelector(data.title) }} to={option.path}>{option.title}</Link>
                      </Menu.Item>
                    })}

                  </SubMenu>) : (<Menu.Item key={data.title} icon={<DashboardFilled />}>
                    <Link to={data.path}>{data.title}</Link>
                  </Menu.Item>)
              }
            })

            }

          </Menu>
          <Button className="logoutbtn" onClick={clearAuthtoken}> <LogoutOutlined /> Logout </Button>

        </Sider>
        <Layout className="site-layout">
          {/* <Header className="site-layout-background" style={{ padding: 0 }} ></Header> */}

          <Content style={{ margin: '20px 16px 0px 16px' }}>

            {/* <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item ><a href="/">Home</a></Breadcrumb.Item>
           <Breadcrumb.Item>{this.props.children.props.history.location.pathname.replace(/\//g, "")}</Breadcrumb.Item>
            </Breadcrumb> */}
            <div className="site-layout-background" style={{ padding: 0, minHeight: 360 }}>
              {this.props.children}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>TSIC ©2020 Created by VGTS</Footer>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(Darklayout);

