import React, { createContext, useState, useEffect } from 'react';
import {get_Freebill_details, getUsers, get_Freebill_customer, get_store_details, get_user_by_store} from '../handler/api_handler';
export const FreebillContext = createContext();

const FreebillContextProvider = (props) => {
    const [User, setUser] = useState([]);
    const [Freebill, setFreebill] = useState([]);
    const [Freebillcustomer, setFreebillcustomer] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [store, setStore] = useState([]);

    useEffect(() => {
        setFreebillData();
        setUserData();
        setFreebillcustomerData();
        setStoreData();
        
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setUserData = () => {
        // alert("check context")
        get_user_by_store().then((details) => {
            setUser(details.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    };

    const setStoreData = () => {
        get_store_details().then((details) => {
            setStore(details.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    };

    const setFreebillcustomerData = () => {
        // alert("check context")
        get_Freebill_customer().then((details) => {
            setFreebillcustomer(details.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    };

    const filterFreebill = (data) => {
        console.log('Context console', data);
        filterFreebill(data).then((details) => {
            console.log('Response data', details);
        }).catch((err) => {
            console.log('Error data', err);
        });
    };
    
    const setFreebillData = () => {
        get_Freebill_details().then((details) => {
           
            // setFreebill(details.length === undefined ?[details] :details);
            setFreebill(details.data);
            console.log(details.data,"to check")

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <FreebillContext.Provider value={{ store, filterFreebill, Freebillcustomer, setFreebillcustomerData, User, setUserData, Freebill, loading, setFreebillData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </FreebillContext.Provider>
    );
};

export default FreebillContextProvider;