import React from "react";

import { Drawer, Button, Input, Table } from "antd";
import { message } from "antd";
class DrawerForm extends React.Component {
  state = [];

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.props.setVisible(!this.props.visible);
  };
  validateFields = () => {
    const validatedData = this.props.drawerList.map((validate) => {
      console.log(validate.is_editable);
      return Boolean(
        parseInt(validate.closing_stock) ||
          parseInt(validate.closing_stock) === 0
      ) && validate.is_editable
        ? ""
        : validate.is_editable
        ? (validate.error = "please fill the data num type only supported")
        : "";
    });
    this.setState(validatedData);
    const data = validatedData.filter((data) => data !== "");
    console.log(data.length);
    return data.length;
  };
  submit = () => {
    if (this.validateFields() == 0) {
      this.props
        .createRawOperationalProduct()
        .then(() => {})
        .catch((err) => {
          message.error("Closing stock greater than stock");
          console.log("eee", err.product_id);
        });
    }
  };

  changeTargets = (index, event) => {
    const updatedArray = [...this.state.input];
    updatedArray[index].closing_stock = event.target.value;
    this.setState(updatedArray);
  };

  columns = [
    {
      title: "product name",
      dataIndex: "name",
    },
    {
      title: "opening stock",
      dataIndex: "opening_stock",
    },
    {
      title: "stock received",
      dataIndex: "received_stock",
    },
    {
      title: "On hand",
      dataIndex: "on_hand",
    },
    {
      title: "unit",
      dataIndex: "unit_name",
    },

    {
      title: "Closed stock",
      dataIndex: "closedstock",
      render: (text, record, index) =>
        this.props.drawerList[index].is_editable ? (
          <React.Fragment>
            <Input
              value={this.props.drawerList[index].closing_stock}
              onChange={this.props.changeTargets.bind(this, index)}
            />

            <span style={{ color: "red" }}>{this.state[index]}</span>
          </React.Fragment>
        ) : (
          <h>{this.props.drawerList[index].closing_stock}</h>
        ),
    },
  ];

  render() {
    return (
      <React.Fragment>
        <Drawer
          title={`Inventory / ${this.props.drawerTitle} `}
          width={"750"}
          error={this.err}
          onClose={this.onClose}
          visible={this.props.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button onClick={this.submit} type="primary">
                {this.props.APImethod}
              </Button>
            </div>
          }
        >
          <Table
            columns={this.columns}
            pagination={false}
            dataSource={this.props.drawerList}
            rowClassName={(record, index) =>
              record.on_hand < this.props.drawerList[index].closing_stock
                ? "table_row_dark"
                : "table_row_light"
            }
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default DrawerForm;
