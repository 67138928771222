import React, { useContext } from 'react';
import { Button } from 'antd';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { DailysheetContext } from '../../context/Dailysheetcontext.js';

const ActionButtonComponent = (props) => {
	let {
		OilconsumptionDrawerVisibile,
		setOilconsumptionDrawerVisibile,
		setdrawerTitle,
		setAPImethod,
		getDrawerList,
		Ebbill,
		bankCashApi,
		drawerdualVisible,
		setdrawerdrawerdualVisible,
		freebilldrawerVisible,
		setfreebilldrawerVisible,
		setEbdrawerVisibile,
		EbdrawerVisibile,
		FoodwastageDrawerVisibile,
		setFoodwastageDrawerVisibile,
		CashhandoverDrawerVisibile, 
        setCashhandoverDrawerVisibile,
		DepartmentsalescountDrawerVisibile,
		setDepartmentsalescountDrawerVisibile,
		CreditsalsedrawerVisible,
		setCreditsalsedrawerVisible,
		CreditStatementdrawerVisible,
		setCreditStatementdrawerVisible,
		BankcashdrawerVisible,
		setBankcashdrawerVisible,
		Denominationopen,
		setDenominationopen,
		updateDenominationopen,
		setupdateDenominationopen,
		setCashmanagementdrawerVisible,
		CashmanagementdrawerVisible,
		getCashmanagement,
		Cashmanagementdata,
		Pettycashopen,
		setPettycashopen,
		updatePettycashopen,
		setupdatePettycashopen
	} = useContext(DailysheetContext);
	let { record } = props;
	// alert(record.id)
	const onClickFunction = () => {
		if (record.id === 2 || record.id === 3 || record.id === 4) {
			setdrawerTitle(record.name);
			getDrawerList(record.id);
			if (record.completed_status) {
				setAPImethod('update');
			} else {
				setAPImethod('create');
			}
		} else if (record.id === 8) {
			setdrawerdrawerdualVisible(!drawerdualVisible);
		} else if (record.id === 6) {
			setOilconsumptionDrawerVisibile(!OilconsumptionDrawerVisibile);
		} else if (record.id === 7) {
			setfreebilldrawerVisible(!freebilldrawerVisible);
		} else if (record.id === 9) {
			if (record.completed_status) {
				setAPImethod('update');
			} else {
				setAPImethod('create');
			}
			Ebbill();
			setEbdrawerVisibile(!EbdrawerVisibile);
		} else if (record.id === 5) {
			setFoodwastageDrawerVisibile(!FoodwastageDrawerVisibile);
		} else if (record.id === 16) {
			setDepartmentsalescountDrawerVisibile(!DepartmentsalescountDrawerVisibile)
		} else if (record.id === 11) {
			setCreditsalsedrawerVisible(!CreditsalsedrawerVisible);
		} else if (record.id === 12) {

			setCreditStatementdrawerVisible(!CreditStatementdrawerVisible);
		}
		else if (record.id === 13) {
			getCashmanagement();
			setCashmanagementdrawerVisible(!CashmanagementdrawerVisible);
		}
		else if (record.id === 15) {
			bankCashApi();
			setBankcashdrawerVisible(!BankcashdrawerVisible);
		}else if (record.id === 17) {
			// bankCashApi();
			// CashhandoverDrawerVisibile, 
			// setCashhandoverDrawerVisibile,
			setCashhandoverDrawerVisibile(!CashhandoverDrawerVisibile);
		} else if (record.id === 14) {
			console.log(record);
			if (record.completed_status === true) {
				setupdateDenominationopen(!updateDenominationopen)
			} else {
				setDenominationopen(!Denominationopen)
			}
		} else if (record.id === 10) {
			if (record.completed_status === true) {
				setupdatePettycashopen(!updatePettycashopen);
			} else {
				setPettycashopen(!Pettycashopen);
			}
		}
	};

	return (
		<Button size="small" onClick={onClickFunction}>
			{record.completed_status ? <EditOutlined /> : <PlusCircleOutlined />}
			{record.completed_status ? 'Edit' : 'Add'}
		</Button>
	);
};

export default ActionButtonComponent;
