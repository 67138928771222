import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import { login_api } from "../handler/api_handler";
import { withRouter } from "react-router-dom";
import { message } from "antd";
import styles from "../styles/main.module.css";
import { CheckOutlined } from "@ant-design/icons";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 8,
  },
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

class LoginComponent extends Component {
  state = {};

  onFinish = (values) => {
    login_api(values)
      .then((response) => {
        console.log("token", response.data.token);
        localStorage.setItem("Auth", JSON.stringify(response));
        localStorage.setItem("Auth-Token", response.data.token);

        if (response.data.is_incharge) {
          window.location.href = "/product_mapping";
        } else {
          window.location.href = "/dashboard";
        }
      })
      .catch((error) => {
        message.error(error.message);
        // console.log('error', error.response.data.message)
      });
  };
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col span={8}>
            <img
              src="filtercoffee.jpg"
              className={styles.loginBanner}
              class="banner"
            />
          </Col>
          <Col span={16} className="login_form">
            <div className="main_block">
              <Form
                layout="vertical"
                {...layout}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                layout="vertical"
                onFinish={this.onFinish}
                onFinishFailed={onFinishFailed}
              >
                <img src="logo.png" className={styles.loginLogo} />

                <Form.Item
                  label="Username"
                  name="user"
                  label="Username"
                  className={styles.loginFields}
                  rules={[
                    {
                      required: true,
                      message: "Please enter username",
                    },
                  ]}
                >
                  <Input placeholder="Username *" />
                </Form.Item>
                {/* <label>Password</label> */}
                <Form.Item
                  label="Password"
                  name="password"
                  label="password"
                  className={styles.loginFields}
                  rules={[
                    {
                      required: true,
                      message: "Please enter password",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password *" />
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  {/* <div> */}
                  <Button type="primary" htmlType="submit">
                    <CheckOutlined /> Submit
                  </Button>
                  {/* </div> */}
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(LoginComponent);
