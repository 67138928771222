import React from 'react';
import { Drawer, Button, Select, Input, Form, DatePicker ,message } from 'antd';
import { DailysheetContext } from '../../context/Dailysheetcontext.js';
import { saveCashmanagement, updateCashmanagement } from '../../handler/api_handler';
import moment from 'moment';
import { FormInstance } from 'antd/lib/form';

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

class CashmanagementDrawer extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = { visible: false, date: null, cashmanagementData: {}, totalCash: 0 };
    // console.log('Props data', this.props.Cashmanagementdata.credit_sales);
  }
  static contextType = DailysheetContext;
  

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.props.setCashmanagementdrawerVisible(!this.props.CashmanagementdrawerVisible);

  };

  componentDidMount() {
    console.log('Props data', this.props.Cashmanagementdata);
    this.setState({ cashmanagementData: this.props.Cashmanagementdata });
    // this.setState({ totalCash: this.props.Cashmanagementdata.total_sales });
    // console.log('Total cash', this.state.totalCash);
    // console.log('State data', this.state.cashmanagementData)
  };

  componentWillReceiveProps(props) {

    var total = parseInt(props.Cashmanagementdata.expenses) + parseInt(props.Cashmanagementdata.incentive) + parseInt(props.Cashmanagementdata.sky_cash) + parseInt(props.Cashmanagementdata.credit_sales) + parseInt(props.Cashmanagementdata.bank_cash);
    this.setState({ totalCash: total });
    this.setState({ cashmanagementData:  props.Cashmanagementdata });
    if (this.formRef.current !== null) {
			this.formRef.current.setFieldsValue(props.Cashmanagementdata);
		}

  }

  render() {
    
    const onFinish = (values: any) => {
      values.total_sales = this.state.totalCash;
      console.log('pps dt', this.props.Cashmanagementdata);
      // if(Object.keys(this.props.Cashmanagementdata).length === 0) {
      if (this.props.Cashmanagementdata.id == null) {
      values.date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

        saveCashmanagement(values).then(() => {
        this.props.setCashmanagementdrawerVisible(!this.props.CashmanagementdrawerVisible);
        this.props.callDailysheetapi();
        }).catch(({ response }) => {
          const { data, status } = response;
          if (status === 400) {
            if (data.hasOwnProperty("incentive")) {
              message.error("Incentive : " + data.incentive[0]);
            } else if (data.hasOwnProperty("sky_cash")) {
              message.error("Sky Cash : " + data.sky_cash[0]);
            } else if (data.hasOwnProperty("credit_sales")) {
              message.error("Credit Sales : " + data.credit_sales[0]);
            } else if (data.hasOwnProperty("bank_cash")) {
              message.error("Bank Cash : " + data.bank_cash[0]);
            }
          }
        });
      } else {
        values.id = this.props.Cashmanagementdata.id;
        updateCashmanagement(values).then(() => {
          this.props.setCashmanagementdrawerVisible(!this.props.CashmanagementdrawerVisible);

        }).catch(({ response }) => {
          const { data, status } = response;
          if (status === 400) {
            if (data.hasOwnProperty("incentive")) {
              message.error("Incentive : " + data.incentive[0]);
            } else if (data.hasOwnProperty("sky_cash")) {
              message.error("Sky Cash : " + data.sky_cash[0]);
            } else if (data.hasOwnProperty("credit_sales")) {
              message.error("Credit Sales : " + data.credit_sales[0]);
            } else if (data.hasOwnProperty("bank_cash")) {
              message.error("Bank Cash : " + data.bank_cash[0]);
            }
          }
        });
      }
    };

    const onFormLayoutChange = (values) => {
      // this.props.Cashmanagementdata.values
      // this.setState({ cashmanagementData: values });
      Object.values(values)[0] == '' ? this.props.Cashmanagementdata[Object.keys(values)[0]] = 0 : this.props.Cashmanagementdata[Object.keys(values)[0]] = Object.values(values)[0];
      console.log(this.props.Cashmanagementdata);

      var total = parseInt(this.props.Cashmanagementdata.expenses) + parseInt(this.props.Cashmanagementdata.incentive) + parseInt(this.props.Cashmanagementdata.sky_cash) + parseInt(this.props.Cashmanagementdata.credit_sales) + parseInt(this.props.Cashmanagementdata.bank_cash);

      console.log('Onchange load data', total);
      this.setState({ totalCash: total });
      console.log(this.state.totalCash);
    };
    
    return (
      <>
        <Drawer
          title="Cash management drawer"
          width={720}
          onClose={this.onClose}
          visible={this.props.CashmanagementdrawerVisible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            {...layout}
            ref={this.formRef}
            initialValues={this.state.cashmanagementData}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onFormLayoutChange}
          >

            <Form.Item
              label="Opening cash"
              name="opening_cash"
              rules={[{ required: true, message: 'Enter opening cash' }]}
            >

              <Input placeholder="Enter opening cash" disabled={true} />
            </Form.Item>

            <Form.Item
              label="Closing cash"
              name="closing_cash"
              rules={[{ required: true, message: 'Enter closing cash' }]}
            >

              <Input placeholder="Enter closing cash" />
            </Form.Item>


            <Form.Item
              label="Expenses"
              name="expenses"
              rules={[{ required: true, message: 'Please input your expenses!' }]}

            >
              <Input placeholder="Enter expenses" />
            </Form.Item>


            <Form.Item
              label="Incentive"
              name="incentive"
              rules={[{ required: true, message: 'Please input the incentive!' }]}
            >
              <Input placeholder="Enter incentive" />
            </Form.Item>


            <Form.Item
              label="Sky cash"
              name="sky_cash"
              rules={[{ required: true, message: 'Please input your skycash!' }]}
            >
              <Input placeholder="Enter sky cash" />
            </Form.Item>


            <Form.Item
              label="Credit sales"
              name="credit_sales"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input placeholder="Enter credit sales" name="credit_sales" />
            </Form.Item>

            <Form.Item
              label="Bank cash"
              name="bank_cash"
              rules={[{ required: true, message: 'Please input the bank cash!' }]}
            >
              <Input placeholder="Enter bank cash" />
            </Form.Item>

            <h3>Total Sales : {this.state.totalCash}</h3>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
        </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    );
  }
}


export default CashmanagementDrawer;