import React, { Component } from 'react';
import { SearchOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin, Skeleton } from 'antd';
import Highlighter from 'react-highlight-words';
import { OperationalproductContext } from '../../context/Operationalproductcontext';
import AddOperationalproductmodels from './AddOperationalproductmodels/';
// import {deleteOperationalproducts} from '../../handler/LocalDB_handler/OperationalproductServiceHandler'
import { update_product_details } from '../../handler/api_handler';
import Updatemodelcomponent from '../../components/Operationalproduct/UpdateOperationalproductModel';
import { ReloadOutlined } from '@ant-design/icons';

class Operationalproductcomponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};
	static contextType = OperationalproductContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
					>
						Search
					</Button>
					<Button type="danger" onClick={() => this.handleReset(clearFilters)}>
						<ReloadOutlined />
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		// delete_Operationalproduct_details(id)
		// 	.then(() => {
		// 		this.context.setOperationalproductData();
		// 	})
		// 	.catch(() => {});
	};

	render() {
		const { Operationalproduct, loading, setOperationalproductData, setVisibles, visible, unit, department } = this.context;
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'unit name',
				dataIndex: 'unit_name',
				key: 'unit_name',
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<Updatemodelcomponent Operationalproduct_data={record} setdata={setOperationalproductData} unit={unit}
							department={department}
						/>
					</Space>
				)
			}
		];
		return (
			<React.Fragment>

				<AddOperationalproductmodels
					unit={unit}
					department={department}
					setdata={setOperationalproductData}
				/>
				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={Operationalproduct} pagination={false} bordered style={{ width: '700px' }} />
				</Spin>
			</React.Fragment>
		);
	}
}

export default Operationalproductcomponent;
