import React, { createContext, useState, useEffect } from 'react';
import { DateFormat } from '../../src/handler/helpers';
import { branch_product_inventory_list_api , Creditsalecustomer } from '../handler/api_handler';
export const CustomerListContext = createContext();


const CustomerListContextProvider = (props) => {

    const [Listing, setListing] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [classification, setClassification] = useState(0);

    
    useEffect(() => {
        setListingData();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }
    const changeClassification = (data) => {
        setClassification(data);
        setListingData(data);
    }

    const setListingData = () => {
        setLoading(true);
        Creditsalecustomer().then(({ data }) => {
            setListing(data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }

  
    

    return (
        <CustomerListContext.Provider value={{
            classification,changeClassification, Listing, loading, setListingData, setVisibles, 
            visible, updateData, setUpdateData 
        }}>
            {props.children}
        </CustomerListContext.Provider>
    );
};

export default CustomerListContextProvider;