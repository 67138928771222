import React, { useState } from 'react';
import { Button, Modal, Form, Input, message, Row, Col } from 'antd';
// import {addCategorys} from '../../../handler/LocalDB_handler/CategoryServiceHandler'
import { post_product_category } from '../../../handler/api_handler';
import {PlusOutlined} from "@ant-design/icons";
// import { message } from 'antd';



const Categorymodel = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Create a new classification"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form className="edit_form" form={form} layout="vertical" name="form_in_modal">
          <Row>
              <Col span={12}>
                  <Form.Item
                      name="name"
                      label="Name"
                      rules={[
                          {
                              required: true,
                              message: 'Please input the name of Category!',
                              whitespace: true,
                          },
                      ]}
                  >
                      <Input />
                  </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item
                      name="description"
                      label="description"
                      rules={[
                          {
                              whitespace: true,
                          }
                      ]}
                  >
                      <Input />
                  </Form.Item>
              </Col>
          </Row>




      </Form>
    </Modal>
  );
};

const AddCategorymodel = (props) => {
  const [visible, setVisible] = useState(false);

  const onCreate = (values) => {
    post_product_category(values).then(() => {
      message.success("Added successfully");
      props.setdata();
      setVisible(false);
    }).catch((err) => {
      message.error(err.message);
    });

  };

  return (

    <div className="main_top">
      <h2>Classification List</h2>
        <div className="bottom_border"></div>


      {/* <Button className="addbtn" style={{ marginTop: 0, marginRight: '0' }}
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      >
          <PlusOutlined />
        Add classification
      </Button> */}
      <Categorymodel
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

export default AddCategorymodel;