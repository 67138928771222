import React, { useState, useContext } from 'react';
import { Form, Modal, Button, Input, message, Row, Col } from 'antd';
import { Creditsalecustomerupdate } from '../../../handler/api_handler';
import { Creditcustomercontext } from '../../../context/Creditcustomercontext';
import { CheckCircleOutlined, EditTwoTone } from '@ant-design/icons';

const Updatecreditcustomer = (props) => {
	const CustomerDetail = useContext(Creditcustomercontext);
	const [form] = Form.useForm();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [Property, setProperty] = useState(props);


	const onFinish = (fieldsValue) => {
		const FormData = { id: Property.record.id,name : fieldsValue.name, phone : fieldsValue.phone, address : fieldsValue.address };
		Creditsalecustomerupdate(FormData).then(() => {
			message.success("Updated successfully");
			CustomerDetail.getList();
			handleCancel();
		}).catch(() => { });
	};

	const showModal = () => {
		setIsModalVisible(true);
		onFill(props.record);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onFill = (data) => {
		form.setFieldsValue({ name : data.name, phone : data.phone, address : data.address });
	};

	return (
		<React.Fragment>
			<EditTwoTone twoToneColor="#FFA500" style={{ fontSize: '20px' }} type="primary" onClick={showModal}></EditTwoTone>
			<Modal
				title="Update credit customer"
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>

				<Form form={form} name="time_related_controls" onFinish={onFinish} className="edit_form" layout="vertical">

					<Row style={{ marginBottom: '0' }}>
						<Col span={12}>
							<Form.Item
								label="Customer"
								name="name"
								rules={[
									{
										required: true,
										message: 'Please input the customer name!',
										whitespace: true,
									}
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Phone"
								name="phone"
								rules={[
									{
										required: true,
										message: 'Please input the phone number!',
										whitespace: true,
									}
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row style={{ marginBottom: '0' }}>
						<Col span={12}>
							<Form.Item
								name="address"
								label="Address"
								rules={[
									{
										required: true,
										message: 'Please input the address!',
										whitespace: true,
									}
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Form.Item>
						<div className="edit_button_block">
							<Button type="primary" htmlType="submit">
								<CheckCircleOutlined /> Update
							</Button>
						</div>
					</Form.Item>

				</Form>
			</Modal>


		</ React.Fragment>


	);
};

export default Updatecreditcustomer;