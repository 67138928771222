import React, { Component } from 'react';
import { Button, Input, Row, Col } from 'antd';
import { SalarylistContext } from '../../context/SalarylistContext';

class SaleryInputComponent extends Component {

    constructor(props) {
        super(props);
        this.Onchange = this.Onchange.bind(this);
    }

    static contextType = SalarylistContext;

    state = {
        per_hour: this.props.record.per_hour,
        showsave: false,
    }

    Onchange = (e) => {
        this.setState({ per_hour: e.target.value, showsave: true });
    }

    SubmitValue = () => {
        let FormData = { user: this.props.record.id, per_hour: this.state.per_hour, work_hours: 1, ot_per_hour: 2 };

        console.log("this.props.record", this.props.record);
        // CreateSalaryDetail()
    }

    render() {

        const { CreateSalaryDetail } = this.context;

        return (
            <React.Fragment>
                <Row>
                    <Col span={6}>
                        <Input value={this.state.per_hour} onChange={this.Onchange} />
                    </Col>
                    <Col span={18}>
                        {(this.state.showsave) ? <Button onClick={this.SubmitValue}> Save </Button> : ""}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default SaleryInputComponent;