import React, { useState, useEffect } from 'react';
import { Drawer, InputNumber, Button, message } from 'antd';
import { validNumber, validInteger } from '../../handler/helpers.js';
import Denominationtableview from './Denominationtableview';
import { get_denomination_list, patchupdate_denomination } from "../../../src/handler/api_handler";
import moment from 'moment';


function Denominationupdatedrawercomponent(props) {

    const Initialdenomination = [
        {
            "id": "",
            "amount": 2000,
            "quantity": 0
        },
        {
            "id": "",
            "amount": 500,
            "quantity": 0
        },
        {
            "id": "",
            "amount": 200,
            "quantity": 0
        },
        {
            "id": "",
            "amount": 100,
            "quantity": 0
        },
        {
            "id": "",
            "amount": 50,
            "quantity": 0
        },
        {
            "id": "",
            "amount": 20,
            "quantity": 0
        },
        {
            "id": "",
            "amount": 10,
            "quantity": 0
        },
        {
            "id": "",
            "amount": 5,
            "quantity": 0
        },
        {
            "id": "",
            "amount": 2,
            "quantity": 0
        },
        {
            "id": "",
            "amount": 1,
            "quantity": 0
        }
    ];

    const [denominationList, setdenominationList] = useState(Initialdenomination);

    const [Total, setTotal] = useState(0);

    const onQuantitychange = (value, index) => {
        if (validNumber(value)) {
            let NewdenominationList = [...denominationList];
            NewdenominationList[index].quantity = value;
            setdenominationList(NewdenominationList);
            calculateTotal();
        }
    }

    const calculateTotal = () => {
        let Sum = 0;
        denominationList.forEach((item) => {
            Sum = Sum + (Number(item.quantity) * Number(item.amount));
        });
        setTotal(Sum);
    }

    const onDrawerclose = () => {
        props.setupdateDenominationopen(false);
    }

    const onSubmit = () => {

        let isSubmit = true;

        denominationList.forEach((list) => {
            if (!validInteger(list.quantity)) {
                isSubmit = false;
                message.warning(`${list.quantity} - Please fill denomination field with valid number field`);
                return false;
            }
        });

        if (isSubmit) {
            patchupdate_denomination(denominationList).then(({ data }) => {
                onDrawerclose();
                props.callDailysheetapi();
            }).catch(() => {

            });
        }
    }

    const alterDenominationvalue = (data) => {
        let newInitialdenomination = [...Initialdenomination];

        newInitialdenomination.map((item, index) => {
            if (data.data[index] !== undefined) {
                item.id = data.data[index].id;
                item.quantity = data.data[index].quantity;
            }

            return item;
        });

        console.log("newInitialdenomination", newInitialdenomination);

        setdenominationList(newInitialdenomination);
        setTotal(data.total);
    }

    useEffect(() => {
        if (props.updateDenominationopen) {
            get_denomination_list(moment(new Date()).format("YYYY-MM-DD")).then(({ data }) => {
                alterDenominationvalue(data);
            }).catch((err) => {

            });
        }
    }, [props.updateDenominationopen]);


    return (
        <React.Fragment>
            <Drawer
                title="Denomination"
                width='400'
                closable={true}
                onClose={onDrawerclose}
                visible={props.updateDenominationopen}
                footer = {
                    <div style={{ textAlign: 'right' }} >
                        <Button style={{ marginRight: 8 }}>
                            Cancel
                      </Button>
                        <Button onClick={onSubmit}>
                            Submit
                      </Button>
                    </div>
                }
            >

                <Denominationtableview
                    denominationList={denominationList}
                    Total={Total}
                    onQuantitychange={onQuantitychange}
                />
                
            </Drawer>
        </React.Fragment>
    );
}

export default Denominationupdatedrawercomponent;