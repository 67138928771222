import React from 'react';
import { Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';

function Exceldownloadbutton(props) {

    const onBtnclick = () => {
        props.clickEvent();
    }

    return (
        <React.Fragment>
            <Button onClick={onBtnclick}>
                <FileExcelOutlined /> to excel
            </Button>
        </React.Fragment>
    );
}

export default Exceldownloadbutton;