
import React, { useState, useContext } from 'react';
import { Button, Modal, Form, Input, Radio, Row, Col } from 'antd';
import { post_branch_details } from '../../../handler/api_handler';
import { message } from 'antd';

import { PlusOutlined } from "@ant-design/icons";
import { Creditcustomercontext } from '../../../context/Creditcustomercontext';
import { Creditsalecustomercreate } from '../../../handler/api_handler';

const Creditcustomer = ({ visible, onCreate, onCancel }) => {
	const [form] = Form.useForm();
	return (
		<Modal
			visible={visible}
			title="Customer creation form"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form className="edit_form" form={form} layout="vertical" name="form_in_modal">

				<Row style={{ marginBottom: '0' }}>
					<Col span={12}>
						<Form.Item
							label="Customer"
							name="name"
							rules={[
								{
									required: true,
									message: 'Please input the customer name!',
									whitespace: true,
								}
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Phone"
							name="phone"
							rules={[
								{
									required: true,
									message: 'Please input the phone number!',
									whitespace: true,
								}
							]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row style={{ marginBottom: '0' }}>
					<Col span={12}>
						<Form.Item
							name="address"
							label="Address"
							rules={[
								{
									required: true,
									message: 'Please input the address!',
									whitespace: true,
								}
							]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>

			</Form>
		</Modal>
	);
};

const AddCreditcustomer = (props) => {

	const CustomerDetail = useContext(Creditcustomercontext);

	const [visible, setVisible] = useState(false);

	const onCreate = (values) => {
		values.payment_type = 1;
		Creditsalecustomercreate(values)
			.then(() => {
				message.success('Added successfully');
				CustomerDetail.getList();
				setVisible(false);
			})
			.catch((err) => { });
	};

	return (
		<div className="main_top">
			<h2>Credit Customer</h2>
			<div className="bottom_border"></div>
			<Button className="addbtn" style={{ marginTop: 0, marginRight: '0' }}
				type="primary"
				onClick={() => {
					setVisible(true);
				}}
			><PlusOutlined />
				Add customer
			</Button>

			<Creditcustomer
				visible={visible}
				onCreate={onCreate}
				onCancel={() => {
					setVisible(false);
				}}
			/>
		</div>
	);
};

export default AddCreditcustomer;