import React, { useState, Component } from 'react';
import { Button, Modal, Form, Input, Radio, DatePicker, Select, Switch, Drawer, InputNumber, Row, Col } from 'antd';
import {EditTwoTone, CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import { update_user_details, get_store_details, get_role_details } from '../../../handler/api_handler';
import { message } from 'antd';
import moment from 'moment';
import Officeuserscontext from '../../../context/Officeuserscontext';

const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';


class UpdateOfficeusersModel extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }
    static contextType = Officeuserscontext;
    state = {
        visible: false,
        userstatus: '',
        role: [],
        store: [],
        children: '',
        userdata: []
    }

    onFill = () => {
        delete this.props.user_data.updated
        delete this.props.user_data.created
        console.log('Initial user data', this.props.user_data);
        this.state.userdata.date = moment(this.state.userdata.date);

        if (typeof this.state.userdata.date_of_resignation === 'object') {
            this.state.userdata.date_of_resignation = moment(this.state.userdata.date_of_joining);
        } else {
            this.state.userdata.date_of_resignation = moment(this.state.userdata.date_of_resignation);
        }

        if (typeof this.state.userdata.date_of_joining === 'object') {
            this.state.userdata.date_of_joining = moment(this.state.userdata.date_of_joining);
        } else {
            this.state.userdata.date_of_joining = moment(this.state.userdata.date_of_joining);
        }
        console.log('User resignation data', this.state.userdata);

        if (this.formRef.current !== null) {
            this.formRef.current.setFieldsValue(this.state.userdata);
        }

    };

    setVisible() {
        this.setState({ visible: !this.state.visible })
    }

    componentWillMount() {
        get_role_details().then((details) => {
            this.setState({ role: details.data });
            let children = [];
            for (let i = 0; i < this.state.role.length; i++) {
                children.push(<Option key={this.state.role[i]['id']} value={this.state.role[i]['id']}>{this.state.role[i]['name']}</Option>);
            }
            this.setState({ children: children });
        }).catch((err) => {
            console.log(err);
        })

        get_store_details().then((details) => {
            this.setState({ store: details.data });
        }).catch((err) => {

        });
    }

    componentDidUpdate(prevProps, prevState) {
        const Settimeout = this.onFill;
        setTimeout(function () { Settimeout() }, 100);
    }

    componentDidMount() {
        this.setState({ userdata: this.props.user_data });
    }

    onCreate = (value) => {
        console.log('Value data', value);
        update_user_details(value).then((
        ) => {
            message.success("Updated successfully");
            this.props.setUserData();
            this.setVisible();
        }).catch(() => { });
    }

    render() {
        const { TextArea } = Input;

        return (
            <div>
                <EditTwoTone twoToneColor="#FFA500"
                    style={{ fontSize: '20px' }}
                    type="primary"
                    onClick={() => {
                        this.setVisible(true);
                    }}
                >
                    Update Users
      </EditTwoTone>
                <Drawer
                    width={720}
                    title="Edit User"
                    placement="right"
                    closable={false}
                    onClose={this.setVisible.bind(this)}
                    visible={this.state.visible}
                    // footer={
                    //     <div
                    //         style={{
                    //             textAlign: 'right',
                    //         }}
                    //     >
                    //         <Button type="primary" htmlType="submit">
                    //             <CheckCircleOutlined /> Submit
                    //         </Button>
                    //     </div>
                    // }
                >
                    <Form ref={this.formRef} className="edit_form" layout="vertical"

                        {...this.layout}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={this.onCreate}
                    >
                    <Form.Item name="id" style={{ display: "none" }} > <Input type="hidden" /> </Form.Item>



                        <Row style={{marginBottom: '0'}}>
                            <Col span={12}>
                                <Form.Item
                                    name="first_name"
                                    label="Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the name of staff!',
                                            whitespace: true,
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="email"
                                    label="Email Address"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter the mail address of staff!',
                                            whitespace: true,
                                            type: "email",
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{marginBottom: '0'}}>
                            <Col span={12}>
                                <Form.Item
                                    name="phone"
                                    label="Phone Number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the mobile number!',
                                            whitespace: true,
                                        }
                                    ]}
                                >
                                    <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="address"
                                    label="Address"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter the mobile number!',
                                            whitespace: true,
                                        }
                                    ]}
                                >
                                    <TextArea />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{marginBottom: '0'}}>
                            <Col span={12}>
                                <Form.Item
                                    name="date_of_joining"
                                    label="DOJ"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the DOJ of User!',
                                        },
                                    ]}
                                >
                                    <DatePicker format={dateFormat} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="aadhaar_number"
                                    label="Aadhaar Number"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter the aadhaar number!',
                                            whitespace: true,
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{marginBottom: '0'}}>
                            <Col span={12}>
                                <Form.Item
                                    name="user_role"
                                    label="Is super user"
                                >
                                    <Radio.Group>
                                        <Radio value={0}>Is super admin</Radio>
                                        <Radio value={1}>Is admin</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="is_active"
                                    label="Status"
                                >
                                    <Switch defaultChecked />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button type="primary" htmlType="submit">
                                <CheckCircleOutlined /> Submit
                            </Button>

                    </Form>
                </Drawer>

            </div>
        );
    }
}

export default UpdateOfficeusersModel;