import React, { createContext, useState, useEffect } from 'react';
// import { getUsers } from '../handler/LocalDB_handler/UserServiceHandler/'
import { getUsers, getalladminUsers, get_role_details, get_store_details } from '../handler/api_handler';
export const Officeuserscontext = createContext();

const OfficeuserscontextProvider = (props) => {
    const [User, setUser] = useState([]);
    const [Role, setRole] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [Store, setStore] = useState("");
    const [Branch, setBranch] = useState([]);
    const [BranchId, setBranchId] = useState('');




    useEffect(() => {
        setRoleData();
        setStoreData();
        setBranchData();
    }, [])

    useEffect(() => {
        if (BranchId !== "") {
            setUserData();
        }
    }, [BranchId]);


    const setBranchData = () => {
        get_store_details().then((details) => {
            if (details.data.length !== 0) {
                setBranchId(details.data[0].id);
                setBranch(details.data);
            }
        }).catch((err) => {

        })
    }

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setUserData = () => {
        getalladminUsers(0).then((details) => {
            setUser(details.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    };

    const setRoleData = () => {
        get_role_details().then((details) => {
            console.log('Roles data', details.data);
            setRole(details.data);
            // console.log('Roles', )
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    };

    const setStoreData = () => {
        get_store_details().then((details) => {
            setStore(details.data);
            setLoading(false);
        }).catch((err) => {

        });
    }

    // const getusersbyid = () => {
    //     getUsersbyId().then((details) => {
    //         console.log(details);
    //     }).catch((err) => {
    //         console.log(err);
    //     })
    // }

    const onBranchChange = (ChangedBranchId) => {
        setBranchId(ChangedBranchId);
    }


    return ( <
        Officeuserscontext.Provider value = {
            {
                User,
                setUserData,
                Role,
                loading,
                setVisibles,
                visible,
                updateData,
                setUpdateData,
                setStoreData,
                Store,
                Branch,
                onBranchChange,
                BranchId
            }
        } > { props.children } <
        /Officeuserscontext.Provider>
    );
};

export default OfficeuserscontextProvider;