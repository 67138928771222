import React, { useState, useEffect } from 'react';
import {Drawer, Table, Button } from 'antd';
import { get_oil_consumption_list_api } from '../../handler/api_handler';
import Oilconsumptionupdateform from './Oilconsumptionupdateform';
import Oilconsumptioncreateform from './Oilconsumptioncreateform';

import moment from 'moment';


function onChange(pagination, filters, sorter, extra) {
	console.log('params', pagination, filters, sorter, extra);
}



function Oilconsumptiontable(props) {

	

	const [wdata, setdata] = useState([]);

	const [childrenDrawer, setchildrenDrawer] = useState(false);


	const showChildrenDrawer = () => {
		setchildrenDrawer(true);
	};

	const onChildrenDrawerClose = () => {
		setchildrenDrawer(false);
	}

	const callTableapi = () => {

		const date = moment(new Date()).format('YYYY-MM-DD');
		get_oil_consumption_list_api(date)
			.then((response) => {
				setdata(response.data);
			})
			.catch((err) => { });

	}

	

	const columns = [
		{
			title: 'Item',
			dataIndex: 'item',
			width: '10%',
			render: (text, record) => (
				 <React.Fragment> { (record.item === 1) ? "Fryer" : "Kadai"} </React.Fragment>
			)
		},
		{
			title: 'Fresh Oil',
			dataIndex: 'fresh_oil',
			width: '10%',
			render: (text, record) => ( <React.Fragment> { record.fresh_oil+" ltr"} </React.Fragment> )
		},
		{
			title: 'Used Oil',
			dataIndex: 'used_oil',
			width: '10%',
			render: (text, record) => ( <React.Fragment> { record.used_oil+" ltr"} </React.Fragment> )
		},
		{
			title: 'Wastage Oil',
			dataIndex: 'wastage_oil',
			width: '10%',
			render: (text, record) => ( <React.Fragment> { record.wastage_oil+" ltr"} </React.Fragment> )
		},

		{
			title: 'Action',
			dataIndex: 'Action',
			render: (text, record) => (<Oilconsumptionupdateform record={record} callTableapi={callTableapi} />)
		}
	];

	useEffect(() => {
		if (props.visible) {
			callTableapi();
		}
	}, [props.visible]);

	return (
		<React.Fragment>

			<Button type="primary" style={{ "margin": "15px", float: 'right' }} onClick={showChildrenDrawer}>
				Add
			</Button>

			<Drawer
				title="Oil Consumption"
				width={400}
				closable={true}
				onClose={onChildrenDrawerClose}
				visible={childrenDrawer}
			>

			<Oilconsumptioncreateform callTableapi={callTableapi} onChildrenDrawerClose={onChildrenDrawerClose}/>
			</Drawer>

			<Table pagination={false} columns={columns} dataSource={wdata} onChange={onChange} />
		</React.Fragment>
	);
}

export default Oilconsumptiontable;
