import React, { useState, useEffect } from 'react';
import { Drawer, Button, Row, Col, Input, InputNumber } from 'antd';
import { validNumber, notEmpty } from '../../handler/helpers.js';
import { DeleteTwoTone, PlusCircleOutlined } from '@ant-design/icons';
import { petty_cash_list_api, opening_petty_cash_api, patchupdate_denomination, save_petty_cash_api } from "../../../src/handler/api_handler";
import { message } from 'antd';
import { useHistory } from "react-router-dom";
import moment from 'moment';

function Pettycashdetailsdrawercomponent(props) {

    let history = useHistory();

    const [openingpettycash, setopeningpettycash] = useState(0);
    const [receivedpettycash, setreceivedpettycash] = useState(0);
    const [Currentdate, setCurrentdate] = useState(moment().format('YYYY-MM-DD').toString());
    const Initialpettycashobj = { "id": null, "remark": "", "amount": 0 };
    const Initialpettycash = [Initialpettycashobj];
    const [pettycashList, setpettycashList] = useState(Initialpettycash);
    const [Total, setTotal] = useState(0);


    const onReceivedpettycashchange = (value) => {
        if (validNumber(value)) {
            setreceivedpettycash(value);
            calculateTotal(value, pettycashList);
        }
    }

    const calculateTotal = (receivedpettycashParams, pettycashListParams) => {
        let arraySum = 0;
        pettycashListParams.forEach((item) => {
            arraySum = arraySum + Number(item.amount);
        });
        let Totalvalue = (Number(openingpettycash) + Number(receivedpettycashParams)) - Number(arraySum);
        setTotal(Totalvalue);
    }

    const onDrawerclose = () => {
        props.setPettycashopen(false);
    }

    const Addrow = () => {
        pettycashList.push(Initialpettycashobj);
        setpettycashList([...pettycashList]);
    }

    const removeStatearray = (index, id) => {
        let Listingdata = [...pettycashList];
        Listingdata.splice(index, 1);
        setpettycashList([...Listingdata]);
        calculateTotal(receivedpettycash, Listingdata);
    }

    const onDeleteclick = (index, id) => {
        removeStatearray(index, id)
    }


    const onSubmit = () => {
        let isSubmit = true;

        if (Math.sign(Total) === -1) {
            isSubmit = false;
            message.error("Total value should be positive");
            return false;
        }

        pettycashList.forEach((list) => {
            if (!notEmpty(list.remark) || !validNumber(list.amount)) {
                isSubmit = false;
                return false;
            }
        });

        if (isSubmit) {
            let SubmitData = pettycashList.map((item) => { item.date = Currentdate + 'T00:00:00'; return item });

            let SendData = {
                recevied_cash: receivedpettycash,
                date: Currentdate + 'T00:00:00',
                petty_cash_remark_data: SubmitData
            };

            save_petty_cash_api(SendData).then(({ data }) => {
                onDrawerclose();
                props.callDailysheetapi();
            }).catch(({ response }) => {
                const { data, status } = response;
                if (status === 400) {
                    if (data.hasOwnProperty("recevied_cash")) {
                        message.error("Recevied Cash : " + data.recevied_cash[0]);
                    } else if (data.hasOwnProperty("petty_cash_remark_data")) {
                        message.error("Petty cash remark : Ensure that there are no more than 10 digits in total");
                    }
                }
            });
        } else {
            message.warning('Please fill all fields with valid data');
        }
    }

    const onRemarkchange = (index, value) => {
        pettycashList[index].remark = value;
        setpettycashList([...pettycashList]);
    }

    const onAmtchange = (index, value) => {
        pettycashList[index].amount = value;
        setpettycashList([...pettycashList]);
        calculateTotal(receivedpettycash, pettycashList);
    }


    useEffect(() => {
        if (props.Pettycashopen) {
            opening_petty_cash_api(moment(new Date()).format("YYYY-MM-DD")).then(({ data }) => {
                setopeningpettycash(data.closing_cash);
            }).catch(err => { });
        }
    }, [props.Pettycashopen]);


    return (
        <React.Fragment>
            <Drawer
                title="Pettycash Create"
                width='600'
                closable={true}
                onClose={onDrawerclose}
                visible={props.Pettycashopen}
            >

                <div className="opening_block">
                    <Row>
                        <Col span={18}> <b>OPENING PETTY CASH</b> </Col>
                        <Col span={4}> {openingpettycash} </Col>
                        <Col span={2}></Col>
                    </Row>

                    <Row style={{ marginTop: 10 }}>
                        <Col span={18}> <b>RECEIVED PETTY CASH</b> </Col>
                        <Col span={4}> <InputNumber value={receivedpettycash} onChange={(value) => { onReceivedpettycashchange(value) }} /> </Col>
                        <Col span={2}></Col>
                    </Row>
                </div>

                <div className="opening_block">
                    <Row style={{ marginTop: 10 }}>
                        <Col span={24}>

                            <div>
                                <div className="edit_button_block">
                                    <Button onClick={Addrow}><PlusCircleOutlined /> Add Row </Button>
                                </div>
                            </div>

                            <table className="inventory_table">
                                <tr style={{ textAlign: 'center' }}>
                                    <th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>Remark</th>
                                    <th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>Amount</th>
                                    <th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>Action</th>
                                </tr>
                                {
                                    pettycashList.map((item, index) => {
                                        return (
                                            <tr style={{ textAlign: 'center' }}>
                                                <td><Input onChange={(event) => { onRemarkchange(index, event.target.value) }} value={item.remark} /></td>
                                                <td><InputNumber onChange={(value) => { onAmtchange(index, value) }} value={item.amount} /></td>
                                                <td><DeleteTwoTone twoToneColor="#d11a2a" onClick={() => { onDeleteclick(index, item.id) }} style={{ cursor: 'pointer', color: 'red', fontSize: '20px' }} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </table>


                        </Col>
                    </Row>
                </div>

                <div className="opening_block">
                    <Row style={{ marginTop: 10 }}>
                        <Col span={18}> <b>TOTAL</b> </Col>
                        <Col span={4}> {Total}</Col>
                        <Col span={2}></Col>
                    </Row>
                </div>

                <div className="denomination_button">
                    <Button onClick={onSubmit} style={{ marginTop: 20 }}> Submit </Button>
                </div>

            </Drawer>
        </React.Fragment>
    );
}

export default Pettycashdetailsdrawercomponent;