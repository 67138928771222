export function Format24hrs(time) {
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes + ":00";
}

export function validNumber(input){
    return (input !== "" && input !== undefined && input !== null) && !isNaN(input) ? true : false;
}

export function validInteger(input){
    return Number.isInteger(input) ? true : false;
}

export function notEmpty(input){
    return (input !== "" && input !== undefined && input !== null) ? true : false;
}

export function DateFormat(DateObj){
    var dd = String(DateObj.getDate()).padStart(2, '0');
    var mm = String(DateObj.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = DateObj.getFullYear();
    return yyyy+'-'+mm+'-'+dd;
}

export function ReplaceUnderscore(InputValue){
   return InputValue.replace("_", " ");
}

export function LocalstorageAuth(){
    let AuthString = localStorage.getItem("Auth");
    let Authobj = JSON.parse(AuthString);
    return Authobj;
}

export function isEmptyobj(objectvalue){
    return (objectvalue.constructor === Object && Object.keys(objectvalue).length === 0) ? true : false;
}

export function isEmptymeanszero(value){
    return notEmpty(value) ? value : 0
}