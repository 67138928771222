import db from '../DBSetup';
import uuid from 'react-uuid';
import moment from 'moment';

export async function getAttendanceDetails() {
  return await db.collection('att').get().then(role => {
    console.log(JSON.stringify(role))
    return role;
  })
}


// export async function AbscentUserDetails(data) {

// console.log(data,"abscent");
// // return await db.collection('att').doc({ id: data.id }).update(data)

// }

export async function checkInAttendanceDetails(data) {
  console.log("check_in", data.check_in, 'break_in', data.break_in, 'break_out', data.break_out, 'check_out', data.check_out)
  console.log('user_attendance', data.user_attendance)
  data.check_in = false;
  data.break_in = true;
  data.break_out = false;
  data.check_out = true;
  data.user_attendance.push({ id: uuid(), existing: false, start: moment(new Date()).format('HH:mm:ss'),date: moment(new Date()).format('YYYY-MM-DD')})
  console.log("check_in", data.check_in, 'break_in', data.break_in, 'break_out', data.break_out, 'check_out', data.check_out)
  console.log('user_attendance', data.user_attendance)
  return await db.collection('att').doc({ id: data.id }).update(data);
}

export async function AbscentAttendanceDetails(data) {
  console.log("check_in", data.check_in, 'break_in', data.break_in, 'break_out', data.break_out, 'check_out', data.check_out)
  console.log('user_attendance', data.user_attendance)
  // data.check_in  =  false;
  // data.break_in  =  false;
  // data.break_out = false;
  // data.check_out = false;
  data.abscent   = true;

  console.log(data,"ddddddd")
  data.user_attendance.push({ id: uuid(), existing: false, abscent: true,date: moment(new Date()).format('YYYY-MM-DD')})
  console.log("check_in", data.check_in, 'break_in', data.break_in, 'break_out', data.break_out, 'check_out', data.check_out)
  console.log('user_attendance', data.user_attendance)
  return await db.collection('att').doc({ id: data.id }).update(data);
}

export async function checkOutAttendanceDetails(data) {
  console.log("check_in", data.check_in, 'break_in', data.break_in, 'break_out', data.break_out, 'check_out', data.check_out);
  console.log('user_attendance', data.user_attendance);
  data.check_in = true;
  data.break_in = false;
  data.break_out = false;
  data.check_out = false;
  const date = moment(new Date()).format('HH:mm:ss');
  if(data.user_attendance[data.user_attendance.length - 1].existing){
    data.user_attendance[data.user_attendance.length - 1].update = true;
  }
  // console.log({"break length":data.break_time.length,"start":data.break_time[data.break_time.length - 1].start,"stop":data.break_time[data.break_time.length - 1].stop  })
  if (data.break_time.length > 0 && data.break_time[data.break_time.length - 1].start !== '' && data.break_time[data.break_time.length - 1].stop === undefined ) {
    data.break_time[data.break_time.length - 1].stop = date;
  }
  data.user_attendance[data.user_attendance.length - 1].stop = date;
  console.log("check_in", data.check_in, 'break_in', data.break_in, 'break_out', data.break_out, 'check_out', data.check_out)
  console.log('user_attendance', data.user_attendance)
  // alert("iuiut")
  return await db.collection('att').doc({ id: data.id }).update(data);
}


export async function breakInAttendanceDetail(data) {
  console.log("check_in", data.check_in, 'break_in', data.break_in, 'break_out', data.break_out, 'check_out', data.check_out);
  console.log('break_time', data.break_time);
  data.check_in = false;
  data.break_in = false;
  data.break_out = true;
  data.check_out = true;
  data.break_time.push({ id: uuid(), existing: false, start: moment(new Date()).format('HH:mm:ss'), date: moment(new Date()).format('YYYY-MM-DD') });
  console.log("check_in", data.check_in, 'break_in', data.break_in, 'break_out', data.break_out, 'check_out', data.check_out)
  console.log('break_time', data.break_time);
  return await db.collection('att').doc({ id: data.id }).update(data);
}

export async function breakOutAttenanceDetail(data) {
  console.log("check_in", data.check_in, 'break_in', data.break_in, 'break_out', data.break_out, 'check_out', data.check_out);
  console.log('break_time', data.break_time);
  data.check_in = false;
  data.break_in = true;
  data.break_out = false;
  data.check_out = true;
  if(data.break_time[data.break_time.length - 1].existing){
    data.break_time[data.break_time.length - 1].update = true;
  }
  data.break_time[data.break_time.length - 1].stop = moment(new Date()).format('HH:mm:ss');
  console.log("check_in", data.check_in, 'break_in', data.break_in, 'break_out', data.break_out, 'check_out', data.check_out)
  console.log('break_time', data.break_time);
  return await db.collection('att').doc({ id: data.id }).update(data);
}