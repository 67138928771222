import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Drawer, Button, Input,InputNumber, Select, Table, Form , message} from 'antd';
import { patch_oil_consumption_api, branch_specific_wrongbill_api } from '../../handler/api_handler';
import moment from 'moment';

class Oilconsumptionupdateform extends React.Component {
	constructor(props) {
		super(props);
		this.formRef = React.createRef();
	}

	state = {
		visible: false,
	};

	showDrawer = () => {
		this.setState({ visible: true });
		const Settimeout = this.onFill;
		setTimeout(function () {
			Settimeout();
		}, 100);
	};

	onClose = () => {
		this.setState({ visible: false });
	};

	onFill = () => {
		if (this.formRef.current !== null) {
			this.formRef.current.setFieldsValue(this.props.record);
		}
	};

	validateFields = () => {
		const validatedData = this.props.drawerList.map((validate) => {
			console.log('fff', typeof (parseInt(validate.closing_stock)));
			return Boolean(parseInt(validate.closing_stock) || parseInt(validate.closing_stock) === 0)
				? ''
				: (validate.error = 'please fill the data num type only supported');
		});

		this.setState(validatedData);

		const data = validatedData.filter((data) => data !== '');
		console.log(data.length);
		return data.length;
	};


	changeTargets = (index, event) => {
		const updatedArray = [...this.state.input];
		updatedArray[index].closing_stock = event.target.value;
		this.setState(updatedArray);
	};

	onUpdate = (data) => {
		data.date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		patch_oil_consumption_api(data).then(() => {
			this.props.callTableapi(); this.onClose()
		}).catch(({ response }) => {
			const { data, status } = response;
			if (status === 400) {
				if (data.hasOwnProperty("fresh_oil")) {
					message.error("Fresh Oil : " + data.fresh_oil[0]);
				} else if (data.hasOwnProperty("used_oil")) {
					message.error("Used Oil : " + data.used_oil[0]);
				} else if (data.hasOwnProperty("wastage_oil")) {
					message.error("Wastage Oil : " + data.wastage_oil[0]);
				}
			}
		});
	};

	render() {

		const { Option } = Select;

		return (
			<React.Fragment>
				<EditOutlined onClick={this.showDrawer} />
				<Drawer
					title="Oil Consumption Update"
					width={"400"}
					onClose={this.onClose}
					visible={this.state.visible}
					bodyStyle={{ paddingBottom: 80 }}
					footer={
						<div
							style={{
								textAlign: 'right'
							}}
						>
							<Button onClick={this.onClose} style={{ marginRight: 8 }}>
								Cancel
							</Button>
						</div>
					}
				>
					<Form
						ref={this.formRef}
						className="edit_form"
						onFinish={this.onUpdate}
						layout="horizontal"
						name="form_in_modal"
					>

						<Form.Item
							name="id"
							style={{ display: 'none' }}
						>
							<Input />
						</Form.Item>

						<Form.Item
							name="item"
							label="Item"
							rules={[
								{
									required: true,
									message: 'Please select item!'
								}
							]}
						>
							<Select>
								<Option value={1}>Fryer</Option>
								<Option value={2}>kadai</Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="fresh_oil"
							label="Fresh Oil (ltr)"
							rules={[
								{
									required: true,
									message: 'Please input fresh oil!'
								}
							]}
						>
							<InputNumber />
						</Form.Item>

						<Form.Item
							name="used_oil"
							label="Used Oil (ltr)"
							rules={[
								{
									required: true,
									message: 'Please input used oil!'
								}
							]}
						>
							<InputNumber />
						</Form.Item>

						<Form.Item
							name="wastage_oil"
							label="Wastage Oil (ltr)"
							rules={[
								{
									required: true,
									message: 'Please input wastage!'
								}
							]}
						>
							<InputNumber />
						</Form.Item>


						<Form.Item>

							<Button type="primary" htmlType="submit">
								Update
					</Button>
						</Form.Item>
					</Form>
				</Drawer>
			</React.Fragment>
		);
	}
}

export default Oilconsumptionupdateform;
