import React, { useState,Component } from 'react';
import { Button, Modal, Form, Input, Radio, Switch } from 'antd';
import {  EditTwoTone } from '@ant-design/icons';
// import {updateBulkorders} from '../../../handler/LocalDB_handler/BulkorderServiceHandler'
import {update_Bulkorder_details} from '../../../handler/api_handler';
import { message } from 'antd';

  

class UpdateBulkordermodel  extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
       
      }


       state = {
         visible: false
      }
    onFill = () => {
        delete this.props.Bulkorder_data.updated
        delete this.props.Bulkorder_data.created

        if(this.formRef.current !== null) {
            this.formRef.current.setFieldsValue(this.props.Bulkorder_data);
        }
    };

    setVisible(){
        this.setState({visible:!this.state.visible})
    }
    
  
    componentDidUpdate(prevProps, prevState){
        const Settimeout = this.onFill;
        setTimeout(function(){  Settimeout() },100);
    }

    onCreate=(value)=>{
      update_Bulkorder_details(value).then((

      )=>{
        message.success("Updated successfully");
        this.props.setdata();
        this.setVisible();
      }).catch(()=>{});
    }
    render() { 
        return (  
            <div>
      <EditTwoTone
      style={{ fontSize: '20px' }}
        type="primary"
        onClick={() => {
         
          this.setVisible(true);
        }}
      >
       Update Bulkorder
      </EditTwoTone>
      <Modal
          title="Edit bulkorder"
          visible={this.state.visible}
          footer={null}
          onCancel={this.setVisible.bind(this)}
        >
          <Form ref={this.formRef}

      {...this.layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={this.onCreate}
    //   onFinishFailed={this.onFinishFailed}
    > 
      
      <Form.Item name="id" style={{ display:"none" }} > <Input type="hidden"/> </Form.Item>

      <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please input the name of Bulkorder!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="code"
          label="code"
          rules={[
            {
              required: true,
              message: 'Please input the code of Bulkorder!',
            },
          ]}
        >
          <Input />
        </Form.Item>
       
        <Form.Item
          name="status"
          label="status"
          
        >
                   <Switch />

        </Form.Item>
        <Form.Item {...this.tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
        </Modal>
     
    </div>
        );
    }
}
 
export default  UpdateBulkordermodel;





