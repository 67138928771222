import React, { createContext, useState, useEffect } from 'react';
import { get_store_details, Adminbilllist_api } from '../../src/handler/api_handler';

import moment from "moment";

export const Admindailysheetbillcontext = createContext();

const AdmindailysheetbillcontextProvider = (props) => {

   
    //Data
    const Dateformat = "YYYY-MM-DD";
    const Yesterday = moment().subtract(1, 'days').format(Dateformat);
    
    const Ebcolumn = [
        {
            title: 'Meter Name',
            dataIndex: 'meter_name',
            key: 'meter_name'
        },
        {
            title: 'Electric Bill',
            dataIndex: 'electric_bill',
            key: 'electric_bill',
        },
        {
            title: 'Opening Reading',
            dataIndex: 'opening_reading',
            key: 'opening_reading',
        },
        {
            title: 'Closing Reading',
            dataIndex: 'closing_reading',
            key: 'closing_reading',
        },
        {
            title: 'No Of Unit',
            dataIndex: 'no_of_unit',
            key: 'no_of_unit',
        }
    ];

    const Freecolumn = [
        {
            title: 'Bill No',
            dataIndex: 'bill_no',
            key: 'bill_no'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Billed by',
            dataIndex: 'billed_by_name',
            key: 'billed_by_name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }
    ];

    const wrongcolumn = [
        {
            title: 'Bill No',
            dataIndex: 'bill_no',
            key: 'bill_no'
        },
        {
            title: 'Wrong Amount',
            dataIndex: 'wrong_amount',
            key: 'wrong_amount',
        },
        {
            title: 'Correct Amount',
            dataIndex: 'correct_amount',
            key: 'correct_amount',
        },
        {
            title: 'Billed by',
            dataIndex: 'billed_by_name',
            key: 'billed_by_name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }
    ];



    //State management
    const [SelectedDate, setSelectedDate] = useState(Yesterday);
    const [SelectedBranch, setSelectedBranch] = useState("");
    const [BranchList, setBranchList] = useState([]);
    const [SelectedTab, setSelectedTab] = useState(1);
    const [Selectedcolumn,setSelectedcolumn] = useState(wrongcolumn);
    const [Tabledata,setTabledata] = useState([]);
    

    // Method
    const onDatechange = (value, event) => {
        let date = moment(value).format(Dateformat);
        setSelectedDate(date);
    }

    const onTabchange = (key) => {
        setSelectedTab(key);
    }

    const onBranchchange = (branchid) => {
        setSelectedBranch(branchid)
    }

    //Hooks
    useEffect(() => {
        get_store_details().then(({ data }) => {
            setBranchList(data);
            setSelectedBranch(data[0].id);
        }).catch((err) => {

        });
    }, []);

    useEffect(() => {
        if(SelectedDate !== "" && SelectedBranch !== "" && SelectedTab !== ""){
            Adminbilllist_api(SelectedDate,SelectedBranch,SelectedTab).then(({ data }) => {
                    if(SelectedTab === "1"){
                        setSelectedcolumn(wrongcolumn);
                    }else if(SelectedTab === "2"){
                        setSelectedcolumn(Freecolumn);
                    }else if(SelectedTab === "3"){
                        setSelectedcolumn(Ebcolumn);
                    }

                    setTabledata(data);
            }).catch((err) => {

            });
        }
    },[SelectedDate,SelectedTab,SelectedBranch]);

    return (
        <Admindailysheetbillcontext.Provider value={{ 
            Dateformat,
            Yesterday,
            SelectedDate, 
            SelectedBranch,
            BranchList,
            SelectedTab,
            Selectedcolumn,
            Tabledata,
            setSelectedDate,
            setSelectedBranch,
            setBranchList,
            setSelectedTab,
            onDatechange,
            onBranchchange,
            onTabchange
        }}>
            {props.children}
        </Admindailysheetbillcontext.Provider>
    );
};

export default AdmindailysheetbillcontextProvider;