import React, { useState, useContext } from "react";
import {
  Form,
  DatePicker,
  Modal,
  Input,
  Space,
  Button,
  message,
  Row,
  Col,
} from "antd";
import {
  update_particular_user_salary_list,
  user_punch_attendance_api,
} from "../../handler/api_handler";
import { Format24hrs } from "../../handler/helpers.js";

import { SalarylistContext } from "../../context/SalarylistContext";
import { EditTwoTone } from "@ant-design/icons";
import moment from "moment";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailLayout = { wrapperCol: { offset: 8, span: 16 } };

const AttendanceModal = (props) => {
  const ContextData = useContext(SalarylistContext);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [Property, setProperty] = useState(props);

  const onFinish = (fieldsValue) => {
    let Validationdone = true;
    let DateTimeFormatForValidation = "LT";
    let objArray = [];
    fieldsValue.staff_attendance.forEach((element) => {
      if (
        !moment(
          element.formatted_punch_in,
          DateTimeFormatForValidation,
          true
        ).isValid() ||
        !moment(
          element.formatted_punch_out,
          DateTimeFormatForValidation,
          true
        ).isValid()
      ) {
        Validationdone = false;
        alert("Please check the date and time format");
        return Validationdone;
      }

      let Newobject = {
        id: element.id,
        start: Format24hrs(element.formatted_punch_in),
        stop: Format24hrs(element.formatted_punch_out),
      };
      objArray.push(Newobject);
    });

    let Inputresponse = { data: objArray };
    if (Validationdone) {
      user_punch_attendance_api(Inputresponse)
        .then(({ data }) => {
          message.success("Updated successfully");
          handleCancel();
          props.Afterfinishapicall();
        })
        .catch((err) => {
          message.error("Check date time format  hh:mm AM/PM");
        });
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const showModal = () => {
    setIsModalVisible(true);
    onFill(props.record);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFill = (data) => {
    console.log("data", data);
    form.setFieldsValue({
      staff_attendance: data.staff_attendance,
    });
  };

  const ModalTitle = () => {
    return (
      <React.Fragment>
        Alter Attendance Entry{" "}
        <small>
          <b>(hh:mm AM/PM)</b>
        </small>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <EditTwoTone
        style={{ fontSize: "20px" }}
        type="primary"
        onClick={showModal}
      />
      <Modal
        title={<ModalTitle />}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <h4>
              <b>Punch In</b>
            </h4>
          </Col>

          <Col className="gutter-row" span={12}>
            <h4 style={{ textAlign: "left" }}>
              <b>Punch Out</b>
            </h4>
          </Col>
        </Row>

        <Form
          form={form}
          name="time_related_controls"
          {...formItemLayout}
          onFinish={onFinish}
        >
          <Form.List name="staff_attendance">
            {(fields) => (
              <React.Fragment>
                {fields.map((field) => {
                  console.log(field, "chekingded");

                  return (
                    <Space
                      key={field.key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, "formatted_punch_in"]}
                        fieldKey={[field.fieldKey, "formatted_punch_in"]}
                        rules={[
                          { required: true, message: "Missing punch-in" },
                        ]}
                      >
                        <Input placeholder="hh:mm AM/PM" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "formatted_punch_out"]}
                        fieldKey={[field.fieldKey, "formatted_punch_out"]}
                        rules={[
                          { required: true, message: "Missing punch-out" },
                        ]}
                      >
                        <Input placeholder="hh:mm AM/PM" />
                      </Form.Item>
                    </Space>
                  );
                })}
              </React.Fragment>
            )}
          </Form.List>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AttendanceModal;
