import React, { useState, Component } from 'react';
import { Button, Modal, Form, Input,InputNumber, Radio, Switch, Select, Row, Col } from 'antd';
import { EditTwoTone, CheckCircleOutlined } from '@ant-design/icons';
// import {updateRawproducts} from '../../../handler/LocalDB_handler/RawproductServiceHandler'
import { update_product_details } from '../../../handler/api_handler';
import { message } from 'antd';
import { RawproductContext } from '../../../context/Rawproductcontext';
const { Option } = Select;


class UpdateRawproductmodel extends Component {
	constructor(props) {
		super(props);
		this.formRef = React.createRef();
	}
	static contextType = RawproductContext;

	state = {
		visible: false
	};
	onFill = () => {
		delete this.props.Rawproduct_data.updated;
		delete this.props.Rawproduct_data.created;

		if (this.formRef.current !== null) {
			console.log("this.props.Rawproduct_data",this.props.Rawproduct_data);
			this.formRef.current.setFieldsValue(this.props.Rawproduct_data);
		}
	};

	setVisible() {
		this.setState({ visible: !this.state.visible });
	}

	componentDidUpdate(prevProps, prevState) {
		const Settimeout = this.onFill;
		setTimeout(function () {
			Settimeout();
		}, 100);
	}

	onCreate = (value) => {
		update_product_details(value)
			.then(() => {
				message.success('Updated successfully');
				this.props.setdata();
				this.setVisible();
			})
			.catch(() => { });
	};
	render() {
		console.log(this.props.unit)
		return (
			<div>
				<EditTwoTone
					twoToneColor="#FFA500"
					style={{ fontSize: '20px' }}
					type="primary"
					onClick={() => {
						this.setVisible(true);
					}}
				>
					Update Raw Material
				</EditTwoTone>
				<Modal
					title="Edit Raw Material"
					visible={this.state.visible}
					footer={null}
					onCancel={this.setVisible.bind(this)}
				>
					<Form layout="vertical"
						className="edit_form"
						ref={this.formRef}
						{...this.layout}
						name="basic"
						initialValues={{ remember: true }}
						onFinish={this.onCreate}
					>
						<Form.Item name="id" style={{ display: 'none' }}>
							{' '}
							<Input type="hidden" />{' '}
						</Form.Item>
						<Row style={{ marginBottom: '0' }}>
							<Col span={12}>
								<Form.Item
									name="name"
									label="Name"
									rules={[
										{
											required: true,
											message: 'Please input the name of Rawproduct!',
											whitespace: true,
										}
									]}
								>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="unit" label="unit">
									<Select>
										{this.props.unit.map(data => {
											return <Option value={data.id}>{data.name}</Option>
										})}

									</Select>
								</Form.Item>
							</Col>
						</Row>
						
						<Row style={{ marginBottom: '0' }}>
							<Col span={12}>
								<Form.Item
									name="reorder_level"
									label="reorder level"
								>
									<InputNumber />
								</Form.Item>
							</Col>
						</Row>

						<Form.Item {...this.tailLayout}>
							<div className="edit_button_block">
								<Button type="primary" htmlType="submit">
									<CheckCircleOutlined /> Submit
								</Button>
							</div>
						</Form.Item>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default UpdateRawproductmodel;
