import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Input, Button, Space, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { ComplaintCategoryContext } from '../../context/ComplaintCategoryContext';
import Addcomplaintcategorymodel from './AddComplaintCategoryModel';
import Updatecomplaintcategorymodel from './UpdateComplaintCategoryModel';
import { delete_product_details, status_product_update } from '../../handler/api_handler';

class ComplaintCategoryComponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};

	static contextType = ComplaintCategoryContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"

					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small">
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
					text
				)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};
	handleStatus = (id, status) => {
		status_product_update(id, status)
			.then(() => {
				this.context.setProductData();
			})
			.catch(() => { });
	};

	handleOk = (id) => {
		delete_product_details(id)
			.then(() => {
				this.context.setProductData();
			})
			.catch(() => { });
	};

	render() {
		const { Product, setProductData } = this.context;
		console.log("Product List",this.context);
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'id',

			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<Updatecomplaintcategorymodel
							text={text}
							product_data={record}
							setdata={setProductData}
						/>
					</Space>
				)
			}
		];
		return (
			<React.Fragment>
				<Addcomplaintcategorymodel setdata={setProductData}/>
				<Spin spinning={this.context.loading} delay={500}>
					<Table columns={columns} dataSource={Product} pagination={false} bordered style={{width: '500px'}}  />
				</Spin>
			</React.Fragment>
		);
	}
}

export default ComplaintCategoryComponent;
