import React, { createContext, useState, useEffect } from 'react';
import { product_maping_get_api } from '../handler/api_handler';
import { useParams } from "react-router-dom";

export const MappingContext = createContext();

const MappingContextProvider = (props) => {
    const [Product, setProduct] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [classification, setClassification] = useState(0);

    useEffect(() => {
        setProductData(classification);
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const changeClassification = (data) => {
       
        setClassification(data);
        setProductData(data);
    }

    let { storeid } = useParams();


    const setProductData = (classification) => {
        product_maping_get_api(classification).then((response) => {
            console.log(response.data, "dddd")
            setProduct(response.data);
        }).catch((error) => { })
    }
    return (
        <MappingContext.Provider value={{ classification,changeClassification, Product, loading, setProductData, storeid, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </MappingContext.Provider>
    );
};

export default MappingContextProvider;