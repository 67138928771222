import React, { createContext, useState, useEffect } from 'react';
// import { getUnits } from '../handler/LocalDB_handler/UnitServiceHandler/'
import {get_Unit_details} from '../handler/api_handler';
export const UnitContext = createContext();

const UnitContextProvider = (props) => {
    const [Unit, setUnit] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);

    useEffect(() => {
        setUnitData();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setUnitData = () => {
        get_Unit_details().then((details) => {
           
            // setUnit(details.length === undefined ?[details] :details);
            setUnit(details.data);

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <UnitContext.Provider value={{ Unit, loading, setUnitData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </UnitContext.Provider>
    );
};

export default UnitContextProvider;