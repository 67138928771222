import React, { useState ,useContext } from 'react';
import { Button, Modal, Form, Input, Select, Row, Col } from 'antd';
import { create_product } from '../../../handler/api_handler';
import { message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { VegitableproductContext } from '../../../context/Vegitableproductcontext';


const { Option } = Select;

const ModalCoponent = ({ visible, onCreate, onCancel, unit, department }) => {
	const [form] = Form.useForm();
	return (
		<Modal
			visible={visible}
			title="Create product"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form className="edit_form" form={form} layout="vertical" name="form_in_modal">


				<Row style={{marginBottom: '0'}}>
					<Col span={12}>
						<Form.Item
							name="name"
							label="Name"
							rules={[
								{
									required: true,
									message: 'Please input the name of product!',
									whitespace: true
								}
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="unit"
							label="unit"
							rules={[{ required: true, message: 'Please select unit!'}]}
						>
							<Select>
								{unit.map((data) => {
									return <Option value={data.id}>{data.name}</Option>;
								})}
							</Select>
						</Form.Item>
					</Col>
				</Row>


			</Form>
		</Modal>
	);
};

const Addmodal = (props) => {

	const VegContext = useContext(VegitableproductContext);

	const [visible, setVisible] = useState(false);

	const onCreate = (values) => {
		values.classification = 4;
		create_product(values).then(() => {
			message.success("Added successfully");
			setVisible(false);
			VegContext.setListingData();
		}).catch((err) => {
			message.error("Something went wrong");
		});
		
	};

	return (
		<div className="main_top">
			<h2>Veg product</h2>
			<div className="bottom_border"></div>
			<Button
				className="addbtn"
				style={{ marginTop: 0, marginRight: '0' }}
				type="primary"
				onClick={() => {
					setVisible(true);
				}}
			><PlusOutlined />Add Veg Product</Button>
			<ModalCoponent
				visible={visible}
				unit={props.unit}
				department={props.department}
				onCreate={onCreate}
				onCancel={() => {
					setVisible(false);
				}}
			/>
		</div>
	);
};

export default Addmodal;
