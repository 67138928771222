import React, { useState, Component } from 'react';
import { Button, Modal, Form, Input, Radio, DatePicker, Select, Switch, Drawer, InputNumber, Row, Col, message } from 'antd';
import { EditTwoTone, CheckCircleOutlined } from '@ant-design/icons';
import { update_user_details, get_store_details, get_role_details } from '../../../handler/api_handler';
import moment from 'moment';
import { Branchemployeescontext } from '../../../context/Branchemployeescontext';
// import MaskedInput from 'antd-mask-input'

const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';


class UpdateBranchemployeesModel extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }
    static contextType = Branchemployeescontext;
    state = {
        visible: false,
        userstatus: '',
        role: [],
        store: [],
        children: '',
        userdata: [],
    }

    onFill = () => {

        this.props.user_data.date_of_joining = moment(this.props.user_data.date_of_joining);

        if (this.formRef.current !== null) {
            this.formRef.current.setFieldsValue(this.props.user_data);
        }
    };

    setVisible() {
        
        this.setState({ visible: !this.state.visible });
        console.log('setvisible');

        let children = [];
        console.log('role data', this.state.role);
        for (let i = 0; i < this.state.role.length; i++) {
            children.push(<Option key={this.state.role[i]['id']} value={this.state.role[i]['id']}>{this.state.role[i]['name']}</Option>);
        }
        this.setState({ children: children });
    }

    onClosecleardata() {
        this.setVisible()
        this.setState({ userstatus: '' });
        // this.setState({ role: [] });
        this.setState({ store: [] });
        this.setState({ children: [] });
        this.setState({ userdata: [] });
    }

    componentWillMount() {
        
        get_role_details().then((details) => {
            this.setState({ role: details.data });
            let children = [];
            for (let i = 0; i < this.state.role.length; i++) {
                children.push(<Option key={this.state.role[i]['id']} value={this.state.role[i]['id']}>{this.state.role[i]['name']}</Option>);
            }
            this.setState({ children: children });

        }).catch((err) => {
            console.log(err);
        })
        get_store_details().then((details) => {
            this.setState({ store: details.data });
        }).catch((err) => {

        });
    }

    componentDidUpdate(prevProps, prevState) {
        const Settimeout = this.onFill;
        setTimeout(function () { Settimeout() }, 100);
    }

    componentDidMount() {
        this.setState({ userdata: this.props.user_data });
    }

    onCreate = (value) => {
        value.user_role = 2;
        update_user_details(value).then((
        ) => {
            message.success("Updated successfully");
            this.props.setUserData();
            this.setVisible();
        }).catch(({ response }) => {
            const { data, status } = response;
            if (status === 400) {
                if (data.hasOwnProperty("phone")) {
                    message.error(data.phone[0]);

                }
            }
            return 0;
        });
    }

    render() {

        const { TextArea } = Input;

        return (
            <div>
                <EditTwoTone twoToneColor="#FFA500"
                    style={{ fontSize: '20px' }}
                    type="primary"
                    onClick={() => {
                        this.setVisible(true);
                    }}
                >
                    Update Users
      </EditTwoTone>
                <Drawer
                    width={720}
                    title="Edit User"
                    placement="right"
                    closable={false}
                    onClose={this.onClosecleardata.bind(this)}
                    visible={this.state.visible}
                >
                    <Form ref={this.formRef} className="edit_form" layout="vertical"
                        {...this.layout}
                        name="basic"
                        onFinish={this.onCreate}
                    >
                        <Form.Item name="id" style={{ display: "none" }} > <Input type="hidden" /> </Form.Item>

                        <Row style={{ marginBottom: '0' }}>
                            <Col span={12}>
                                <Form.Item
                                    name="first_name"
                                    label="Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the name of staff!',
                                            whitespace: true,
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="email"
                                    label="Email Address"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter the mail address of staff!',
                                            whitespace: true,
                                            type: "email",
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: '0' }}>
                            <Col span={12}>
                                <Form.Item
                                    name="phone"
                                    label="Phone Number"
                                    rules={[
                                        { required: true, message: "Please input a number!" },
                                        { pattern: /^[0-9]{2}\d{8}$/, message: "Please input 10 digit number!" }
                                    ]}
                                >
                                    <InputNumber maxLength={10} style={{ width: "100%" }} />
                                    {/* <MaskedInput mask="9999999999" name="card" size="20"/> */}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="address"
                                    label="Address"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter the mobile number!',
                                            whitespace: true,
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: '0' }}>
                            <Col span={12}>
                                <Form.Item
                                    name="date_of_joining"
                                    label="DOJ"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the DOJ of User!',
                                        },
                                    ]}
                                >
                                    <DatePicker format={dateFormat} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="aadhaar_number"
                                    label="Aadhaar Number"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please enter the aadhaar number!',
                                //         whitespace: true,
                                //     }
                                // ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: '0' }}>
                            <Col span={12}>
                                <input type="hidden" name="user_role" value="2" />
                                <Form.Item
                                    name="employee_role"
                                    label="Sub role"
                                    mode="multiple"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the name of User!',
                                        },
                                    ]}
                                >
                                    {/* _role_list */}
                                    <Select mode="multiple" allowClear>
                                        {this.state.children}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="branch"
                                    label="Branch"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the store of User!',
                                        },
                                    ]}
                                >
                                    <Select defaultActiveFirstOption="true">
                                        {
                                            this.state.store.map((storedata) => <Option value={storedata.id}>{storedata.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: '0' }}>
                            <Col span={12}>
                                <Form.Item
                                    name="is_active"
                                    label="Status"
                                >
                                    <Switch defaultChecked />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item {...this.tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Submit
        </Button>
                        </Form.Item>

                    </Form>
                </Drawer>

            </div>
        );
    }
}

export default UpdateBranchemployeesModel;