import React, { createContext, useState, useEffect } from 'react';
import {get_admin_vegitable_product_list,get_product_type,get_product_unit} from '../handler/api_handler';
export const VegitableproductContext = createContext();

const VegitableproductContextProvider = (props) => {
    const [Listing, setListing] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [unit, setUnit] = useState([]);
    const [department,setdepartment] = useState([]);


    useEffect(() => {
        setListingData();
        get_product_dept();
        get_product_units();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const get_product_dept=()=>{
        get_product_type().then((res)=>{
          setdepartment(res.data)
        }).catch((err)=>{})
    }

    const get_product_units=()=>{
        get_product_unit().then((res)=>{
            setUnit(res.data)
        }).catch((err)=>{

        })
    }

    const setListingData = () => {
        get_admin_vegitable_product_list().then((details) => {
            setListing(details.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <VegitableproductContext.Provider value={{ Listing, unit,department,loading, setListingData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </VegitableproductContext.Provider>
    );
};

export default VegitableproductContextProvider;