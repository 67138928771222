import React,{Component} from 'react';

class Dashboard extends Component {
    state = {  }
    render() { 
        return ( 
        <div>
            <h2>Hello</h2>
        </div> 
        );
    }
}
 
export default Dashboard;