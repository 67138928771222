import React, { createContext, useState, useEffect } from 'react';
import {get_branch_expenses_details} from '../handler/api_handler';
export const ExpensescategoryContext = createContext();

const ExpensescategoryContextProvider = (props) => {
    const [Expensescategory, setExpensescategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);

    useEffect(() => {
        setExpensescategoryData();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setExpensescategoryData = () => {
        get_branch_expenses_details().then((details) => {
           
            // setExpensescategory(details.length === undefined ?[details] :details);
            setExpensescategory(details.data);

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <ExpensescategoryContext.Provider value={{ Expensescategory, loading, setExpensescategoryData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </ExpensescategoryContext.Provider>
    );
};

export default ExpensescategoryContextProvider;