import React, { createContext, useState, useEffect } from 'react';
import {product_stock_in_list,branch_product_inventory_create_api} from '../handler/api_handler';
import { useHistory } from "react-router-dom";
import { message } from 'antd';

import moment from 'moment';
import { validNumber } from '../handler/helpers.js';

export const InventoryContext = createContext();

const InventoryContextProvider = (props) => {

    let history = useHistory();

    const [Producttypeselected,setProducttypeselected] = useState("3");
    const [productlist, setproductlist] = useState([]);
    const [Lising, setLising] = useState([]);

    const [Currentdate, setCurrentdate] = useState(moment().format('YYYY-MM-DD').toString());

    const InitialData = { product: "", stock_in_hand: 0, buying_price : 0 ,quantity: 0, new_quantity_in_hand: 0 };

    const loopProduct = (data) => {
        
        let Newarray = [];

        data.forEach((item) => { 

            let Newobj = { 
                product: item.product , 
                productname : item.product_name+" - "+item.unit, 
                stock_in_hand: item.on_hand,
                buying_price : 0,
                quantity: 0,
                new_quantity_in_hand: 0 
            };

            Newarray.push(Newobj);

        });

        setLising(Newarray);
    }

    const callProductlist = (producttype) => {
        setLising([]);
        product_stock_in_list(producttype).then(({ data }) =>  {
            loopProduct(data);
            setproductlist(data);
        }).catch(() => {})
    }

    const Removerow = (index) => {
        let Listingdata = [...Lising];
        Listingdata.splice(index,1);
        setLising(Listingdata);
    }

    const Addnewrow = () => {
        let Listingdata = [...Lising];
        Listingdata.push(InitialData);
        setLising(Listingdata);
    }

    const IsproductidAlreadyexist = (productid) => {
        return Lising.some(el => el.product === productid);
    }

    const onProductchange = (producttype) => {
        callProductlist(producttype);
    }

    const InventorySubmit = () => {
        let isSubmit = true;


        let Newarrayobj = [...Lising].filter(item => Number(item.quantity) !== 0);

        Newarrayobj.forEach((list) => {
            if(!validNumber(list.quantity) || !validNumber(list.buying_price) || !validNumber(list.product)){
                isSubmit = false;
                return false;
            }
        });

        if(isSubmit){
            let SubmitData = Newarrayobj.map((item) => { item.date = Currentdate+'T00:00:00'; return item });

            branch_product_inventory_create_api(SubmitData).then(({ data }) => {
                history.push("/dailyinward/list");
            }).catch((err) => {

            });
        }else{
            message.warning('Please fill all fields with valid data');
        }
    }

    useEffect(() => {
		callProductlist(Producttypeselected);
	}, []);

    return (
        <InventoryContext.Provider 
            value={{
                Producttypeselected,
                 InitialData,
                 productlist,
                 Lising,
                 setLising,
                 Addnewrow,
                 Removerow,
                 InventorySubmit,
                 Currentdate,
                 setCurrentdate,
                 IsproductidAlreadyexist,
                 onProductchange
                 }}
            >
            {props.children}
        </InventoryContext.Provider>
    );
};

export default InventoryContextProvider;