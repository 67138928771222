import React, { useState } from 'react';
import { Button, Modal, Form, Input, Select } from 'antd';
import { post_product_details } from '../../../handler/api_handler';

import { message } from 'antd';
import {PlusOutlined} from "@ant-design/icons";

const Productmodel = ({ visible, onCreate, onCancel, category, type, unit }) => {
	const [ form ] = Form.useForm();
	const { Option } = Select;
	const handleChange = () => {};
	return (
		<Modal
			visible={visible}
			title="Create a new Product"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form form={form} layout="horizontal" name="form_in_modal" className="edit_form">
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true,
							message: 'Please input the name of Product!'
						}
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="category"
					label="Product_category"
					rules={[
						{
							required: true,
							message: 'Please input the Product_category of Product!'
						}
					]}
				>
					<Select>
						{category.map((data) => {
							return <Option value={data.id}>{data.name}</Option>;
						})}
					</Select>
				</Form.Item>

				<Form.Item
					name="product_unit"
					label="unit"
					rules={[
						{
							required: true,
							message: 'Please input the unit of Product!'
						}
					]}
				>
					<Select>
						{unit.map((data) => {
							return <Option value={data.id}>{data.name}</Option>;
						})}
					</Select>
				</Form.Item>
				<Form.Item
					name="product_type"
					label="item_type"
					rules={[
						{
							required: true,
							message: 'Please input the item type of Product!'
						}
					]}
				>
					<Select>
						{type.map((data) => {
							return <Option value={data.id}>{data.name}</Option>;
						})}
					</Select>
				</Form.Item>

				{/* <Form.Item name="product_quantity" label="product_quantity">
					<Input />
				</Form.Item>
				<Form.Item name="description" label="description">
					<Input />
				</Form.Item> */}
				{/* const {Option} =Select; */}
			</Form>
		</Modal>
	);
};

const AddProductmodel = (props) => {
	const [ visible, setVisible ] = useState(false);
	const onCreate = (values) => {
		values.sort_order = 1;
		post_product_details(values)
			.then(() => {
				message.success('Added successfully');
				props.setdata();
			})
			.catch((err) => {});
		setVisible(false);
	};

	return (
		<div className="main_top">
			<h2>Product</h2>
			<Button className="addbtn" style={{marginTop: 0, marginRight: '0'}}
				type="primary"
				onClick={() => {
					setVisible(true);
				}}
			><PlusOutlined />
				Add Product
			</Button>
			<Productmodel
				category={props.category}
				unit={props.unit}
				type={props.type}
				visible={visible}
				onCreate={onCreate}
				onCancel={() => {
					setVisible(false);
				}}
			/>
		</div>
	);
};

export default AddProductmodel;
