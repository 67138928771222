import './App.css';
import 'antd/dist/antd.less';
// import 'antd/dist/antd.less';

import './services/AttendenceServices'

import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from "./router";
import Routecomponent from "./Routecomponent";
import LoginComponent from './components/login';






function App() {
    return (
        <div className="App">

            <Suspense fallback={<div> </div>}>

                <Switch>
                    {
                        routes.map((route) => {
                            return route.subMenu ?
                                route.submenuOptions.map((option) => {
                                    return <Route
                                        exact
                                        path={option.path}
                                        key={option.path}
                                        render={props => (<Routecomponent {...props} component={option.component} title={option.title} provider={option.provider} isprivate={true} />)}
                                    />
                                })
                                : <Route
                                    exact
                                    path={route.path}
                                    key={route.path}
                                    render={props => (<Routecomponent {...props} component={route.component} title={route.title} provider={route.provider} isprivate={true} />)}
                                />
                        })
                    }
                    <Route exact path="/" render={props => (<Routecomponent {...props} component={LoginComponent} title="Login" />)} />

                </Switch>
            </Suspense>

        </div>
    );
}


export default App;

