import React, { useState, useContext } from 'react';
import { Form, Modal, Button, Input, message, Row, Col, Select } from 'antd';
import { update_vendor_api } from '../../../handler/api_handler';
import { Vendorcontext } from '../../../context/Vendorcontext';
import { CheckCircleOutlined, EditTwoTone } from '@ant-design/icons';

const SalaryComponent = (props) => {
	const VendorDetail = useContext(Vendorcontext);
	const [form] = Form.useForm();
	const { Option } = Select;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [Property, setProperty] = useState(props);


	const onFinish = (fieldsValue) => {
		const FormData = {
			id: Property.record.id,
			name: fieldsValue.name,
			company_name: fieldsValue.company_name,
			address: fieldsValue.address,
			category: fieldsValue.category
		};
		update_vendor_api(FormData).then(() => {
			message.success("Added successfully");
			VendorDetail.getList();
			handleCancel();
		}).catch(() => { });
	};

	const showModal = () => {
		setIsModalVisible(true);
		onFill(props.record);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onFill = (data) => {
		form.setFieldsValue({
			id: data.id,
			name: data.name,
			category: data.category,
			company_name: data.company_name,
			address: data.address,
		});
	};

	return (
		<React.Fragment>
			<EditTwoTone twoToneColor="#FFA500" style={{ fontSize: '20px' }} type="primary" onClick={showModal}></EditTwoTone>
			<Modal
				title="Update vendor"
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>

				<Form form={form} name="time_related_controls" onFinish={onFinish} className="edit_form" layout="vertical">

					<Row style={{ marginBottom: '0' }}>
						<Col span={12}>
							<Form.Item
								name="name"
								label="Contact Person"
								rules={[
									{
										required: true,
										message: 'Please input the contact person!',
										whitespace: true,
									}
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="company_name"
								label="Company Name"
								rules={[
									{
										required: true,
										message: 'Please input the company name!',
										whitespace: true,
									}
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row style={{ marginBottom: '0' }}>
						<Col span={12}>
							<Form.Item
								name="address"
								label="Address"
								rules={[
									{
										required: true,
										message: 'Please input the address!',
										whitespace: true,
									}
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								name="category"
								label="Category"
							>
								<Select>
									{VendorDetail.vendorCategorylist.map((item) => <Option value={item.id}>{item.name}</Option>)}
								</Select>
							</Form.Item>
						</Col>

					</Row>


					<Form.Item>
						<div className="edit_button_block">
							<Button type="primary" htmlType="submit">
								<CheckCircleOutlined /> Update
							</Button>
						</div>
					</Form.Item>

				</Form>
			</Modal>


		</ React.Fragment>


	);
};

export default SalaryComponent;