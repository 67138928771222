import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Drawer, Button, Input, Select, Table, Form } from 'antd';
import {
	bank_cash_received_details_create_api,
	bank_cash_received_details_update_api
} from '../../handler/api_handler';
import moment from 'moment';
const { Option } = Select;
class Ebbillupdateform extends React.Component {
	constructor(props) {
		super(props);
		this.formRef = React.createRef();
	}

	state = {
		visible: false
	};

	showDrawer = () => {
		const Settimeout = this.onFill;
		setTimeout(function() {
			Settimeout();
		}, 100);
	};

	onClose = () => {
		this.props.callDailysheetapi();
		this.props.setBankcashdrawerVisible(!this.props.BankcashdrawerVisible);
	};

	componentWillUpdate = () => {
		this.showDrawer();
	};

	onFill = () => {
		console.log('this.props.record', this.props.record);
		if (this.formRef.current !== null) {
			this.formRef.current.setFieldsValue(this.props.record[0] === undefined ? [] : this.props.record[0]);
		}
	};

	validateFields = () => {
		const validatedData = this.props.drawerList.map((validate) => {
			console.log('fff', typeof parseInt(validate.closing_stock));
			return Boolean(parseInt(validate.closing_stock) || parseInt(validate.closing_stock) === 0)
				? ''
				: (validate.error = 'please fill the data num type only supported');
		});

		this.setState(validatedData);

		const data = validatedData.filter((data) => data !== '');
		console.log(data.length);
		return data.length;
	};

	changeTargets = (index, event) => {
		const updatedArray = [ ...this.state.input ];
		updatedArray[index].closing_stock = event.target.value;
		this.setState(updatedArray);
	};

	onUpdate = (data) => {
		if (data.id === undefined) {
			data.date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
			bank_cash_received_details_create_api(data).then(() => {
				this.onClose();
				this.formRef.current.setFieldsValue({});
			}).catch(() => {});
		}else{
			bank_cash_received_details_update_api(data).then(() => {
				this.onClose();
				this.formRef.current.setFieldsValue({});
			}).catch(() => {});
		}
	};

	render() {
		// console.log('this.props.record',this.props.record)
		return (
			<React.Fragment>
				<Drawer
					title="Bank cash"
					width={'750'}
					onClose={this.onClose}
					visible={this.props.BankcashdrawerVisible}
					bodyStyle={{ paddingBottom: 80 }}
					footer={
						<div
							style={{
								textAlign: 'right'
							}}
						>
							<Button onClick={this.onClose} style={{ marginRight: 8 }}>
								Cancel
							</Button>
						</div>
					}
				>
					<Form
						ref={this.formRef}
						className="edit_form"
						onFinish={this.onUpdate}
						layout="horizontal"
						name="form_in_modal"
					>
                        <Form.Item
							name="name"
							label="name"
							rules={[
								{
									required: true,
									message: 'Please input the name !'
								}
							]}
						>
							<Input />
						</Form.Item>
                     

						<Form.Item
							name="amount"
							label="Amount"
							rules={[
								{
									required: true,
									message: 'Please input the amount of bill!'
								}
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item name="id" style={{ display: 'none' }}>
							<Input />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								{this.props.record.length > 0 ? 'Update' : 'Submit'}
							</Button>
						</Form.Item>
					</Form>
				</Drawer>
			</React.Fragment>
		);
	}
}

export default Ebbillupdateform;
