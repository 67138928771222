import React, { Component } from 'react';
import { SearchOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin, Skeleton, Select, DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import { FreebillContext } from '../../context/Freebillcontext';
import AddFreebillmodels from '../Freebill/AddFreebillModel/';
// import {deleteFreebills} from '../../handler/LocalDB_handler/FreebillServiceHandler'
import { delete_Freebill_details } from '../../handler/api_handler';
import Updatemodelcomponent from '../../components/Freebill/UpdateFreebillModel'
import moment from 'moment';

const { Option } = Select;

class Freebillreport extends Component {
    state = {
        searchText: '',
        searchedColumn: '',
        currentStore: 0,
        startDate: '',
        toDate: ''
    };
    static contextType = FreebillContext;


    storeChange = (value, event) => {
        // alert(value);
        this.setState({currentStore: value});
        
    };

    changeFromdate = (value, event) => {
        // alert(value);
        let date = moment(value).format('YYYY-MM-DD');
        this.setState({startDate: date});
    }

    changeTodate = (value, event) => {
        let date = moment(value).format('YYYY-MM-DD');
        this.setState({toDate: date});
    }

    
    

    render() {
        const { store, Freebillcustomer, filterFreebill, Freebill, loading, setFreebillData, setVisibles, visible } = this.context;
        const filterData = () => {
            var data = {
                'currentStore': this.state.currentStore,
                'startDate': this.state.startDate,
                'toDate': this.state.toDate
            }
            console.log(data);
            filterFreebill(data).then((res) => {
                console.log(res);
            }).catch((err) => {
                console.log(err);
            });

            // let dt = filterFreebill(data);
        };
        return (
            <React.Fragment>
                <label>Store :</label>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a store"
                    optionFilterProp="children"
                    onChange={(value, event) => this.storeChange(value, event)}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    
                >
                    {
                        store.map(storedata => {
                            return <Option value={storedata.id}>{storedata.name}</Option>
                        })
                    }
                </Select>
                <label>From date :</label>

                <DatePicker onChange={(value, event) => this.changeFromdate(value, event)} />
                <label>To date :</label>

                <DatePicker onChange={(value, event) => this.changeTodate(value, event)} />
                <Button type="primary" onClick={filterData}>Filter</Button>
            </React.Fragment>
        );
    }
}

export default Freebillreport;