import React from 'react';
import { Row, Col } from 'antd';
import { isEmptymeanszero } from '../../../src/handler/helpers';

function Branchcashmanagement(props) {
    let { data } = props;

    return (
        <div className="opening_block" style={{ width: '1200px', backgroundColor: '#fff' }}>
            <Row style={{marginBottom:'10px'}}>
                <Col span={18}> <p>OPENING PETTY CASH</p> </Col>
                <Col span={4}> <p>{isEmptymeanszero(data.opening_cash)}</p> </Col>
            </Row>

            <Row style={{marginBottom:'10px'}}>
                <Col span={18}> <p>CLOSING PETTY CASH</p> </Col>
                <Col span={4}> <p>{isEmptymeanszero(data.closing_cash)}</p> </Col>
            </Row>

            <Row style={{marginBottom:'10px'}}>
                <Col span={18}> <p>EXPENSES</p> </Col>
                <Col span={4}> <p>{isEmptymeanszero(data.expenses)} </p></Col>
            </Row>

            <Row style={{marginBottom:'10px'}}>
                <Col span={18}> <p>INCENTIVE</p> </Col>
                <Col span={4}> <p>{isEmptymeanszero(data.incentive)} </p></Col>
            </Row>

            <Row style={{marginBottom:'10px'}}>
                <Col span={18}> <p>SKY CASH</p> </Col>
                <Col span={4}> <p>{isEmptymeanszero(data.sky_cash)}</p> </Col>
            </Row>

            <Row style={{marginBottom:'10px'}}>
                <Col span={18}> <p>CREDIT SALES</p> </Col>
                <Col span={4}> <p>{data.credit_sales}</p> </Col>
            </Row>

            <Row style={{marginBottom:'10px'}}>
                <Col span={18}> <p>BANK CASH</p> </Col>
                <Col span={4}> <p>{isEmptymeanszero(data.bank_cash)}</p> </Col>
            </Row>

            <Row style={{ marginTop: '20px', paddingTop:'20px', borderTop: '1px solid #f0f0f082'}}>
                <Col span={18}> <p>TOTAL</p> </Col>
                <Col span={4}> <p>{isEmptymeanszero(data.total_sales)}</p></Col>
            </Row>
            
            
        </div>
    );
}

export default Branchcashmanagement;