import React, { useContext } from 'react';
import { Select } from 'antd';
import { InventoryContext } from '../../context/InventoryContext';
import { store_product_instock_count_api } from '../../handler/api_handler';

function SelectForm(props) {

    const Contextdata = useContext(InventoryContext);
    const { Option } = Select;

    const productwiseInstockcount = (productid) => {
        store_product_instock_count_api(productid).then(({ data }) => {
            let stock_in_hand = Number(data.product_count);
            let Listingdata = [...Contextdata.Lising];
            let new_quantity_in_hand = stock_in_hand + Number(Listingdata[props.index].quantity);
            Listingdata[props.index].new_quantity_in_hand = new_quantity_in_hand;
            Listingdata[props.index].stock_in_hand = stock_in_hand;
            Contextdata.setLising(Listingdata);
        }).catch(({ response }) => {
            const { status } = response;
            if (status === 400) {
                let Listingdata = [...Contextdata.Lising];
                Listingdata[props.index].new_quantity_in_hand = 0;
                Listingdata[props.index].stock_in_hand = 0;
                Listingdata[props.index].buying_price = 0;
                Contextdata.setLising(Listingdata);
            }
        });
    }

    const onProductChange = (productid) => {
        const isExist = productid !== "" ? Contextdata.Lising.some(el => el.product === productid) : false;

        if (!isExist) {
            let Listingdata = [...Contextdata.Lising];
            Listingdata[props.index].product = productid;
            Contextdata.setLising(Listingdata);
            productwiseInstockcount(productid);
        } else {
            alert("Product already exist");
            let Listingdata = [...Contextdata.Lising];
            Listingdata[props.index] = Contextdata.InitialData;
            Contextdata.setLising(Listingdata);
        }
    }

    const onSearchFunction = (value) => {
    }

    return (
        <Select
            showSearch
            style={{ width: 200 }}
            optionFilterProp="children"
            onSearch={onSearchFunction}
            onChange={onProductChange}
            value={props.product}
        >
            <Option value="" key="EmptyKeyOption"> Select Name </Option>
            {
                Contextdata.productlist.map((item) => <Option key={item.id} value={item.id}> {item.name + " - " + item.unit_name} </Option>)
            }
        </Select>
    );
}

export default SelectForm;