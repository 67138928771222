import React, { useContext } from 'react';
import { Spin, Tag } from 'antd';
import { DailysheetContext } from '../../context/Dailysheetcontext.js';
import Tablecomponent from './TableComponent';
import Drawer from './Drawer';
import Drawerdual from './DrawerDual';
import Oilconsumption from './Oilconsumption';
import Freebill from './Freebill';
import Ebdrawer from './EbDrawer';
import Departmentsalescount from './Departmentsalescount';
import Creditsalse from './CreditSalse';
import Creditstatement from './CreditStatement';
import Denominationdrawercomponent from './Denominationdrawercomponent';
import Denominationupdatedrawercomponent from './Denominationupdatedrawercomponent';
import Bankcashcomponent from './BankCash';
import CashmanagementDrawer from './CashmanagementDrawer';
import Pettycashdetailsdrawercomponent from './Pettycashdetailsdrawercomponent';
import Pettycashdetailsupdatedrawercomponent from './Pettycashdetailsupdatedrawercomponent';

import Foodwastage from './Foodwastage';
import moment from 'moment';
import ActionButtonComponent from './ActionButtonComponent';
import Cashhandover from './CashHandover';

const Index = (props) => {
	let {
		formStructure,
		date,
		setdate,
		Mainformindex,
		drawerTitle,
		setdrawerTitle,
		setMainformindex,
		setformStructure,
		drawerVisible,
		setdrawerVisible,
		APImethod,
		drawerList,
		setAPImethod,
		getDrawerList,
		callDailysheetapi,
		changeTargets,
		changeTargetsEb,
		CreditStatementdrawerVisible,
		setCreditStatementdrawerVisible,
		CashhandoverDrawerVisibile, 
        setCashhandoverDrawerVisibile,
		createRawOperationalProduct,
		drawerdualVisible,
		EblistCreate,
		setdrawerdrawerdualVisible,
		OilconsumptionDrawerVisibile,
		setOilconsumptionDrawerVisibile,
		freebilldrawerVisible,
		setfreebilldrawerVisible,
		loading,
		setLoading,
		BankcashdrawerVisible,
		setBankcashdrawerVisible,
		setCreditsalsedrawerVisible,
		CreditsalsedrawerVisible,
		Eblist,
		EbdrawerVisibile,
		setEbdrawerVisibile,
		FoodwastageDrawerVisibile,
		setFoodwastageDrawerVisibile,
		DepartmentsalescountDrawerVisibile,
		setDepartmentsalescountDrawerVisibile,
		Bankcash,
		Denominationopen,
		setDenominationopen,
		updateDenominationopen,
		setupdateDenominationopen,
		setCashmanagementdrawerVisible,
		CashmanagementdrawerVisible,
		getCashmanagement,
		Cashmanagementdata,
		Pettycashopen,
		setPettycashopen,
		updatePettycashopen,
		setupdatePettycashopen
	} = useContext(DailysheetContext);

	const columns = [
		{
			title: 'Category type',
			render: (text, record) => <p>{record.name}</p>
		},
		{
			title: 'Updated Status',

			render: (text, record) => (
				<React.Fragment>
					{record.completed_status ? (
						<Tag color={'green'} key={record.id}>
							{'Completed'}
						</Tag>
					) : (
						<Tag color={'red'} key={record.name}>
							{'Not completed'}
						</Tag>
					)}
				</React.Fragment>
			)
		},
		{
			title: 'Action',
			render: (text, record) => <ActionButtonComponent record={record} />
		}
	];

	return (
		<React.Fragment>
			<div class="date_picker">
				<h2 style={{ textAlign: 'center' }}>{moment().format('DD-MM-YYYY')}</h2>
			</div>

			<Cashhandover
			 CashhandoverDrawerVisibile={CashhandoverDrawerVisibile}
			 setCashhandoverDrawerVisibile={setCashhandoverDrawerVisibile}
			 callDailysheetapi={callDailysheetapi}
			/>
			<Ebdrawer
				changeTargetsEb={changeTargetsEb}
				Eblist={Eblist}
				EblistCreate={EblistCreate}
				APImethod={APImethod}
				setEbdrawerVisibile={setEbdrawerVisibile}
				visible={EbdrawerVisibile}
				callDailysheetapi={callDailysheetapi}
			/>
			<Drawerdual callDailysheetapi={callDailysheetapi} drawerdualVisible={drawerdualVisible} setdrawerdrawerdualVisible={setdrawerdrawerdualVisible} />
			<Oilconsumption
				OilconsumptionDrawerVisibile={OilconsumptionDrawerVisibile}
				setOilconsumptionDrawerVisibile={setOilconsumptionDrawerVisibile}
			/>
			<Freebill
				callDailysheetapi={callDailysheetapi}
				freebilldrawerVisible={freebilldrawerVisible}
				setfreebilldrawerVisible={setfreebilldrawerVisible}
			/>
			<Creditsalse
				callDailysheetapi={callDailysheetapi}
				CreditsalsedrawerVisible={CreditsalsedrawerVisible}
				setCreditsalsedrawerVisible={setCreditsalsedrawerVisible}
			/>
			<Oilconsumption
				callDailysheetapi={callDailysheetapi}
				OilconsumptionDrawerVisibile={OilconsumptionDrawerVisibile}
				setOilconsumptionDrawerVisibile={setOilconsumptionDrawerVisibile}
			/>
			<Creditstatement
				callDailysheetapi={callDailysheetapi}
				CreditStatementdrawerVisible={CreditStatementdrawerVisible}
				setCreditStatementdrawerVisible={setCreditStatementdrawerVisible}
			/>

			<Foodwastage
				callDailysheetapi={callDailysheetapi}
				FoodwastageDrawerVisibile={FoodwastageDrawerVisibile}
				setFoodwastageDrawerVisibile={setFoodwastageDrawerVisibile}
			/>

			<Departmentsalescount
			callDailysheetapi={callDailysheetapi}
				DepartmentsalescountDrawerVisibile={DepartmentsalescountDrawerVisibile}
				setDepartmentsalescountDrawerVisibile={setDepartmentsalescountDrawerVisibile}
			/>
			<Bankcashcomponent
				record={Bankcash}
				callDailysheetapi={callDailysheetapi}
				BankcashdrawerVisible={BankcashdrawerVisible}
				setBankcashdrawerVisible={setBankcashdrawerVisible}
			/>

			<Denominationdrawercomponent
				Denominationopen={Denominationopen}
				setDenominationopen={setDenominationopen}
				callDailysheetapi={callDailysheetapi}
			/>



			<Denominationupdatedrawercomponent
				updateDenominationopen={updateDenominationopen}
				setupdateDenominationopen={setupdateDenominationopen}
				callDailysheetapi={callDailysheetapi}
			/>

			<Pettycashdetailsdrawercomponent
				Pettycashopen={Pettycashopen}
				setPettycashopen={setPettycashopen}
				callDailysheetapi={callDailysheetapi}
			/>

			<Pettycashdetailsupdatedrawercomponent
				updatePettycashopen={updatePettycashopen}
				setupdatePettycashopen={setupdatePettycashopen}
				callDailysheetapi={callDailysheetapi}
			/>

			<CashmanagementDrawer callDailysheetapi={callDailysheetapi} CashmanagementdrawerVisible={CashmanagementdrawerVisible} setCashmanagementdrawerVisible={setCashmanagementdrawerVisible} Cashmanagementdata={Cashmanagementdata} />

			{formStructure.map((data) => {
				return (
					<Spin spinning={loading}>
						<div className="main_top">
							<h2>
								{data.name} {data.completed_count} / {data.total_count}
							</h2>
							<div className="bottom_border" />
							<Drawer
								APImethod={APImethod}
								drawerTitle={drawerTitle}
								visible={drawerVisible}
								setVisible={setdrawerVisible}
								drawerList={drawerList}
								changeTargets={changeTargets}
								createRawOperationalProduct={createRawOperationalProduct}
							/>
							<Tablecomponent columns={columns} data={data.sub_menu} />
						</div>
					</Spin>
				);
			})}
		</React.Fragment>
	);
};

export default Index;
