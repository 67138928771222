import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio, TimePicker, Select } from 'antd';
import { message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { save_cash_handover, branch_specific_wrongbill_api } from '../../handler/api_handler';
const { Option } = Select;
const Ebbillcreateform = (props) => {

	const [form] = Form.useForm();

	const onsubmit = (data) => {
		data.time = moment(data.time).format('HH:mm:ss');
		console.log(data);
		data.date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		save_cash_handover(data)
			.then(() => {
				props.callTableapi();
				props.onChildrenDrawerClose();
				form.resetFields();
			})
			.catch(() => {});
	};

	return (
		<div className="main_top">
			<Form form={form} className="edit_form" onFinish={onsubmit} layout="horizontal" name="form_in_modal">
				<Form.Item
					name="bill_no"
					label="bill no"
					rules={[
						{
							required: true,
							message: 'Please input the bill no!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="amount"
					label="Amount"
					rules={[
						{
							required: true,
							message: 'Please input the amount of bill!'
						}
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="name"
					label="name"
					rules={[
						{
							required: true,
							message: 'Please input the amount of bill!'
						}
					]}
				>
					<Select>
						{props.user.map((user) => {
							return <Option value={user.id}>{user.first_name}</Option>
						})}
					</Select>
				</Form.Item>
				<Form.Item
					name="time"
					label="Time"
					rules={[
						{
							required: true,
							message: 'Please input the description of Bill!'
						}
					]}
				>
					<TimePicker defaultOpenValue={moment('00:00:00', 'hh:mm:ss A')} />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default Ebbillcreateform;
