import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio, DatePicker, Select } from 'antd';
// import {addBulkorders} from '../../../handler/LocalDB_handler/BulkorderServiceHandler'
import { post_Bulkorder_details } from '../../../handler/api_handler';
import { message } from 'antd';
import {PlusOutlined} from "@ant-design/icons";

const { Option } = Select;

const Bulkordermodel = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Create a new Bulkorder"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form className="edit_form"
        form={form}
        layout="horizontal"
        name="form_in_modal"
       
      >
        <Form.Item
          name="customer_name"
          label="Customer Name"
          rules={[
            {
              required: true,
              message: 'Please input the customer name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Customer phone"
          rules={[
            {
              required: true,
              message: 'Please input the customer phone!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phone_2"
          label="Customer phone 2"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="address"
          label="Customer address"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="order_note"
          label="Order note"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="delivery_date"
          label="Delivery date"
          rules={[
            {
              required: true,
              message: 'Please input the delivery date!',
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          rules={[
            {
              required: true,
              message: 'Please input the status!',
            },
          ]}
        >
          <Select defaultValue="lucy">
      <Option value="jack">Jack</Option>
      <Option value="lucy">Lucy</Option>
      <Option value="disabled" disabled>
        Disabled
      </Option>
      <Option value="Yiminghe">yiminghe</Option>
    </Select>
        </Form.Item>
       
      
        
      </Form>
    </Modal>
  );
};

const AddBulkordermodel = (props) => {
  const [visible, setVisible] = useState(false);

  const onCreate = (values) => {
    post_Bulkorder_details(values).then(() =>{
      message.success("Added successfully");
     props.setdata();
    }).catch((err) =>{

    });
    setVisible(false);
  };

  return (
      <div className="main_top">
          <h2>Bulk Order</h2>
      <Button className="addbtn" style={{marginTop: 0, marginRight: '0'}}
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      ><PlusOutlined />
       Add Bulkorder
      </Button>
      <Bulkordermodel
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

export default AddBulkordermodel;