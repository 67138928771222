import React, { createContext, useState, useEffect } from 'react';
import { get_store_details,get_sub_branch_api,get_ebmeter_api } from '../handler/api_handler';
export const EbmeterContext = createContext();

const EbmeterContextProvider = (props) => {
    const [Ebmeter, setEbmeter] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [subBranch, setsubBranch] = useState([]);
    const [Branch, setBranch] = useState([]);
    const [branchid,setbranchid] = useState("");
    

    const getBranchList = () => {
        get_store_details().then((details) => {
            setbranchid(details.data[0].id);
            setBranch(details.data);
        }).catch((error) => { })
    }

    useEffect(() => {
        getBranchList();
    }, []);

    const subBranchList = (id) => {
        setbranchid(id);
        ebMetered(id);
        get_sub_branch_api(id).then((response) => {
            setsubBranch(response.data);
        }).catch((error) => {

        })
    }

    const ebMetered = (id)=>{
        get_ebmeter_api(id).then((response) => {
            setEbmeter(response.data);
        }).catch((error) => {})
    }


    return (
        <EbmeterContext.Provider value={{ Ebmeter, loading, visible, Branch, subBranchList,ebMetered, subBranch ,branchid}}>
            {props.children}
        </EbmeterContext.Provider>
    );
};

export default EbmeterContextProvider;