import React, { useState, Component } from 'react';
import { Button, Modal, Form, Input, Radio, Switch, Select, DatePicker } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
// import {updateFreebills} from '../../../handler/LocalDB_handler/FreebillServiceHandler'
import { update_Freebill_details } from '../../../handler/api_handler';
import { message } from 'antd';
import moment from 'moment';

const { Option } = Select;

class UpdateFreebillmodel extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }


  state = {
    visible: false,
    freebilldata: [],
  }

  componentDidMount() {
    this.setState({ freebilldata: this.props.Freebill_data });
}
  onFill = () => {
    delete this.props.Freebill_data.updated
    delete this.props.Freebill_data.created
    console.log('Got free bill data', this.state.freebilldata);
    this.state.freebilldata.date = moment(this.state.freebilldata.date);
    if (this.formRef.current !== null) {
      this.formRef.current.setFieldsValue(this.state.freebilldata);
    }
  };

  setVisible() {
    this.setState({ visible: !this.state.visible })
  }


  componentDidUpdate(prevProps, prevState) {
    const Settimeout = this.onFill;
    setTimeout(function () { Settimeout() }, 100);
  }

  onCreate = (value) => {
    update_Freebill_details(value).then((

    ) => {
      message.success("Updated successfully");
      this.props.setdata();
      this.setVisible();
    }).catch(() => { });
  }
  render() {
    return (
      <div>
        <EditTwoTone
          style={{ fontSize: '20px' }}
          type="primary"
          onClick={() => {
            this.setVisible(true);
          }}
        >
          Update Freebill
      </EditTwoTone>
        <Modal
          title="Edit Freebill"
          visible={this.state.visible}
          footer={null}
          onCancel={this.setVisible.bind(this)}
        >
          <Form ref={this.formRef}

            {...this.layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={this.onCreate}
          //   onFinishFailed={this.onFinishFailed}
          >

            <Form.Item name="id" style={{ display: "none" }} > <Input type="hidden" /> </Form.Item>

            <Form.Item
          name="bill_no"
          label="Bill No"
          rules={[
            {
              required: true,
              message: 'Please input the name of bill no!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="amount"
          label="amount"
          rules={[
            {
              required: true,
              message: 'Please input the amount!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="date"
          label="Billed on"
          rules={[
            {
              required: true,
              message: 'Please input the DOJ of User!',
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="billed_by"
          label="Billed by"
          rules={[
            {
              required: true,
              message: 'Please input the status!',
            },
          ]}
        >
          <Select style={{ width: 120 }}
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
            {
              this.props.User.map(users => {
               return <Option value={users.id}>{users.username}</Option>
              })
            }
          </Select>
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: 'Please input the description!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="billed_for"
          label="Billed for"
          rules={[
            {
              required: true,
              message: 'Please input the billed for!',
            },
          ]}
        >
          <Select defaultValue=""
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>

            
            {
              this.props.Freebillcustomer.map(freebillcustomer => {
                return <Option value={freebillcustomer.id}>{freebillcustomer.name}</Option>
                
              })
            }
          </Select>
        </Form.Item>
            <Form.Item {...this.tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
        </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default UpdateFreebillmodel;