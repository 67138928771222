import React, { createContext, useState,useEffect } from 'react';
import { get_vendor_list_api,update_vendor_api,fetch_vendor_category } from '../handler/api_handler';

export const Vendorcontext = createContext();

const VendorcontextProvider = (props) => {

    let [Listingdata,setListingdata] = useState();
    let [vendorCategorylist,setvendorCategorylist] = useState([]);

    useEffect(() => {
        getList();
        vendorCategory();
    }, []);

    const getList = () => {
        get_vendor_list_api().then(({ data }) => { 
            setListingdata(data);
        }).catch(() => {

        });
    }

    const formUpdate = (data) => {
        update_vendor_api(data).then(() => {

        }).catch(err => {

        });
    }

    const vendorCategory = () => { 
        fetch_vendor_category().then(({ data }) => {
            setvendorCategorylist(data);
        }).catch(err => {

        });
    }

    return(
        <Vendorcontext.Provider 
        value={{
            Listingdata ,
            formUpdate,
            getList,
            vendorCategory,
            vendorCategorylist,
        }}
        >
            {props.children}
        </Vendorcontext.Provider>
    );
};

export default VendorcontextProvider;