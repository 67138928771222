import React, { useState, useContext } from 'react';
import { Modal, Table } from 'antd';
import { salary_user_report } from '../../../handler/api_handler';
import { SalaryreportlistContext } from '../../../context/SalaryreportlistContext';
import { EyeOutlined } from '@ant-design/icons';

const SalaryComponent = (props) => {
    const SalaryBreakReport = useContext(SalaryreportlistContext);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [Salarybreak, setSalarybreak] = useState([]);

    const showModal = () => {
        setIsModalVisible(true);
        callSalarybreak()
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'id',
        },
        {
            title: 'Time Spend',
            dataIndex: 'time_spend',
            key: 'id',
        },
        {
            title: 'Salary',
            dataIndex: 'salary',
            key: 'id',
        },
        {
            title: 'OT Salary',
            dataIndex: 'ot_salary',
            key: 'id',
        },
        {
            title: 'Food Allowance',
            dataIndex: 'food_allowance',
            key: 'food_allowance',
        },
        {
            title: 'Total salary',
            dataIndex: 'total_salary',
            key: 'id',
        }
       

    ];


    const breakqueryParameter = () => {
        let Baseurl = 'salary_user_report/' + props.record.id + '/';
        let Query = '';

        if (SalaryBreakReport.previousMonthsearch !== "") {
            let explodeData = SalaryBreakReport.previousMonthsearch.split('-');
            Query = `?month=${explodeData[1]}&year=${explodeData[0]}`;
        }
        return Baseurl + Query;
    }


    const callSalarybreak = () => {
        let Endurl = breakqueryParameter();
        salary_user_report(Endurl).then(({ data }) => {
            setSalarybreak(data);
        }).catch(err => { });
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <React.Fragment>

            <EyeOutlined twoToneColor="#52c41a"
                style={{ fontSize: '20px' }}
                type="primary"
                onClick={showModal}
            >
            </EyeOutlined>

            <Modal
                width={1000}
                title="Salary break up"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <Table columns={columns} dataSource={Salarybreak.attendance_data} pagination={false} />
            </Modal>
        </ React.Fragment>
    );
};

export default SalaryComponent;