import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Input, Button, Space, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { Admindailysheetbillcontext } from '../../context/Admindailysheetbillcontext';
import { ReloadOutlined } from '@ant-design/icons';

class Billlisttable extends Component {
    state = {
        searchText: '',
        searchedColumn: ''
    };

    static contextType = Admindailysheetbillcontext;

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                    >
                        Search
					</Button>
                    <Button type="danger" onClick={() => this.handleReset(clearFilters)}>
                        <ReloadOutlined />
						Reset
					</Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const columns = [
            {
                title: 'Bill No',
                dataIndex: 'bill_no',
                key: 'bill_no'
            },
            {
                title: 'Wrong Amount',
                dataIndex: 'wrong_amount',
                key: 'wrong_amount',
            },
            {
                title: 'Correct Amount',
                dataIndex: 'correct_amount',
                key: 'correct_amount',
            },
            {
                title: 'Billed by',
                dataIndex: 'billed_by_name',
                key: 'billed_by_name',
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            }
        ];

        return (
            <React.Fragment>
                <Table columns={this.props.column} dataSource={this.props.data} pagination={false} bordered style={{ width: '1200px' }} />
            </React.Fragment>
        );
    }
}

export default Billlisttable;