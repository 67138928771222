import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio, DatePicker, Select, Switch, Drawer, Row, Col, message, InputNumber } from 'antd';
import { post_user_details } from '../../../handler/api_handler';
import { PlusOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

var role = false;
const onChange = e => {
    role = e.target.value;
};



const Usermodel = ({ visible,  onCancel, roles, Store, onClose,setUserData,setVisible }) => {
    const [form] = Form.useForm();
    const [userstatus, setuserstatus] = useState(false);
    const onCreate = (values) => {
        values.user_role = 2;
        post_user_details(values).then(() => {
            message.success("Added successfully");
           setUserData();
            setVisible(false);
            form.resetFields()
            return 1;
        }).catch(({ response }) => {
            // console.log('Error data', err);
            // message.error(err.phone[0]);

            const { data, status } = response;
            if (status === 400) {
                if (data.hasOwnProperty("phone")) {
                    message.error(data.phone[0]);

                }
            }
            return 0;
        });
        
    };
    const { TextArea } = Input;

    const userstatuschange = (id) => {
        setuserstatus(id);
    }

    const children = [];
    for (let i = 0; i < roles.length; i++) {
        children.push(<Option key={roles[i]['id']}>{roles[i]['name']}</Option>);
    }

    return (
        <Drawer
            title="Add User"
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
            width={720}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={onCancel} style={{ marginRight: 8 }}>
                        <CloseCircleOutlined /> Cancel
              </Button>
                    <Button onClick={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                onCreate(values).then((res) => {
                                    console.log('res123', res)
                                    if(res == 1) {
                                        form.resetFields();
                                    }
                                });
                                
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }} type="primary">
                        <CheckCircleOutlined /> Submit
              </Button>
                </div>
            }
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                className="edit_form"
            >

                <Row style={{ marginBottom: '0' }}>
                    <Col span={12}>
                        <Form.Item
                            name="first_name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the name of staff!',
                                    whitespace: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter the mail address of staff!',
                                    whitespace: true,
                                    type: "email",
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginBottom: '0' }}>
                    <Col span={12}>
                        <Form.Item
                            name="phone"
                            label="Phone Number"
                            rules={[
                                { required: true, message: "Please input a number!" },
                                { pattern: /^[0-9]{2}\d{8}$/, message: "Please input 10 digit number!" }
                              ]}
                        >
                            <InputNumber maxLength={10} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="address"
                            label="Address"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter the mobile number!',
                                    whitespace: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginBottom: '0' }}>
                    <Col span={12}>
                        <Form.Item
                            name="date_of_joining"
                            label="DOJ"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the DOJ of User!',
                                },
                            ]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="aadhaar_number"
                            label="Aadhaar Number"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter the aadhaar number!',
                                    whitespace: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginBottom: '0' }}>
                    <Col span={12}>
                        <Form.Item
                            name="employee_role"
                            label="Sub role"
                            mode="tags"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the role of User!',
                                },
                            ]}
                        >
                            <Select mode="multiple"
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {/* {roles.map((role) => <Option key={role.id}>{role.name}</Option>)} */}
                                {children}
                            </Select>


                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="branch"
                            label="Branch"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the store of user!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {
                                    Store.map((storedata) => <Option value={storedata.id}>{storedata.name}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginBottom: '0' }}>
                    <Col span={12}>
                        <Form.Item
                            name="status"
                            label="Status"
                        >
                            <Switch defaultChecked />
                        </Form.Item>
                    </Col>

                </Row>

            </Form>
        </Drawer>
    );
};


const AddBranchemployeesModel = (props) => {
    const [visible, setVisible] = useState(false);

   

    const onClose = () => {
        setVisible(false);
    };

    console.log('Props data', props);

    return (
        <div>
            <Button className="addbtn" style={{ marginTop: 0, marginRight: '0' }}
                type="primary"
                onClick={() => {
                    setVisible(true);
                }}
            ><PlusOutlined />
                Add User
            </Button>
            <Usermodel
                visible={visible}
                onClose={onClose}
               
                onCancel={() => {
                    setVisible(false);
                }}
                roles={props.roles}
                Store={props.store}
                setVisible={setVisible}
                setUserData={props.setUserData}
            />
        </div>
    );
};

export default AddBranchemployeesModel;