import React, { createContext, useState, useEffect } from 'react';
import {get_product_type,get_product_details,get_product_classification,get_product_unit} from '../handler/api_handler'

export const ProductContext = createContext();

const ProductContextProvider = (props) => {
    const [Product, setProduct] = useState("");
    const [loading, setLoading] = useState(true);
    const [unit, setUnit] = useState([]);
    const [category, setCategory] = useState([]);
    const [type, setType] = useState([]);

    useEffect(() => {
        setProductData();
        setTypeData();
        getProductCategory();
        getUnitData();
    }, [])

    const setTypeData =() => {
        get_product_type().then((response)=>{
            setType(response.data);
        }).catch((error) => {
        })
    }

    const getUnitData =() => {
        get_product_unit().then((response) => {
            setUnit(response.data);
        }).catch((error) => {})
    }

    const getProductCategory = () => {
        get_product_classification().then((response)=>{
            setCategory(response.data);
        });
    }

    const setProductData = () => {

        get_product_details().then((response) => {
            setProduct(response.data);
            setLoading(false);

        }).catch((error) => {})
        
    }


    return (
        <ProductContext.Provider value={{ setProductData, Product, loading,unit,category,type }}>
            {props.children}
        </ProductContext.Provider>
    );
};

export default ProductContextProvider;