import React, { createContext } from 'react';
// import { message } from 'antd';
export const DashboardContext = createContext();

const DashboardContextProvider = (props) => {


    return (
        <DashboardContext.Provider value={"Dashboard"}>
            {props.children}
        </DashboardContext.Provider>
    );
};

export default DashboardContextProvider;