
import React, { useState, useContext } from 'react';
import { Button, Modal, Form, Input, Radio, Row, Col, Select } from 'antd';
import { post_branch_details } from '../../../handler/api_handler';
import { message } from 'antd';

import { PlusOutlined } from "@ant-design/icons";
import { Vendorcontext } from '../../../context/Vendorcontext';
import { create_vendor_api } from '../../../handler/api_handler';

const Vendormodel = ({ visible, onCreate, onCancel, catlist }) => {
	const [form] = Form.useForm();
	const { Option } = Select;
	return (
		<Modal
			visible={visible}
			title="Vendor creation form"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form className="edit_form" form={form} layout="vertical" name="form_in_modal">

				<Row style={{ marginBottom: '0' }}>
					<Col span={12}>
						<Form.Item
							name="name"
							label="Contact Person"
							rules={[
								{
									required: true,
									message: 'Please input the contact person!',
									whitespace: true,
								}
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="company_name"
							label="Company Name"
							rules={[
								{
									required: true,
									message: 'Please input the company name!',
									whitespace: true,
								}
							]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row style={{ marginBottom: '0' }}>
					<Col span={12}>
						<Form.Item
							name="address"
							label="Address"
							rules={[
								{
									required: true,
									message: 'Please input the address!',
									whitespace: true,
								}
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="category"
							label="Category"
						>
							<Select>
								{catlist.map((item) => <Option value={item.id}>{item.name}</Option>)}
							</Select>
						</Form.Item>
					</Col>
				</Row>

			</Form>
		</Modal>
	);
};

const AddVendormodel = (props) => {

	const VendorDetail = useContext(Vendorcontext);

	const [visible, setVisible] = useState(false);

	const onCreate = (values) => {
		create_vendor_api(values)
			.then(() => {
				message.success('Added successfully');
				VendorDetail.getList();
				setVisible(false);
			})
			.catch((err) => { });
	};

	return (
		<div className="main_top">
			<h2>Vendor</h2>
			<div className="bottom_border"></div>
			<Button className="addbtn" style={{ marginTop: 0, marginRight: '0' }}
				type="primary"
				onClick={() => {
					setVisible(true);
				}}
			><PlusOutlined />
				Add vendor
			</Button>

			<Vendormodel
				visible={visible}
				onCreate={onCreate}
				onCancel={() => {
					setVisible(false);
				}}
				catlist={VendorDetail.vendorCategorylist}
			/>
		</div>
	);
};

export default AddVendormodel;