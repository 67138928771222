import React, { createContext, useState,useEffect } from 'react';
import { Creditsalecustomer,update_vendor_api } from '../handler/api_handler';

export const Creditcustomercontext = createContext();

const CreditcustomercontextProvider = (props) => {

    let [Listingdata,setListingdata] = useState();

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        Creditsalecustomer().then(({ data }) => { 
            setListingdata(data);
        }).catch(() => {

        });
    }


    const formUpdate = (data) => {
        update_vendor_api(data).then(() => {

        }).catch(err => {

        });
    }

    return(
        <Creditcustomercontext.Provider 
        value={{
            Listingdata ,
            formUpdate,
            getList
        }}
        >
            {props.children}
        </Creditcustomercontext.Provider>
    );
};

export default CreditcustomercontextProvider;