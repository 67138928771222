import React, { createContext, useState, useEffect } from 'react';
import {get_product_type,get_complaint_status,get_product_category,get_product_unit} from '../handler/api_handler'

export const ComplaintStatusContext = createContext();

const ComplaintStatusContextProvider = (props) => {
    const [Product, setProduct] = useState("");
    const [loading, setLoading] = useState(true);
    const [unit, setUnit] = useState([]);
    const [category, setCategory] = useState([]);
    const [type, setType] = useState([]);

    useEffect(() => {
        setProductData();
        setTypeData();
        getProductCategory();
        getUnitData();
    }, [])

    const setTypeData =() => {
        get_product_type().then((response)=>{
            setType(response.data);
        }).catch((error) => {
        })
    }

    const getUnitData =() => {
        get_product_unit().then((response) => {
            setUnit(response.data);
        }).catch((error) => {})
    }

    const getProductCategory = () => {
        // get_product_category().then((response)=>{
        //     setCategory(response.data);
        // });
    }

    const setProductData = () => {

        get_complaint_status().then((response) => {
            setProduct(response.data);
            setLoading(false);

        }).catch((error) => {})
        
    }


    return (
        <ComplaintStatusContext.Provider value={{ setProductData, Product, loading,unit,category,type }}>
            {props.children}
        </ComplaintStatusContext.Provider>
    );
};

export default ComplaintStatusContextProvider;