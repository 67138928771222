import React, { createContext, useState, useEffect } from 'react';
import {get_complaint_details,get_complaint_status,get_complaint_type} from '../handler/api_handler';
export const ComplaintContext = createContext();

const ComplaintContextProvider = (props) => {
    const [Complaint, setComplaint] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [type, setType] = useState([]);
    const [status, setStatus] =useState([]);

    useEffect(() => {
        setComplaintData();
        set_type_data();
        set_status_data();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }


    const set_type_data = ()=> {
        get_complaint_type().then((response)=>{
            setType(response.data);
        }).catch((error) => {})
    }

    const set_status_data = () => {
        get_complaint_status().then((response)=>{
            setStatus(response.data);
        }).catch((error) => {})
    }

    const setComplaintData = () => {
        get_complaint_details().then((details) => {
           
            setComplaint(details.data)

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <ComplaintContext.Provider value={{ Complaint, loading, setComplaintData, setVisibles, visible, updateData, setUpdateData,status,type }}>
            {props.children}
        </ComplaintContext.Provider>
    );
};

export default ComplaintContextProvider;