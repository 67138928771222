import React, { useState, useEffect } from 'react';
import {Drawer, Table, Button } from 'antd';
import { credit_sale_list_api,credit_sale_customer_list_api } from '../../handler/api_handler';
import Creditsalseupdateform from './CreditSalseupdateform';
import Creditsalsecreateform from './CreditSalsecreateform';

import moment from 'moment';


function onChange(pagination, filters, sorter, extra) {
	console.log('params', pagination, filters, sorter, extra);
}



function Ebbilltable(props) {

	console.log("props.visible", props.visible);

	const [wdata, setdata] = useState([]);

	const [childrenDrawer, setchildrenDrawer] = useState(false);
    const [customer,setCustomer] = useState([]);

	const showChildrenDrawer = () => {
		setchildrenDrawer(true);
	};

	const onChildrenDrawerClose = () => {
		setchildrenDrawer(false);
	}

	const callTableapi = () => {

		const date = moment(new Date()).format('YYYY-MM-DD');
		credit_sale_list_api(date)
			.then((response) => {
				setdata(response.data);
			})
			.catch((err) => { });

	}

	const customerapi = () => {
		credit_sale_customer_list_api().then((response) => {
			setCustomer(response.data);
		}).catch((err) =>{

		})
	}

	const columns = [
		{
			title: 'customer name',
			dataIndex: 'customer_name',
			width: '20%'
		},
		{
			title: 'bill no',
			dataIndex: 'bill_no',
			width: '20%'
		},
		{
			title: 'amount',
			dataIndex: 'amount'
		},
		{
			title: 'Remark',
			dataIndex: 'description'
		},

		

		{
			title: 'Action',
			dataIndex: 'Action',
			render: (text, record) => (<Creditsalseupdateform customer={customer} record={record} callTableapi={callTableapi} />)
		}
	];

	useEffect(() => {
		if (props.visible) {
			callTableapi();
			customerapi();
		}
	}, [props.visible]);

	return (
		<React.Fragment>
			<Button type="primary" style={{ "margin": "15px", float: 'right' }} onClick={showChildrenDrawer}>
				Add
			</Button>

			<Drawer
						title="Credit sales Add"
						width={750}
						closable={true}
						onClose={onChildrenDrawerClose}
						visible={childrenDrawer}
					>
						<Creditsalsecreateform  customer={customer} callTableapi={callTableapi} onChildrenDrawerClose={onChildrenDrawerClose}/>

					</Drawer>

			<Table pagination={false} columns={columns} dataSource={wdata} onChange={onChange} />
		</React.Fragment>
	);
}

export default Ebbilltable;
