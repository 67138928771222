import React, { Component } from 'react';
import { Row } from 'antd';
import Category from './Category';
class Rolecomponent extends Component {
    render() {
        let { role, roleInd } = this.props;
        return (
            <React.Fragment>
                <h1 style={{ textTransform: 'uppercase' }}> {role.employee_role_name} </h1>
                <Row className="incentive_block_main">
                    {
                        role.departments.map((departmentdata, departmentInd) => {
                            return (
                                <Category departmentdata={departmentdata} roleInd={roleInd} departmentInd={departmentInd} />
                            );
                        })
                    }
                </Row>
            </React.Fragment>
        );
    }
}

export default Rolecomponent;