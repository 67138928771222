import React, { useState, Component } from 'react';
import { Button, Modal, Form, Input, Radio, Switch } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
// import {updateTypes} from '../../../handler/LocalDB_handler/TypeServiceHandler'
import { update_Type_details } from '../../../handler/api_handler';
import { message } from 'antd';



class UpdateTypemodel extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();

    }


    state = {
        visible: false
    }
    onFill = () => {
        delete this.props.Type_data.updated
        delete this.props.Type_data.created

        if (this.formRef.current !== null) {
            this.formRef.current.setFieldsValue(this.props.Type_data);
        }
    };

    setVisible() {
        this.setState({ visible: !this.state.visible })
    }

    componentDidUpdate(prevProps, prevState) {
        const Settimeout = this.onFill;
        setTimeout(function () { Settimeout() }, 100);
    }

    onCreate = (value) => {
        update_Type_details(value).then((

        ) => {
            message.success("Updated successfully");
            this.props.setdata();
            this.setVisible();
        }).catch(() => { });
    }
    render() {
        return (
            <div>
                <EditTwoTone
                    style={{ fontSize: '20px' }}
                    type="primary"
                    onClick={() => {

                        this.setVisible(true);
                    }}
                >
                    Update Type
      </EditTwoTone>
                <Modal
                    title="Edit Type"
                    visible={this.state.visible}
                    footer={null}
                    onCancel={this.setVisible.bind(this)}
                >
                    <Form ref={this.formRef}

                        {...this.layout}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={this.onCreate}
                    //   onFinishFailed={this.onFinishFailed}
                    >

                        <Form.Item name="id" style={{ display: "none" }} > <Input type="hidden" /> </Form.Item>

                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the name of Type!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the description of Type!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        {/* <Form.Item
                            name="code"
                            label="code"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the code of Type!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item> */}

                        {/* <Form.Item
                            name="status"
                            label="status"

                        >
                            <Switch defaultChecked />
                        </Form.Item> */}
                        <Form.Item {...this.tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Submit
        </Button>
                        </Form.Item>
                    </Form>
                </Modal>

            </div>
        );
    }
}

export default UpdateTypemodel;