import React, { createContext, useState, useEffect } from 'react';
import {get_sales_product_details,get_product_type,get_product_unit,fetch_sales_product_from_slick_pos} from '../handler/api_handler';
export const SalesproductContext = createContext();

const SalesproductContextProvider = (props) => {
    const [Salesproduct, setSalesproduct] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [unit, setUnit] = useState([]);
    const [department,setdepartment] = useState([]);


    useEffect(() => {
        setSalesproductData();
        get_product_dept();
        get_product_units();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const get_product_dept=()=>{
        get_product_type().then((res)=>{
          setdepartment(res.data)
        }).catch((err)=>{})
    }

    const get_product_units=()=>{
        get_product_unit().then((res)=>{
            setUnit(res.data)
        }).catch((err)=>{

        })
    }

    const fetch_sync_slick_sales_product = () => {
        fetch_sales_product_from_slick_pos().then((details) => {
            setSalesproductData();
        }).catch((err) => {
            console.log(err);
        });
    }

    const setSalesproductData = () => {
        get_sales_product_details().then((details) => {
           
            // setSalesproduct(details.length === undefined ?[details] :details);
            setSalesproduct(details.data);

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <SalesproductContext.Provider value={{ Salesproduct,fetch_sync_slick_sales_product, unit,department,loading, setSalesproductData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </SalesproductContext.Provider>
    );
};

export default SalesproductContextProvider;