import React, { Component } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Table, Input, Button, Space, Popconfirm, Spin, Skeleton } from "antd";
import Highlighter from "react-highlight-words";
import { AttendanceContext } from "../../context/Attendancecontext";
import {
  checkInAttendanceDetails,
  breakInAttendanceDetail,
  breakOutAttenanceDetail,
  checkOutAttendanceDetails,
  AbscentAttendanceDetails,
} from "../../handler/LocalDB_handler/AttendanceServiceHandler";

import {
  get_attendance_details,
  post_attendance_details,
} from "../../handler/api_handler";
import moment from "moment";

class Attendancecomponent extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    sendAttendanceapi: false,
  };
  static contextType = AttendanceContext;

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small">
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  abscent = (data) => {
    AbscentAttendanceDetails(data)
      .then(() => {
        this.context.setAttendanceDataApi();
        this.setState({ sendAttendanceapi: true });
        if (window.navigator.onLine) {
          if (this.state.sendAttendanceapi) {
            this.sendAPIcall()
              .then()
              .catch((err) => {
                alert(err.message);
              });
          }
        }
      })
      .catch(() => {});
  };

  handleCheckIn = (data) => {
    checkInAttendanceDetails(data)
      .then(() => {
        this.context.setAttendanceDataApi();
        this.setState({ sendAttendanceapi: true });
        if (window.navigator.onLine) {
          if (this.state.sendAttendanceapi) {
            this.sendAPIcall()
              .then()
              .catch((err) => {
                alert(err.message);
              });
          }
        } else {
          alert("please check internet connection");
        }
      })
      .catch(() => {});
  };
  handleBreakIn = (data) => {
    breakInAttendanceDetail(data)
      .then(() => {
        this.context.setAttendanceDataApi();
        this.setState({ sendAttendanceapi: true });
        if (window.navigator.onLine) {
          if (this.state.sendAttendanceapi) {
            this.sendAPIcall();
          }
          // setInterval(() => {
          //   this.sendAPIcall();
          // }, 300000);
        }
        // setTimeout(() => {
        //   this.sendAPIcall();
        // }, 1000);
      })
      .catch(() => {});
  };
  handleBreakOut = (data) => {
    breakOutAttenanceDetail(data)
      .then(() => {
        this.context.setAttendanceDataApi();
        this.setState({ sendAttendanceapi: true });
        if (window.navigator.onLine) {
          if (this.state.sendAttendanceapi) {
            this.sendAPIcall();
          }
        }
      })
      .catch(() => {});
  };

  handleCheckOut = (data) => {
    console.log(data, "check out");
    checkOutAttendanceDetails(data)
      .then(() => {
        this.context.setAttendanceDataApi();
        this.setState({ sendAttendanceapi: true });
        if (window.navigator.onLine) {
          if (this.state.sendAttendanceapi) {
            this.sendAPIcall();
          }
        }
      })
      .catch(() => {});
  };

  sendAPIcall = () => {
    post_attendance_details(this.context.Attendance)
      .then(() => {
        this.context.setAttendanceDataApi();
        this.setState({ sendAttendanceapi: false });
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  componentDidMount() {
    if (window.navigator.onLine) {
      if (this.state.sendAttendanceapi) {
        alert("sdsd");
        this.sendAPIcall();
      }
      // setInterval(() => {
      //   this.sendAPIcall();
      // }, 300000);
    }
  }

  render() {
    const {
      Attendance,
      loading,
      setAttendanceData,
      setVisibles,
      visible,
    } = this.context;
    const columns = [
      {
        title: "username",
        dataIndex: "username",
        key: "name",

        ...this.getColumnSearchProps("username"),
      },

      {
        title: "Check In",
        dataIndex: "Check In",
        key: "id",

        render: (text, record) => (
          <Space>
            {!record.check_in &&
            !record.user_attendance[record.user_attendance.length - 1]
              .abscent ? (
              <React.Fragment>
                {/* <h4>{record.user_attendance[0].start}</h4> */}
                <h4>
                  {moment(
                    record.user_attendance[record.user_attendance.length - 1]
                      .start,
                    "HH:mm:ss"
                  ).format("hh:mm:ss A")}
                </h4>
              </React.Fragment>
            ) : !record.abscent && !record.is_attendance_exists ? (
              <Popconfirm
                title="Are you sure？"
                onConfirm={() => this.handleCheckIn(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" style={{ fontSize: "20px" }}>
                  {"Check In"}
                </Button>
              </Popconfirm>
            ) : (
              <Button
                disabled={true}
                style={{ fontSize: "20px", color: "grey" }}
              >
                {"Check In"}
              </Button>
            )}
          </Space>
        ),
      },
      {
        title: "Break In",
        dataIndex: "Break In",
        key: "id",
        render: (text, record) => (
          <Space>
            {record.break_in ? (
              <Popconfirm
                title="Are you sure？"
                onConfirm={() => this.handleBreakIn(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" style={{ fontSize: "20px" }}>
                  {"Break In"}
                </Button>
              </Popconfirm>
            ) : record.break_time[0] !== undefined ? (
              <h4>
                {moment(
                  record.break_time[record.break_time.length - 1].start,
                  "HH:mm:ss"
                ).format(" hh:mm:ss A")}
              </h4>
            ) : (
              <Button
                type="primary"
                disabled={true}
                style={{ fontSize: "20px" }}
              >
                {"Break In"}
              </Button>
            )}
          </Space>
        ),
      },
      {
        title: "Break Out",
        dataIndex: "Break Out",
        key: "id",
        render: (text, record) => (
          <Space>
            {record.break_out ? (
              <Popconfirm
                title="Are you sure？"
                onConfirm={() => this.handleBreakOut(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" style={{ fontSize: "20px" }}>
                  {"Break Out"}
                </Button>
              </Popconfirm>
            ) : record.break_time[0] !== undefined &&
              record.break_time[record.break_time.length - 1].stop !== null ? (
              <h4>
                {moment(
                  record.break_time[record.break_time.length - 1].stop,
                  "HH:mm:ss"
                ).format("hh:mm:ss A")}
              </h4>
            ) : (
              <Button
                type="primary"
                disabled={true}
                style={{ fontSize: "20px" }}
              >
                {"Break Out"}
              </Button>
            )}
          </Space>
        ),
      },
      {
        title: "Check Out",
        dataIndex: "Check Out",
        key: "id",
        render: (text, record) => (
          <Space>
            {record.check_out && !record.break_out ? (
              <Popconfirm
                title="Are you sure？"
                onConfirm={() => this.handleCheckOut(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  disabled={record.user_attendance.length === 0 ? true : false}
                  style={{ fontSize: "20px" }}
                >
                  {"Check Out"}
                </Button>
              </Popconfirm>
            ) : record.user_attendance[0] !== undefined &&
              !record.abscent &&
              !record.break_out ? (
              <h4>
                {moment(
                  record.user_attendance[record.user_attendance.length - 1]
                    .stop,
                  "HH:mm:ss"
                ).format(" hh:mm:ss A")}
              </h4>
            ) : (
              <Button
                disabled={
                  record.abscent || record.break_out
                    ? true
                    : false || record.check_in
                }
                style={{ fontSize: "20px", color: "grey" }}
              >
                {"Check Out"}
              </Button>
            )}
          </Space>
        ),
      },
      {
        title: "Abscent",
        dataIndex: "Abscent",
        key: "id",
        render: (text, record) => (
          <Space>
            {/* {record.name} */}

            {(record.user_attendance[0] !== undefined &&
              record.user_attendance[record.user_attendance.length - 1]
                .abscent) ||
            record.is_attendance_exists ||
            !record.check_in ? (
              <Button
                type="primary"
                className={record.abscent ? "absent_button" : ""}
                // onClick={() => this.abscent(record)}
                disabled={true}
                style={{ fontSize: "20px" }}
              >
                {"Abscent"}
              </Button>
            ) : (
              <Popconfirm
                title="Are you sure？"
                onConfirm={() => this.abscent(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  disabled={false}
                  style={{ fontSize: "20px" }}
                >
                  {"Abscent"}
                </Button>
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ];
    return (
      <React.Fragment>
        <div className="main_top">
          <h2>Date: {moment(new Date()).format("DD-MM-YYYY")}</h2>
          <div className="bottom_border" />
        </div>

        <Spin spinning={loading} delay={500}>
          <h3 style={{ color: "red" }}>
            {"Note: Only salary assigned employee list here."}
          </h3>
          <Table
            columns={columns}
            dataSource={Attendance}
            pagination={false}
            bordered
          />
        </Spin>
      </React.Fragment>
    );
  }
}

export default Attendancecomponent;
