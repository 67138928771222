import React, { createContext, useState, useEffect } from 'react';
import { get_store_details, Adminbilllist_api , Cashdetailslist } from '../../src/handler/api_handler';

import moment from "moment";

export const Admincashdetailscontext = createContext();

const AdmincashdetailscontextProvider = (props) => {

   
    //Data
    const Dateformat = "YYYY-MM-DD";
    const Yesterday = moment().subtract(1, 'days').format(Dateformat);
    
    // Data - 2
    const Creditsalecolumn = [
        {
            title: 'customer name',
            dataIndex: 'customer_name',
            key: 'customer_name'
        },
        {
            title: 'Bill no',
            dataIndex: 'bill_no',
            key: 'bill_no',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }
    ];

      // Data - 3
      const Creditsettlementcolumn = [
        {
            title: 'customer name',
            dataIndex: 'customer_name',
            key: 'customer_name'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }
    ];

    // Data - 5
    const bankcashdetailscolumn = [
        {
            title: 'Branch Name',
            dataIndex: 'branch_name',
            key: 'branch_name'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount'
        }
    ];

    // Data - 6
    const cashhandovercolumn = [
        {
            title: 'Bill No',
            dataIndex: 'bill_no',
            key: 'bill_no'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount'
        }
    ];
    

    
    //State management
    const [apiloading,setapiloading] = useState(false);
    const [SelectedDate, setSelectedDate] = useState(Yesterday);
    const [SelectedBranch, setSelectedBranch] = useState("");
    const [BranchList, setBranchList] = useState([]);
    const [SelectedTab, setSelectedTab] = useState(2);
    const [Selectedcolumn,setSelectedcolumn] = useState(Creditsalecolumn);
    const [Tabledata,setTabledata] = useState([]);
    const [Objectdata,setObjectdata] = useState({});

    // Method
    const onDatechange = (value, event) => {
        let date = moment(value).format(Dateformat);
        setSelectedDate(date);
    }

    const onTabchange = (key) => {
        callCashdetailsapi(SelectedDate,key,SelectedBranch);
    }

    const onBranchchange = (branchid) => {
        setSelectedBranch(branchid);
    }

    const callCashdetailsapi = (date,type,branch) => {
        
        setapiloading(true);

        if(type === "1" || type === "4" || type === "7"){ setObjectdata({}); }else{ setTabledata([]); }
        
        if(date !== "" && branch !== "" && type !== ""){
            Cashdetailslist(date,branch,type).then(({ data }) => {
                    setapiloading(false);
                    if(type === "1" || type === "4" || type === "7"){ setObjectdata(data); }else{ setTabledata(data); }
                    
                    if(type === "2"){
                        setSelectedcolumn(Creditsalecolumn);
                    }else if(type === "3"){
                        setSelectedcolumn(Creditsettlementcolumn);
                    }else if(type === "5"){
                        setSelectedcolumn(bankcashdetailscolumn);
                    }else if(type === "6"){
                        setSelectedcolumn(cashhandovercolumn);
                    }
                    
                    setSelectedTab(type);
            }).catch((err) => {
                setapiloading(false);
                setSelectedTab(type);
            });
        }
    }

    //Hooks
    useEffect(() => {
        get_store_details().then(({ data }) => {
            setBranchList(data);
            setSelectedBranch(data[0].id);
            callCashdetailsapi(SelectedDate,SelectedTab,data[0].id);
        }).catch((err) => {

        });
    }, []);

    useEffect(() => {
        callCashdetailsapi(SelectedDate,SelectedTab,SelectedBranch);
    },[SelectedDate,SelectedBranch]);
    
    return (
        <Admincashdetailscontext.Provider value={{
            Dateformat,
            Yesterday,
            SelectedDate, 
            SelectedBranch,
            BranchList,
            SelectedTab,
            Selectedcolumn,
            Tabledata,
            Objectdata,
            apiloading,
            setSelectedDate,
            setSelectedBranch,
            setBranchList,
            setSelectedTab,
            onDatechange,
            onBranchchange,
            onTabchange
        }}>
            {props.children}
        </Admincashdetailscontext.Provider>
    );
};

export default AdmincashdetailscontextProvider;