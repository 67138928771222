import React, { useState } from 'react';
import { Button, Modal, Form, Input, InputNumber, Radio, Select } from 'antd';
import { message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { post_foodwastage_api, branch_specific_wrongbill_api } from '../../handler/api_handler';

const Foodwastagecreateform = (props) => {

	const { Option } = Select;

	const [form] = Form.useForm();

	const onsubmit = (data) => {
		data.date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		post_foodwastage_api(data).then(() => {
			form.resetFields();
			props.callTableapi();
			props.onChildrenDrawerClose();
		}).catch(({ response }) => {
			const { data, status } = response;
			if (status === 400) {
				if (data.hasOwnProperty("quantity")) {
					message.error("quantity : " + data.quantity);
				} else if (data.hasOwnProperty("mrp_price")) {
					message.error("Mrp Price : " + data.mrp_price);
				}
			}
		});
	};

	return (
		<div className="main_top">
			<Form 
			form={form}
			className="edit_form" onFinish={onsubmit} layout="horizontal" name="form_in_modal">

				<Form.Item
					name="product"
					label="Product"
					rules={[
						{
							required: true,
							message: 'Please select item!'
						}
					]}
				>

<Select
    showSearch
    defaultValue=""
    optionFilterProp="children"
    filterOption={(input, option) =>  option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 }
>

						<Option value="">Select Item</Option>
						{
							props.Allproductlist.map((item) => <Option value={item.id}>{item.name} - {item.unit_name}</Option>)
						}
					</Select>
				</Form.Item>

				<Form.Item
					name="quantity"
					label="Qty"
					rules={[
						{
							required: true,
							message: 'Please input qty!'
						}
					]}
				>
					<InputNumber />
				</Form.Item>
					
				
				<Form.Item
					name="mrp_price"
					label="mrp price"
				>
					<InputNumber />
				</Form.Item>

				<Form.Item
					name="description"
					label="Remark"
					rules={[
						{
							required: true,
							message: 'Please input remark!'
						}
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="wasted_by"
					label="Wasted By"
					rules={[
						{
							required: true,
							message: 'Please select!'
						}
					]}
				>
					<Select
						defaultValue=""
					>
						<Option value="" > Select  Person</Option>
						{
							props.Branchspecificuserlist.map((item) => <Option value={item.id}>{item.first_name}</Option>)
						}
					</Select>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default Foodwastagecreateform;
