import axios from 'axios';
const API = axios;

// console.log(process.env.REACT_APP_BASE_URL)
API.defaults.baseURL = process.env.REACT_APP_BASE_URL;

if("Auth-Token" in localStorage && localStorage.getItem("Auth-Token") !== null && localStorage.getItem("Auth-Token") !== ""){
    API.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem("Auth-Token");
}

export default API;