import {get_attendance_details } from '../../handler/api_handler';
import {createCollections} from '../../handler/LocalDB_handler/createcollection'
import moment from 'moment';


get_attendance_details(moment(new Date()).format('YYYY-MM-DD')).then((res) => {
    createCollections('att',res.data);
}).catch((err) => {
    console.log(err.message);
});

