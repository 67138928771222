import React, { useState,Component } from 'react';
import { Button, Modal, Form, Input, Radio } from 'antd';
import {  EditTwoTone } from '@ant-design/icons';
import {updateRoles} from '../../../handler/LocalDB_handler/RoleServiceHandler'
import { message } from 'antd';

  

class UpdatePriceModel  extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
       
      }


       state = {
         visible: false
      }
    onFill = () => {
        // delete this.props.role_data.updated
        // delete this.props.role_data.created

        if(this.formRef.current !== null) {
            this.formRef.current.setFieldsValue(this.props.price_data);
        }
    };

    setVisible(){
        this.setState({visible:!this.state.visible})
    }
    
  
    componentDidUpdate(prevProps, prevState){
        const Settimeout = this.onFill;
        setTimeout(function(){  Settimeout() },100);
    }

    onCreate=(value)=>{
      updateRoles(value).then((

      )=>{
        message.success("Updated successfully");
        this.props.setdata();
        this.setVisible();
      }).catch(()=>{});
    }
    render() { 
        return (  
            <div>
      <EditTwoTone
      style={{ fontSize: '20px' }}
        type="primary"
        onClick={() => {
         
          this.setVisible(true);
        }}
      >
       Update Role
      </EditTwoTone>
      <Modal
          title="Edit Product price"
          visible={this.state.visible}
          footer={null}
          onCancel={this.setVisible.bind(this)}
        >
          <Form ref={this.formRef}

      {...this.layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={this.onCreate}
    //   onFinishFailed={this.onFinishFailed}
    > 
      
      <Form.Item name="id" style={{ display:"none" }} > <Input type="hidden"/> </Form.Item>

      <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please input the name of Role!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="MRP"
          label="MRP"
          rules={[
            {
              required: true,
              message: 'Please input the MRP of Role!',
            },
          ]}
        >
          <Input />
        </Form.Item>
       
        <Form.Item
          name="Selling_price"
          label="Selling_price"
          
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="Buying_price"
          label="Buying_price"
          
        >
          <Input />
        </Form.Item>
        <Form.Item {...this.tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
        </Modal>
     
    </div>
        );
    }
}
 
export default  UpdatePriceModel;





