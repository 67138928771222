import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  TimePicker,
  DatePicker,
  Space,
  Select,
  Button,
  Tag,
  message,
  Row,
  Col,
} from "antd";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import {
  get_store_details,
  salary_attendance_user_report_api,
  user_punch_attendance_api,
  previous_date_api,
  export_report_attendance_api,
} from "../../handler/api_handler";
import Updatemodel from "../AttendanceReport/update";
import AttendanceModal from "./AttendanceModal";
import BreaktimingModal from "./BreaktimingModal";
import { Modal } from "antd";

const originData = [];
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

const { RangePicker } = DatePicker;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [exportdate, setExportdate] = useState(moment());
  const [branch, setbranch] = useState([]);
  const [visible, setVisible] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [BranchId, setBranchId] = useState(0);
  const [Currentdate, setCurrentdate] = useState(
    moment()
      .subtract(1, "days")
      .startOf("day")
      .format("YYYY-MM-DD")
      .toString()
  );

  function getBranch() {
    get_store_details()
      .then((res) => {
        setbranch(res.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (BranchId !== "") {
      setListingData(Currentdate);
    }
  }, [BranchId]);

  useEffect(() => {
    callPreviousdate();
  }, []);

  const callPreviousdate = () => {
    previous_date_api()
      .then(({ data }) => {
        setCurrentdate(data.date);
        getBranch();
      })
      .catch((err) => {});
  };

  const [editingKey, setEditingKey] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    setUpdateData(record);
    setVisible(!visible);
  };
  const visibledata = () => {
    setVisible(!visible);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const setListingData = (date) => {
    let Baseurl = "salary_attendance_user_report/" + BranchId + "/";
    let Query = "";
    if (date !== undefined && date !== "" && date !== null) {
      Query = `?date=${date}`;
    }
    let CompleteUrl = Baseurl + Query;

    salary_attendance_user_report_api(CompleteUrl)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {});
  };

  const Afterfinishapicall = () => {
    setListingData(Currentdate);
  };

  const save = async (key) => {
    console.log("saving", key);
    try {
      const row = await form.validateFields();
      const newData = [...data];

      const index = newData.findIndex((item) => key.key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const onBranchChange = (ChangedBranchId) => {
    setBranchId(ChangedBranchId);
  };

  const columns = [
    {
      title: "Staff Name",
      dataIndex: "user_name",

      editable: false,
    },

    {
      title: "Working Hours",
      dataIndex: "time_spend",

      editable: false,
    },
    {
      title: "OT Hours",
      dataIndex: "ot_time_spend",

      editable: false,
    },
    {
      title: "Attendance",
      dataIndex: "attedance",
      render: (text, record) =>
        record.attendance == "Present" ? (
          <Tag color="green">Present</Tag>
        ) : (
          <Tag color="red">absent</Tag>
        ),
      editable: false,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record) =>
        record.staff_attendance.length === 0 ? (
          "No data found"
        ) : (
          <AttendanceModal
            record={record}
            Afterfinishapicall={Afterfinishapicall}
          />
        ),
    },
    {
      title: "Break In & Break Out",
      dataIndex: "operation",
      render: (text, record) =>
        record.break_time.length === 0 ? (
          "No data found"
        ) : (
          <BreaktimingModal record={record} />
        ),
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  function handleChangeDate(data, dateString) {
    setCurrentdate(dateString);
    setListingData(dateString);
  }

  function onFinish(data) {
    console.log("Data", data);
    user_punch_attendance_api()
      .then(({ data }) => {})
      .catch((err) => {});

    console.log("onFinish", moment(data.date).format("YYYY-MM-DD"));
  }

  function onDateExport(date) {
    console.log(date);
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (BranchId === 0) {
      window.open(
        process.env.REACT_APP_BASE_URL +
          "salary_attendance_user_report_list_to_excel/" +
          0 +
          "/" +
          exportdate[0] +
          "/" +
          exportdate[1] +
          "/"
      );
    } else {
      window.open(
        process.env.REACT_APP_BASE_URL +
          "salary_attendance_user_report_list_to_excel/" +
          BranchId +
          "/" +
          exportdate[0] +
          "/" +
          exportdate[1] +
          "/"
      );
    }

    // export_report_attendance_api(exportdate, BranchId)
    //   .then(({ data }) => {})
    //   .catch((err) => {});

    console.log(exportdate);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function handleDateRange(data, dateString) {
    setExportdate(dateString);

    console.log(dateString);
  }

  return (
    <React.Fragment>
      <Updatemodel
        visible={visible}
        updateData={updateData}
        visibledata={visibledata}
      />

      <Modal
        title="Date Range Export"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Space direction="vertical" size={12}>
          <RangePicker onChange={handleDateRange} />
        </Space>
      </Modal>

      <Form onFinish={onFinish}>
        <Form.Item name="date" label="date">
          <DatePicker
            onChange={handleChangeDate}
            defaultValue={moment(Currentdate, dateFormat)}
            format={dateFormat}
          />

          <Button type="primary" onClick={showModal}>
            Export
          </Button>
        </Form.Item>
        <Form.Item name="branch" label="Branch">
          <Select
            showSearch
            style={{ width: 200, marginBottom: "10px" }}
            placeholder="Select branch"
            optionFilterProp="children"
            onChange={onBranchChange}
            value={BranchId}
          >
            <Option value={0}>All branch</Option>
            {branch.map((branchData) => (
              <Option value={branchData.id}>{branchData.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
    </React.Fragment>
  );
};
export default EditableTable;
