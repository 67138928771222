import React, { createContext, useState } from 'react';

export const Adminoilconsumptioncontext = createContext();

const AdminoilconsumptioncontextProvider = (props) => {
    const [Data, setData] = useState("");

    return (
        <Adminoilconsumptioncontext.Provider value={{ Data }}>
            {props.children}
        </Adminoilconsumptioncontext.Provider>
    );
};

export default AdminoilconsumptioncontextProvider;