import React, { Component, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Select,
  Input,
  Button,
  Space,
  Spin,
  Table,
  Card,
  Col,
  Row,
  message,
  DatePicker,
} from "antd";
import Highlighter from "react-highlight-words";
import { SalaryreportlistContext } from "../../../context/SalaryreportlistContext";
import { delete_user_details } from "../../../handler/api_handler";
import Reportbreakupmodal from "./Reportbreakupmodal";
import DisplayPriceComponent from "../../../components/HelperComponent/DisplayPriceComponent";
import DatepickerComponent from "./DatepickerComponent";
import { Modal, Form } from "antd";

class SalaryReportComponent extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    isModalVisible: false,
    setIsModalVisible: false,
    setExportdate: "",
  };

  static contextType = SalaryreportlistContext;

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small">
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  showModal = () => {
    this.setState({ setIsModalVisible: true });
  };

  handleDateRange = (data, dateString) => {
    this.setState({ setExportdate: dateString });

    console.log(dateString);
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleOk = (id) => {
    delete_user_details(id)
      .then(() => {
        this.context.setUserData();
      })
      .catch(() => {});
  };

  setdata = () => {
    console.log("Updated");
  };

  handleOk = () => {
    const { BranchId } = this.context;
    if (BranchId === 0) {
      window.open(
        process.env.REACT_APP_BASE_URL +
          "salary_report_to_excel/" +
          0 +
          "/?start=" +
          this.state.setExportdate[0] +
          "&stop=" +
          this.state.setExportdate[1]
      );
    } else {
      window.open(
        process.env.REACT_APP_BASE_URL +
          "salary_report_to_excel/" +
          BranchId +
          "/?start=" +
          this.state.setExportdate[0] +
          "&stop=" +
          this.state.setExportdate[1]
      );
    }
    this.setState({ setIsModalVisible: false });
    // export_report_attendance_api(exportdate, BranchId)
    //   .then(({ data }) => {})
    //   .catch((err) => {});
  };

  render() {
    const monthFormat = "YYYY-MM";
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const YearData = this.context.previousMonthsearch.split("-")[0];
    const { Branch, BranchId, onBranchChange, Listing, loading } = this.context;

    const columns = [
      {
        title: "Staff Name",
        dataIndex: "staff_name",
        key: "id",
      },
      {
        title: "Overall Salary",
        dataIndex: "overall_user_salary",
        key: "id",
      },
      {
        title: "Overall OT Salary",
        key: "id",
        dataIndex: "overall_ot_salary",
      },
      {
        title: "Total Salary",
        key: "id",
        dataIndex: "total_salary",
      },
      {
        title: "Salary Breakup",
        key: "id",
        dataIndex: "work_hours",
        render: (text, record) => <Reportbreakupmodal record={record} />,
      },
    ];

    return (
      <React.Fragment>
        <div className="main_top">
          <h2>salary list</h2>
          <div className="bottom_border"></div>
        </div>

        <div style={{ marginBottom: "15px" }}>
          <Select
            showSearch
            style={{ width: 200, marginBottom: "10px", marginTop: "15px" }}
            placeholder="Select branch"
            optionFilterProp="children"
            onChange={onBranchChange}
            value={BranchId}
          >
            <Option value={0}>All branch</Option>
            {Branch.map((branchData) => (
              <Option value={branchData.id}>{branchData.name}</Option>
            ))}
          </Select>
        </div>

        <Row gutter={24} style={{ marginBottom: "30px" }}>
          <Col span={12}>
            <Space direction="vertical" size={12}>
              <DatepickerComponent
                defaultValue={this.context.previousMonthsearch}
              />
            </Space>
          </Col>
        </Row>

        <Button type="primary" onClick={this.showModal}>
          Export
        </Button>

        <div className="main_top">
          <h2 style={{ textTransform: "uppercase" }}>
            <b>Staff Salary Report</b>
          </h2>
          <div className="bottom_border"></div>
        </div>

        <div className="site-card-wrapper" style={{ marginBottom: "30px" }}>
          <Row gutter={16}>
            <Col span={8}>
              <Card title="Total Salary" bordered={false}>
                <DisplayPriceComponent priceValue={Listing.total_salary} />
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Total OT Salary" bordered={false}>
                <DisplayPriceComponent priceValue={Listing.total_ot} />
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Total Incentive Salary" bordered={false}>
                NaN
              </Card>
            </Col>
          </Row>
        </div>
        <div className="main_top">
          <h2 style={{ textTransform: "uppercase" }}>
            <b>
              {this.context.MonthText} {YearData}
            </b>
          </h2>
          <div className="bottom_border"></div>
        </div>

        <Spin spinning={loading} delay={500}>
          <Table
            columns={columns}
            dataSource={Listing.data}
            pagination={false}
            bordered
            style={{ width: "1000px" }}
          />
        </Spin>

        <Modal
          title="Date Range Export"
          visible={this.state.setIsModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Space direction="vertical" size={12}>
            <RangePicker onChange={this.handleDateRange} />
          </Space>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SalaryReportComponent;
