import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Drawer, Button, Input, Select, Table, Form , message} from 'antd';
import { credit_sale_update_api, branch_specific_wrongbill_api } from '../../handler/api_handler';
import moment from 'moment';
const { Option } = Select;
class Ebbillupdateform extends React.Component {
	constructor(props) {
		super(props);
		this.formRef = React.createRef();
	}

	state = {
		visible: false
	};

	showDrawer = () => {
		this.setState({ visible: true });
		const Settimeout = this.onFill;
		setTimeout(function() {
			Settimeout();
		}, 100);
	};

	onClose = () => {
		this.setState({ visible: false });
	};

	onFill = () => {
		
		if (this.formRef.current !== null) {
			this.formRef.current.setFieldsValue(this.props.record);
		}
	};

	validateFields = () => {
		const validatedData = this.props.drawerList.map((validate) => {
			console.log('fff', typeof parseInt(validate.closing_stock));
			return Boolean(parseInt(validate.closing_stock) || parseInt(validate.closing_stock) === 0)
				? ''
				: (validate.error = 'please fill the data num type only supported');
		});

		this.setState(validatedData);

		const data = validatedData.filter((data) => data !== '');
		console.log(data.length);
		return data.length;
	};

	changeTargets = (index, event) => {
		const updatedArray = [ ...this.state.input ];
		updatedArray[index].closing_stock = event.target.value;
		this.setState(updatedArray);
	};

	onUpdate = (data) => {
		data.date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		credit_sale_update_api(data)
			.then(() => {
				this.props.callTableapi();
				this.onClose();
			})
			.catch(({ response }) => {
				const { data, status } = response;
				if (status === 400) {
					if (data.hasOwnProperty("bill_no")) {
						message.error("Bill No : " + data.bill_no[0]);
					} else if (data.hasOwnProperty("amount")) {
						message.error("Amount : " + data.amount[0]);
					}
				}
			});
	};

	render() {
		return (
			<React.Fragment>
				<EditOutlined onClick={this.showDrawer} />
				<Drawer
					title="Free Bill Update"
					width={'750'}
					onClose={this.onClose}
					visible={this.state.visible}
					bodyStyle={{ paddingBottom: 80 }}
					footer={
						<div
							style={{
								textAlign: 'right'
							}}
						>
							<Button onClick={this.onClose} style={{ marginRight: 8 }}>
								Cancel
							</Button>
						</div>
					}
				>
					<Form
						ref={this.formRef}
						className="edit_form"
						onFinish={this.onUpdate}
						layout="horizontal"
						name="form_in_modal"
					>
						<Form.Item name="id" style={{ display: 'none' }}>
							<Input />
						</Form.Item>

						<Form.Item
							name="customer"
							label="customer"
							rules={[
								{
									required: true,
									message: 'Please input the customer!'
								}
							]}
						>
							<Select>
								{this.props.customer.map((data) => {
									return <Option value={data.id}>{data.name}</Option>;
								})}
							</Select>
						</Form.Item>

						<Form.Item
							name="bill_no"
							label="bill no"
							rules={[
								{
									required: true,
									message: 'Please input the bill no!'
								}
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="amount"
							label="Amount"
							rules={[
								{
									required: true,
									message: 'Please input the amount of bill!'
								}
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="description"
							label="Remark"
							rules={[
								{
									required: true,
									message: 'Please input the description of Bill!'
								}
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Update
							</Button>
						</Form.Item>
					</Form>
				</Drawer>
			</React.Fragment>
		);
	}
}

export default Ebbillupdateform;
