import React, { useState, Component } from 'react';
import { Button, Modal, Form, Input, Radio, Switch,Select, Row, Col } from 'antd';
import { EditTwoTone, CheckCircleOutlined } from '@ant-design/icons';
// import {updateOperationalproducts} from '../../../handler/LocalDB_handler/OperationalproductServiceHandler'
import { update_product_details } from '../../../handler/api_handler';
import { message } from 'antd';
import { VegitableproductContext } from '../../../context/Vegitableproductcontext';
const { Option } = Select;


class UpdateOperationalproductmodel extends Component {
	constructor(props) {
		super(props);
		this.formRef = React.createRef();
	}

    static contextType = VegitableproductContext;

	state = {
		visible: false
	};
	onFill = () => {
		if (this.formRef.current !== null) {
			this.formRef.current.setFieldsValue(this.props.Listing_data);
		}
	};

	setVisible() {
		this.setState({ visible: !this.state.visible });
	}

	componentDidUpdate(prevProps, prevState) {
		const Settimeout = this.onFill;
		setTimeout(function() {
			Settimeout();
		}, 100);
	}

	onCreate = (value) => {
		update_product_details(value)
			.then(() => {
				message.success('Updated successfully');
				this.setVisible();
				this.context.setListingData();
			})
			.catch(() => {});
	};
	render() {
        console.log(this.props.unit)
		return (
			<div>
				<EditTwoTone
					twoToneColor="#FFA500"
					style={{ fontSize: '20px' }}
					type="primary"
					onClick={() => {
						this.setVisible(true);
					}}
				></EditTwoTone>

				<Modal
					title="Edit product"
					visible={this.state.visible}
					footer={null}
					onCancel={this.setVisible.bind(this)}
				>
					<Form
						className="edit_form" layout="vertical"
						ref={this.formRef}
						{...this.layout}
						name="basic"
						initialValues={{ remember: true }}
						onFinish={this.onCreate}
						//   onFinishFailed={this.onFinishFailed}
					>
						<Form.Item name="id" style={{ display: 'none' }}>
							{' '}
							<Input type="hidden" />{' '}
						</Form.Item>


						<Row style={{marginBottom: '0'}}>
							<Col span={12}>
								<Form.Item
									name="name"
									label="Name"
									rules={[
										{
											required: true,
											message: 'Please input the name of product!',
											whitespace : true,
										}
									]}
								>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="unit" label="unit">
									<Select>
										{this.props.unit.map(data =>{
											return <Option value={data.id}>{data.name}</Option>
										})}

									</Select>
								</Form.Item>
							</Col>
						</Row>
					


						<Form.Item {...this.tailLayout}>
							<div className="edit_button_block">
								<Button type="primary" htmlType="submit">
									<CheckCircleOutlined /> Submit
								</Button>
							</div>
						</Form.Item>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default UpdateOperationalproductmodel;
