import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Input, Button, Space, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SalesproductContext } from '../../context/Salseproductcontext';
import { ReloadOutlined } from '@ant-design/icons';

class Producttable extends Component {
    state = {
        searchText: '',
        searchedColumn: ''
    };

    static contextType = SalesproductContext;

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                    >
                        Search
					</Button>
                    <Button type="danger" onClick={() => this.handleReset(clearFilters)}>
                        <ReloadOutlined />
						Reset
					</Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {

        console.log("this.props.Listing", this.props.Listing);

        const columns = [
            {
                title: 'employee name',
                dataIndex: 'employee_name',
                key: 'employee_name',
                // ?render: (text, record) => (<React.Fragment> { record.name + " - " + record.unit_name} </React.Fragment>)
            },
            {
                title: 'branch name',
                dataIndex: 'branch_name',
                key: 'branch_name',
            },
            {
                title: 'section name',
                dataIndex: 'section_name',
                key: 'section_name',
            },
            {
                title: 'product name',
                dataIndex: 'product_name',
                key: 'product_name',
            },
            {
                title: 'quantity',
                dataIndex: 'quantity',
                key: 'quantity',
            }
        ];
        return (
            <React.Fragment>
                <Table columns={columns} dataSource={this.props.Listing} pagination={false} bordered style={{ width: '1200px' }} />
            </React.Fragment>
        );
    }
}

export default Producttable;
