import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Input, Button, Space, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { VegitableproductContext } from '../../context/Vegitableproductcontext';
import Addmodal from './Addmodal';
import Updatemodal from './Updatemodal';
import { ReloadOutlined } from '@ant-design/icons';

class Listingcomponent extends Component {

	state = {
		searchText: '',
		searchedColumn: ''
	};

	static contextType = VegitableproductContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
					>
						Search
					</Button>
					<Button type="danger" onClick={() => this.handleReset(clearFilters)}>
						<ReloadOutlined />
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		// delete_Listing_details(id)
		// 	.then(() => {
		// 		this.context.setListingData();
		// 	})
		// 	.catch(() => {});
	};

	render() {
		const { Listing, loading, setListingData, setVisibles, visible, unit, department } = this.context;
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',

			},
			{
				title: 'Unit name',
				dataIndex: 'unit_name',
				key: 'unit_name',

			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<Updatemodal
							Listing_data={record}
							setdata={setListingData}
							unit={unit}
							department={department}
						/>
					</Space>
				)
			}
		];
		return (
			<React.Fragment>
				<Addmodal
					unit={unit}
					department={department}
					setdata={setListingData}
				/>
				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={Listing} pagination={false} bordered style={{width: '500px'}} />
				</Spin>
			</React.Fragment>
		);
	}
}

export default Listingcomponent;
