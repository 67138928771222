import React, { createContext, useState, useEffect } from 'react';
// import { getCategorys } from '../handler/LocalDB_handler/CategoryServiceHandler/'
import {get_product_classification} from '../handler/api_handler';
export const CategoryContext = createContext();

const CategoryContextProvider = (props) => {
    const [Category, setCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);

    useEffect(() => {
        setCategoryData();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setCategoryData = () => {
        get_product_classification().then((details) => {
           
            // setCategory(details.length === undefined ?[details] :details);
            setCategory(details.data);

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <CategoryContext.Provider value={{ Category, loading, setCategoryData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </CategoryContext.Provider>
    );
};

export default CategoryContextProvider;