import React, { Component } from 'react';
import { Button, Modal, Form, Input, Select, Row, Col } from 'antd';
import { EditTwoTone, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { update_store_details } from '../../../handler/api_handler';
import { get_city_details } from '../../../handler/api_handler';
import { StoreContext } from '../../../context/Storecontext';

import { message } from 'antd';
const { Option } = Select;

class UpdateStoremodel extends Component {
	constructor(props) {
		super(props);
		this.formRef = React.createRef();
	}

	state = {
		errors: []
	};

	static contextType = StoreContext;

	onFill = () => {
		if (this.formRef.current !== null) {
			this.formRef.current.setFieldsValue(this.context.updateStore);
		}
	};

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.updateModelVisibility !== this.props.updateModelVisibility) {
			if (nextProps.updateModelVisibility) {
				const Settimeout = this.onFill;
				setTimeout(function () {
					Settimeout();
				}, 100);
			}
		}
	}

	onCreate = (value) => {
		update_store_details(value)
			.then(() => {
				message.success('Updated successfully');
				this.context.setStoreData();
				this.context.setVisible();
			})
			.catch((res) => {
				if (res.response != undefined) this.setState({ errors: res.response.data });
			});
	};

	render() {
		return (
			<div>
				<Modal
					title="Edit branch"
					visible={this.context.updateModelVisibility}
					footer={null}
					onCancel={this.context.setVisible.bind(this)}
				>
					<Form
						className="edit_form"
						ref={this.formRef}
						{...this.layout}
						name="basic"
						initialValues={{ remember: true }}
						onFinish={this.onCreate}
						layout="vertical"
					//   onFinishFailed={this.onFinishFailed}
					>
						<Form.Item name="id" style={{ display: 'none' }}>
							{' '}
							<Input type="hidden" />{' '}
						</Form.Item>


						<Row style={{marginBottom: '0'}}>
							<Col span={12}>
								<Form.Item
									name="name"
									label="Name"
									rules={[
										{
											required: true,
											message: 'Please input the name of branch!',
											whitespace: true,
										}
									]}
								>
									<Input />
								</Form.Item>
								<h4 style={{ color: 'red' }}>
									{this.state.errors.name !== undefined ? this.state.errors.name[0] : ''}
								</h4>
							</Col>
							<Col span={12}>
								<Form.Item
									name="state"
									label="state"
									rules={[
										{
											required: true,
											message: 'Please input the state of store!',
										}
									]}
								>
									<Select
										onChange={this.context.onChangeState}
										showSearch

										placeholder="Search to Select state"
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) =>
											optionA.children
												.toLowerCase()
												.localeCompare(optionB.children.toLowerCase())}
									>
										{this.context.State.map((state) => {
											return <Option value={state.id} key={'Updatestate_' + state.id}>{state.name}</Option>;
										})}
									</Select>
								</Form.Item>
								<h4 style={{ color: 'red' }}>
									{this.state.errors.state !== undefined ? this.state.errors.state[0] : ''}
								</h4>
							</Col>
						</Row>

						<Row style={{marginBottom: '0'}}>
							<Col span={12}>
								<Form.Item
									name="city"
									label="city"
									rules={[
										{
											required: true,
											message: 'Please input the city of branch!',
										}
									]}
								>
									<Select
										showSearch

										placeholder="Search to Select city"
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) =>
											optionA.children
												.toLowerCase()
												.localeCompare(optionB.children.toLowerCase())}
									>
										{/* {console.log(city)} */}
										{this.context.city.map((data) => {
											return <Option value={data.id} key={"Updatecity_" + data.id}>{data.name}</Option>;
										})}
									</Select>
								</Form.Item>
								<h4 style={{ color: 'red' }}>
									{this.state.errors.city !== undefined ? this.state.errors.city[0] : ''}
								</h4>
							</Col>
							<Col span={12}>
								<Form.Item
									name="pincode"
									label="pincode"
									rules={[
										{
											required: true,
											message: 'Please input the pincode of store!',
											whitespace: true,
										}
									]}
								>
									<Input type="textarea" />
								</Form.Item>
								<h4 style={{ color: 'red' }}>
									{this.state.errors.pincode !== undefined ? this.state.errors.pincode[0] : ''}
								</h4>
							</Col>
						</Row>

						<Row style={{marginBottom: '0'}}>
							<Col span={12}>
								<Form.Item
									name="latitude"
									label="latitude"
									rules={[
										{
											required: true,
											message: 'Please input the latitude of store!',
											whitespace: true,
										}
									]}
								>
									<Input type="textarea" />
								</Form.Item>
								<h4 style={{ color: 'red' }}>
									{this.state.errors.latitude !== undefined ? this.state.errors.latitude[0] : ''}
								</h4>
							</Col>
							<Col span={12}>
								<Form.Item
									name="longitude"
									label="longitude"
									rules={[
										{
											required: true,
											message: 'Please input the longitude of store!',
											whitespace: true,
										}
									]}
								>
									<Input type="textarea" />
								</Form.Item>
								<h4 style={{ color: 'red' }}>
									{this.state.errors.longitude !== undefined ? (
										this.state.errors.longitude[0]
									) : (
										''
									)}
								</h4>
							</Col>
						</Row>
						<Row style={{marginBottom: '0'}}>
							<Col span={12}>
								<Form.Item
									name="address"
									label="address"
									rules={[
										{
											required: true,
											message: 'Please input the address of store!',
											whitespace: true,
										}
									]}
								>
									<Input type="textarea" />
								</Form.Item>
								<h4 style={{ color: 'red' }}>
									{this.state.errors.address !== undefined ? this.state.errors.address[0] : ''}
								</h4>
							</Col>

						</Row>

						<Form.Item {...this.tailLayout}>
							<div className="edit_button_block">
								<Button onClick={() => this.context.setVisible()} style={{ marginRight: 15 }}>
									<CloseCircleOutlined /> cancel
								</Button>
								<Button type="primary" htmlType="submit">
									<CheckCircleOutlined /> Submit
								</Button>
							</div>
						</Form.Item>

					</Form>
				</Modal>
			</div>
		);
	}
}

export default UpdateStoremodel;
