import React, { Component } from 'react';
import { Button, Modal, Form, Input, Select } from 'antd';
import { EditTwoTone, CheckCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { update_complaint_status_details } from '../../../handler/api_handler'


class UpdateProductmodel extends Component {
	constructor(props) {
		super(props);
		this.formRef = React.createRef();
	}

	state = {
		visible: false
	};
	onFill = () => {
		const product = this.props.product_data;

		if (typeof (product.category) === "object") product.category = "";
		if (this.formRef.current !== null) {
			this.formRef.current.setFieldsValue(product);
		}
	};

	setVisible() {
		this.setState({ visible: !this.state.visible });
	}

	componentDidUpdate(prevProps, prevState) {
		const Settimeout = this.onFill;
		setTimeout(function () {
			Settimeout();
		}, 100);
	}

	onCreate = (value) => {
		update_complaint_status_details(value)
			.then(() => {
				message.success('Updated successfully');
				this.props.setdata();
				this.setVisible();
			})
			.catch(() => { });
	};
	render() {
		return (
			<div>
				<EditTwoTone twoToneColor="#FFA500"
					style={{ fontSize: '20px' }}
					type="primary"
					onClick={() => {
						this.setVisible(true);
					}}
				>
					Update Role
				</EditTwoTone>
				<Modal
					title="Edit Complaint Status"
					visible={this.state.visible}
					footer={null}
					onCancel={this.setVisible.bind(this)}
				>
					<Form className="edit_form"
						ref={this.formRef}
						{...this.layout}
						name="basic"
						initialValues={{ remember: true }}
						onFinish={this.onCreate}
						layout="vertical"
					//   onFinishFailed={this.onFinishFailed}
					>
						<Form.Item name="id" style={{ display: 'none' }}>
							{' '}
							<Input type="hidden" />{' '}
						</Form.Item>

						<Form.Item
							name="name"
							label="Status Name"
							rules={[
								{
									required: true,
									message: 'Please input the complaint status name!'
								}
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item {...this.tailLayout}>
							<div className="edit_button_block">
								<Button type="primary" htmlType="submit">
									<CheckCircleOutlined /> Submit
								</Button>
							</div>

						</Form.Item>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default UpdateProductmodel;
