import React, { useState, useContext } from 'react';
import { Form, DatePicker, Modal, Button, InputNumber, Radio, Input, message, Row, Col } from 'antd';
import { update_particular_user_salary_list, create_particular_user_salary_increment } from '../../handler/api_handler';
import { SalarylistContext } from '../../context/SalarylistContext';
import { EditTwoTone, CheckCircleOutlined } from '@ant-design/icons';

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};


const tailLayout = { wrapperCol: { offset: 8, span: 16 }, };

const SalaryComponent = (props) => {

    const ContextData = useContext(SalarylistContext);
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [RadiobuttonValue, setRadiobuttonValue] = useState(1);
    const [RadiobuttonBoolValue, setRadiobuttonBoolValue] = useState(true);

    const config = {
        rules: [
            {
                type: 'object',
                required: RadiobuttonBoolValue,
                message: 'Please select date!',
            },
        ],
    };

    const onErrorhandle = (response) => {
        const { data, status } = response;
        if (status === 400) {
            if (data.hasOwnProperty("user")) {
                message.error(data.user[0]);
            }else if (data.hasOwnProperty("ot_per_hour")) {
                message.error("OT Salary : "+data.ot_per_hour[0]);
            }
        }
    }

    const onFinish = (fieldsValue) => {
        
        console.log(props ,"ddddd    ");

        const FormData = {
            id: props.record.salary_id,
            user: props.record.id,
            work_hours: fieldsValue.work_hours,
            per_day: fieldsValue.per_day,
            ot_per_hour: fieldsValue.ot_per_hour
        };

        console.log(FormData);

        // return false;


        if (fieldsValue.date !== undefined && fieldsValue.date !== null && fieldsValue.date !== "") {
            FormData.date = fieldsValue['date'].format('YYYY-MM-DD')
        }

        if (RadiobuttonValue === 1) {
            create_particular_user_salary_increment(FormData).then((
            ) => {
                message.success("Added successfully");
                ContextData.setUserData();
                handleCancel();
            }).catch(({ response }) => {
                onErrorhandle(response);
            });
        } else {
            update_particular_user_salary_list(FormData).then((
            ) => {
                message.success("Updated successfully");
                ContextData.setUserData();
                handleCancel();
            }).catch(({ response }) => {
                onErrorhandle(response);
            });
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
        onFill(props.record);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const onFill = (data) => {
        form.setFieldsValue({
            id: data.id,
            work_hours: data.work_hours,
            per_day: data.per_day,
            ot_per_hour: data.ot_per_hour,
        });
    };

    const onChangeRadio = (e) => {
        let RadiobuttonBoolValue = (e.target.value === 1) ? true : false;
        setRadiobuttonBoolValue(RadiobuttonBoolValue);
        setRadiobuttonValue(e.target.value);
    }

    return (
        <React.Fragment>

            <EditTwoTone twoToneColor="#FFA500"
                style={{ fontSize: '20px' }}
                type="primary"
                onClick={showModal}
            >
                Update Salary
                </EditTwoTone>

            <Modal
                title="Salary Alteration"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <p style={{ textAlign: "center" }}>

                    <Radio.Group value={RadiobuttonValue} onChange={onChangeRadio}>
                        <Radio value={1}>
                            INCREMENT
        </Radio>

                        {
                            (props.record.work_hours !== null) ? <Radio value={2}> EDIT </Radio> : ""
                        }



                    </Radio.Group>
                </p>

                <Form form={form} name="time_related_controls" onFinish={onFinish} layout="vertical" className="edit_form">

                    <Form.Item name="id" style={{ display: "none" }} > <Input /> </Form.Item>


                    <Row style={{ marginBottom: '0' }}>
                        <Col span={12}>
                            <Form.Item
                                name="work_hours"
                                label="Working Hours"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input working hours!',
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="per_day"
                                label="Per Day Salary"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the per day salary!',
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '0' }}>
                        <Col span={12}>
                            <Form.Item
                                name="ot_per_hour"
                                label="OT Salary Per Hour"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the ot salary!',
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="date"
                                label="Effective Date"
                                {...config}
                            >
                                <DatePicker />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item {...tailLayout}>
                        <div className="edit_button_block">
                            <Button type="primary" htmlType="submit">
                                <CheckCircleOutlined /> Apply
                            </Button>
                        </div>

                    </Form.Item>

                </Form>
            </Modal>


        </ React.Fragment>


    );
};

export default SalaryComponent;