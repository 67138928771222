import React, { createContext, useState, useEffect } from 'react';
import { get_paymentmode_details } from '../handler/api_handler';
export const PaymentmodeContext = createContext();

const PaymentmodeContextProvider = (props) => {
    const [Paymentmode, setPaymentmode] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);

    useEffect(() => {
        setPaymentmodeData();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setPaymentmodeData = () => {
        get_paymentmode_details().then((details) => {

            setPaymentmode(details.data);

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <PaymentmodeContext.Provider value={{ Paymentmode, loading, setPaymentmodeData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </PaymentmodeContext.Provider>
    );
};

export default PaymentmodeContextProvider;