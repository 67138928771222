import React, { createContext, useState, useEffect } from 'react';
// import { getWrongbills } from '../handler/LocalDB_handler/WrongbillServiceHandler/'
import {getWrongbills, get_role_details, get_store_details, getOrderstatus, get_user_by_store} from '../handler/api_handler';
export const WrongbillContext = createContext();

const WrongbillContextProvider = (props) => {
    const [User, setUser] = useState([]);
    const [Wrongbill, setWrongbill] = useState([]);
    const [Role, setRole] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [Store,setStore] = useState("");
    const [Orderstatus, setOrderstatus] = useState("");

    useEffect(() => {
        setUserData();
        setWrongbillData();
        setRoleData();
        orderStatusData();
        setStoreData();
     
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setWrongbillData = () => {
        getWrongbills().then((details) => {
            setWrongbill(details.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    };

    const setUserData = () => {
        // alert("check context")
        get_user_by_store().then((details) => {
            setUser(details.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    };

    const orderStatusData = () => {
        getOrderstatus().then((details) => {
            setOrderstatus(details.data);
            console.log(details.data);
        }).catch((err) => {
            console.log(err);
        })
    }

    const setRoleData = () => {
        get_role_details().then((details) => {
            console.log('Roles data', details.data);
            setRole(details.data);
            // console.log('Roles', )
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    };

    const setStoreData = ()=>{
        get_store_details().then((details) => {
            setStore(details.data);
            setLoading(false);
        }).catch((err) =>{

        });
    }

    // const getWrongbillsbyid = () => {
    //     getWrongbillsbyId().then((details) => {
    //         console.log(details);
    //     }).catch((err) => {
    //         console.log(err);
    //     })
    // }


    
    return (
        <WrongbillContext.Provider value={{ User, setUserData, Wrongbill, Orderstatus, setWrongbillData, Role, loading, setVisibles, visible, updateData, setUpdateData, setStoreData, Store }}>
            {props.children}
        </WrongbillContext.Provider>
    );
};

export default WrongbillContextProvider;