import React, { createContext, useState, useEffect } from 'react';
// import { getUsers } from '../handler/LocalDB_handler/UserServiceHandler/'
import { getUsers, get_role_details, get_store_details } from '../handler/api_handler';
export const SalarylistContext = createContext();

const SalarylistContextProvider = (props) => {
    const [User, setUser] = useState([]);
    const [Role, setRole] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [Store, setStore] = useState("");
    const [Branch,setBranch] = useState([]);
    const [BranchId,setBranchId] = useState(0);

    useEffect(() => {
        setRoleData();
        setStoreData();
        setBranchData();
    }, [])

    useEffect(() => {
        if(BranchId !== ""){
            setUserData();
        }
    },[BranchId]);


    const setBranchData = () => {
        get_store_details().then((details) => {
            if(details.data.length !== 0){
                setBranch(details.data);
            }
        }).catch((err) => {

        })
    }

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setUserData = () => {
        getUsers(BranchId).then((details) => {
            setUser(details.data);
            setLoading(false);
        }).catch((err) => {
        })
    };

    const setRoleData = () => {
        get_role_details().then((details) => {
            setRole(details.data);
            setLoading(false);
        }).catch((err) => {
            
        })
    };

    const setStoreData = () => {
        get_store_details().then((details) => {
            setStore(details.data);
            setLoading(false);
        }).catch((err) => {

        });
    }

    const onBranchChange = (ChangedBranchId) => {
        setBranchId(ChangedBranchId);
    }

    const CreateSalaryDetail= (data) => {

    }

    return (
        <SalarylistContext.Provider value={{
            User, 
            setUserData, 
            Role, loading, 
            setVisibles, visible, updateData, 
            setUpdateData, setStoreData, Store,
            Branch,
            onBranchChange,
            BranchId,
            CreateSalaryDetail
        }}>
            {props.children}
        </SalarylistContext.Provider>
    );
};

export default SalarylistContextProvider;