import db from './DBSetup'
import { Update_notice } from './updatenotice_handler'
export function createCollections(name, value) {

    console.log(name,"name")
    const updated_data = Update_notice(value);
    db.collection(name).delete()

    updated_data.forEach(function (val, index) {
        db.collection(name).add(val)
    })
}