import React, { useState, useEffect } from 'react';
import { DatePicker, Select } from 'antd';
import { get_store_details, admin_branch_specific_oil_consumption_list } from '../../handler/api_handler';
import Oilconsumptionproduct from "./Oilconsumptionproduct";
import moment from "moment";
import { notEmpty } from "../../handler/helpers";

function Oilconsumption(props) {
    //Data
    const Dateformat = "YYYY-MM-DD";
    const Yesterday = moment().subtract(1, 'days').format(Dateformat);

    //State management
    const [SelectedDate, setSelectedDate] = useState(Yesterday);
    const [SelectedBranch, setSelectedBranch] = useState("");
    const [BranchList, setBranchList] = useState([]);
    const [Listing, setListing] = useState([]);
    const [loading, setloading] = useState(false);

    // Component
    const { Option } = Select;

    // Method
    const onDatechange = (value, event) => {
        let date = moment(value).format(Dateformat);
        setSelectedDate(date);
    }

    const Oilwasteapicall = (date, branch) => {
        setloading(true);
        admin_branch_specific_oil_consumption_list(date,branch).then(({ data }) => {
            setListing(data);
            setloading(false);
        }).catch(() => {
            setloading(false);
        });
    }

    const onBranchchange = (branchid) => {
        setSelectedBranch(branchid)
    }

    //Hooks
    useEffect(() => {
        get_store_details().then(({ data }) => {
            setBranchList(data);
            setSelectedBranch(data[0].id);
        }).catch((err) => {

        });
    }, []);

    useEffect(() => {
        if (notEmpty(SelectedBranch)) {
            Oilwasteapicall(SelectedDate, SelectedBranch)
        }
    }, [SelectedDate, SelectedBranch]);

    return (
        <React.Fragment>
            <div className="main_top">
                <h2>Oil consumption</h2> 
                <div className="bottom_border"></div>
            </div>

            <DatePicker
                defaultValue={moment(SelectedDate, Dateformat)} format={Dateformat}
                onChange={(value, event) => onDatechange(value, event)}
            />

            <Select
                value={SelectedBranch}
                showSearch
                style={{ width: 150, margin: 20 }}
                onChange={onBranchchange}
            >
                <Option value="">Select Branch</Option>
                {BranchList.map((item) => <Option value={item.id}>{item.name}</Option>)}
            </Select>


            <Oilconsumptionproduct Listing={Listing} style={{ marginTop: 50 }} />
        </React.Fragment>
    );
}

export default Oilconsumption;