import React, { useContext } from 'react';
import { SalaryreportlistContext } from '../../../context/SalaryreportlistContext';
import { DatePicker } from 'antd';
import moment from 'moment';

function DatepickerComponent(props) {
    const SalaryBreakReport = useContext(SalaryreportlistContext);
    const monthFormat = 'YYYY-MM';

    const onDaterangefilter = (momentDate, dateString) => {
        SalaryBreakReport.onchangeMonthpicker(dateString);
    }

    return (
        <React.Fragment>
            <DatePicker defaultValue={moment(props.defaultValue, monthFormat)} format={monthFormat} picker="month" onChange={onDaterangefilter} />
        </React.Fragment>
    );
}

export default DatepickerComponent;