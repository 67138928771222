import React from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import IndexLayout from './components/layout/';

// const Notautherize = React.lazy(() => import ("./components/Httpresponsepages/Notautherize"));

class Routecomponent extends React.Component {
	state = {
		key: ' ',
		subkey: ''
	};

	componentWillMount() {
		[ '/' ].includes(this.props.location.pathname) &&
			'Auth-Token' in localStorage &&
			localStorage.getItem('Auth-Token') !== null &&
			localStorage.getItem('Auth-Token') !== '' &&
			this.props.history.push('/dashboard');
	}

	componentDidMount() {
		document.title = 'TSIC | ' + this.props.title;
	}
	handleClick = (key) => {
		// alert(key)
		this.setState({ key: key });
		this.setState({ subkey: key });
	};
	render() {
		const isAuthenticated =
			'Auth-Token' in localStorage &&
			localStorage.getItem('Auth-Token') !== null &&
			localStorage.getItem('Auth-Token') !== '';
		const Propscomponent = this.props.component;
		const Provider = this.props.provider;
		const userDetails = JSON.parse(localStorage.getItem('Auth'));
		return this.props.isprivate === true ? isAuthenticated ? (
			<Provider>
				<IndexLayout keys={this.state.key} subkey={this.state.subkey} user={userDetails} menuSelector={this.handleClick}>
					<Propscomponent {...this.props} />
				</IndexLayout>
			</Provider>
		) : (
			<Redirect to="/" />
		) : (
			<Propscomponent {...this.props} />
		);
	}
}

// Set default props
Routecomponent.defaultProps = {
	title: 'Application',
	isprivate: false
};
// console.log(Routecomponent)

export default withRouter(Routecomponent);
