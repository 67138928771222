import React, { useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { post_complaint_status_details } from '../../../handler/api_handler';

import { message } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

const Productmodel = ({ visible, onCreate, onCancel, category, type, unit }) => {
	const [form] = Form.useForm();
	return (
		<Modal
			visible={visible}
			title="Create Complaint Status"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form form={form} name="form_in_modal"  className="edit_form" layout="vertical">
				<Form.Item
					name="name"
					label="Status Name"
					rules={[
						{
							required: true,
							message: 'Please input the complaint status name!',
							whitespace: true,
						}
					]}
				>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const AddProductmodel = (props) => {
	const [visible, setVisible] = useState(false);
	const onCreate = (values) => {
		values.status = 1;
		post_complaint_status_details(values)
			.then(() => {
				message.success('Added successfully');
				props.setdata();
			})
			.catch((err) => { });
		setVisible(false);
	};

	return (
		<div className="main_top">
			<h2>Complaint Status</h2>
			<div className="bottom_border"></div>

			{/* <Button className="addbtn" style={{ marginTop: 0, marginRight: '0' }}
				type="primary"
				onClick={() => {
					setVisible(true);
				}}
			><PlusOutlined />
				Add Status
			</Button> */}

			<Productmodel
				category={props.category}
				unit={props.unit}
				type={props.type}
				visible={visible}
				onCreate={onCreate}
				onCancel={() => {
					setVisible(false);
				}}
			/>
		</div>
	);
};

export default AddProductmodel;
