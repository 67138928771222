import React, { createContext, useState, useEffect } from 'react';
import {  get_role_details, get_store_details,salary_report_listing , salary_user_report } from '../handler/api_handler';
export const SalaryreportlistContext = createContext();

const SalaryreportlistContextProvider = (props) => {
    
    const [Listing, setListing] = useState([]);

    const [Role, setRole] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [Store, setStore] = useState("");
    const [Branch,setBranch] = useState([]);
    const [BranchId,setBranchId] = useState(0);
    const [previousMonthsearch,setpreviousMonthsearch] = useState('2021-02');
    const [MonthText,setMonthText] = useState('');
    const [MonthArray,setMonthArray] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);

    useEffect(() => {
        setRoleData();
        setStoreData();
        setBranchData();
    }, [])

    useEffect(() => {
        if(BranchId !== ""){
            setListingData(previousMonthsearch);
        }
    },[BranchId]);


    const setBranchData = () => {
        get_store_details().then((details) => {
            if(details.data.length !== 0){
                setBranch(details.data);
            }
        }).catch((err) => {

        })
    }

    const setVisibles = () => {
        setVisible(!visible);
    }

    const queryParameter = (date) => {
        console.log('onChange',date);
        let Baseurl = 'salary_report/'+BranchId+'/';
        let Query = '';
        if(date !== undefined){
            let explodeData = date.split('-');
            let monthIndex = parseInt(explodeData[1]) - 1;
            setMonthText(MonthArray[monthIndex]);
            Query = `?month=${explodeData[1]}&year=${explodeData[0]}`;
        }
        return Baseurl+Query;
    }

    const setListingData = (date) => {
        let Endurl = queryParameter(date);
        salary_report_listing(Endurl).then(({ data }) => {
            setListing(data);
            let monthIndex = parseInt(data.month) - 1;
            let Text = MonthArray[monthIndex];
            let SearchMonth = data.year+'-'+data.month;
            setpreviousMonthsearch(SearchMonth);
            setMonthText(Text);
        }).catch(err => {
            
        });
    };

    const onchangeMonthpicker = (date) => {

        console.log('onChange',date);
        setListingData(date);
    }

    const setRoleData = () => {
        get_role_details().then((details) => {
            setRole(details.data);
            setLoading(false);
        }).catch((err) => {
        })
    };

    const setStoreData = () => {
        get_store_details().then((details) => {
            setStore(details.data);
            setLoading(false);
        }).catch((err) => {
        });
    }

    const onBranchChange = (ChangedBranchId) => {
        setBranchId(ChangedBranchId);
    }

    const CreateSalaryDetail= (data) => {

    }


    return (
        <SalaryreportlistContext.Provider value={{
            previousMonthsearch,
            setpreviousMonthsearch,
            Listing,
            Role, 
            loading,
            setVisibles, 
            visible, 
            updateData, 
            setUpdateData, 
            setStoreData, 
            Store,
            Branch,
            onBranchChange,
            BranchId,
            CreateSalaryDetail,
            MonthText,
            setListingData,
            onchangeMonthpicker
        }}>
            {props.children}
        </SalaryreportlistContext.Provider>
    );
};

export default SalaryreportlistContextProvider;