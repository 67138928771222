import React, { createContext, useState, useEffect } from 'react';
import { get_store_product_api } from '../handler/api_handler';
export const StoreProductContext = createContext();

const StoreProductContextProvider = (props) => {
    const [StoreProduct, setStoreProduct] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [classification, setClassification] = useState(0);

    useEffect(() => {
        setStoreProductData(classification);
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }
    const changeClassification = (data) => {
        setClassification(data);
        setStoreProductData(data);
    }

    const setStoreProductData = (classification) => {
        setLoading(true);
        get_store_product_api(classification).then((details) => {
            setStoreProduct(details.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <StoreProductContext.Provider value={{ classification,changeClassification, StoreProduct, loading, setStoreProductData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </StoreProductContext.Provider>
    );
};

export default StoreProductContextProvider;