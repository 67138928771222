import React, { useState,Component } from 'react';
import { Button, Modal, Form, Input, Radio, Row, Col } from 'antd';
import {CheckCircleOutlined, EditTwoTone} from '@ant-design/icons';
// import {updateCategorys} from '../../../handler/LocalDB_handler/CategoryServiceHandler'
import {update_classification_list_details} from '../../../handler/api_handler';
import { message } from 'antd';

  

class UpdateCategorymodel  extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
       
      }


       state = {
         visible: false
      }
    onFill = () => {
        delete this.props.Category_data.updated
        delete this.props.Category_data.created

        if(this.formRef.current !== null) {
            this.formRef.current.setFieldsValue(this.props.Category_data);
        }
    };

    setVisible(){
        this.setState({visible:!this.state.visible})
    }
    
  
    componentDidUpdate(prevProps, prevState){
        const Settimeout = this.onFill;
        setTimeout(function(){  Settimeout() },100);
    }

    onCreate=(value)=>{
      update_classification_list_details(value).then((

      )=>{
        message.success("Updated successfully");
        this.props.setdata();
        this.setVisible();
      }).catch(()=>{});
    }
    render() { 
        return (  
            <div>
      <EditTwoTone twoToneColor="#FFA500"
      style={{ fontSize: '20px' }}
        type="primary"
        onClick={() => {
         
          this.setVisible(true);
        }}
      >
       Update classification
      </EditTwoTone>
      <Modal
          title="Edit classification"
          visible={this.state.visible}
          footer={null}
          onCancel={this.setVisible.bind(this)}
        >
          <Form ref={this.formRef} className="edit_form" layout="vertical"

      {...this.layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={this.onCreate}
    //   onFinishFailed={this.onFinishFailed}
    >
              <Form.Item name="id" style={{ display:"none" }} > <Input type="hidden"/> </Form.Item>


              <Row>
                  <Col span={12}>
                      <Form.Item
                          name="name"
                          label="Name"
                          rules={[
                              {
                                  required: true,
                                  message: 'Please input the name of Category!',
                                  whitespace : true,
                              },
                          ]}
                      >
                          <Input />
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item
                          name="description"
                          label="description"
                          rules={[
                              {
                                  whitespace : true,
                              }
                          ]}
                      >
                          <Input />
                      </Form.Item>
                  </Col>
              </Row>

      




        {/* <Form.Item
          name="code"
          label="code"
          rules={[
            {
              required: true,
              message: 'Please input the code of Category!',
            },
          ]}
        >
          <Input />
        </Form.Item> */}
       

        <Form.Item {...this.tailLayout}>
            <div className="edit_button_block">
                <Button type="primary" htmlType="submit">
                    <CheckCircleOutlined /> Submit
                </Button>
            </div>
      </Form.Item>
    </Form>
        </Modal>
     
    </div>
        );
    }
}
 
export default  UpdateCategorymodel;





