import React, { createContext, useState, useEffect } from 'react';
import {get_gst_details} from '../handler/api_handler';
export const GstContext = createContext();

const GstContextProvider = (props) => {
    const [Gst, setGst] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);

    useEffect(() => {
        setGstData();
    }, [])

    const setVisibles = () => {
        setVisible(!visible);
    }

    const setGstData = () => {
        get_gst_details().then((details) => {
           
            // setGst(details.length === undefined ?[details] :details);
            setGst(details.data);

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <GstContext.Provider value={{ Gst, loading, setGstData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </GstContext.Provider>
    );
};

export default GstContextProvider;