import React, { useContext } from 'react';
import { DatePicker, Select, Tabs, Spin } from 'antd';
import moment from "moment";
import Cashdetailstable from "./Cashdetailstable";
import Pettycash from './Pettycash';
import Denomination from './Denomination';
import Branchcashmanagement from './Branchcashmanagement';

import { Admincashdetailscontext } from "../../../src//context/Admincashdetailscontext";

function Cashdetails(props) {

    const Billcontext = useContext(Admincashdetailscontext);
    const { Option } = Select;
    const { TabPane } = Tabs;

    return (
        <React.Fragment>
            <div className="main_top">
                <h2>Cash Details</h2>
                <div className="bottom_border"></div>
            </div>

            <DatePicker
                defaultValue={moment(Billcontext.SelectedDate, Billcontext.Dateformat)} format={Billcontext.Dateformat}
                onChange={(value, event) => Billcontext.onDatechange(value, event)}
            />

            <Select
                value={Billcontext.SelectedBranch}
                showSearch
                style={{ width: 150, marginLeft: 20 }}
                onChange={Billcontext.onBranchchange}
            >
                <Option value="">Select Branch</Option>
                {Billcontext.BranchList.map((item) => <Option value={item.id}>{item.name}</Option>)}
            </Select>

            <Tabs defaultActiveKey={Billcontext.SelectedTab} onChange={Billcontext.onTabchange} className="Tab_dailysheet">
                <TabPane tab="Credit Sales" key="2"></TabPane>
                <TabPane tab="Credit settlement" key="3"></TabPane>
                <TabPane tab="Bank Cash" key="5"></TabPane>
                <TabPane tab="Cash handover" key="6"></TabPane>
                <TabPane tab="Petty Cash" key="1"></TabPane>
                <TabPane tab="Denomination" key="4"></TabPane>
                <TabPane tab="Branch Cash Management" key="7"></TabPane>
            </Tabs>

            <Spin spinning={Billcontext.apiloading} >
                {
                    Billcontext.SelectedTab === "1"
                        ? <Pettycash data={Billcontext.Objectdata} />
                        : Billcontext.SelectedTab === "4" ?
                            <Denomination data={Billcontext.Objectdata} />
                            : Billcontext.SelectedTab === "7" ?
                                <Branchcashmanagement data={Billcontext.Objectdata} />
                                : <Cashdetailstable column={Billcontext.Selectedcolumn} data={Billcontext.Tabledata} />
                }
            </Spin>

        </React.Fragment>
    );
}

export default Cashdetails;