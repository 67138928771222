import React, { Component } from 'react';
import { SearchOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin, Skeleton } from 'antd';
import Highlighter from 'react-highlight-words';
import { RoleContext } from '../../context/Rolecontext';
import AddRolemodels from '../Role/AddRoleModel/';
// import {deleteRoles} from '../../handler/LocalDB_handler/RoleServiceHandler'
import { delete_role_details } from '../../handler/api_handler';
import Updatemodelcomponent from '../../components/Role/UpdateRoleModel';
import { ReloadOutlined } from '@ant-design/icons';

class Rolecomponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};
	static contextType = RoleContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
					>
						Search
					</Button>
					<Button type="danger" onClick={() => this.handleReset(clearFilters)}>
						<ReloadOutlined />
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
					text
				)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		delete_role_details(id)
			.then(() => {
				this.context.setRoleData();
			})
			.catch(() => { });
	};

	render() {
		const { Role, loading, setRoleData, setVisibles, visible } = this.context;
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',

				// ...this.getColumnSearchProps('name')
			},
			{
				title: 'description',
				dataIndex: 'description',
				key: 'description',

				// ...this.getColumnSearchProps('description')
			}

			// {
			// 	title: 'Action',
			// 	dataIndex: 'action',
			// 	key: 'id',
			// 	render: (text, record) => (
			// 		<Space>
			// 			<Updatemodelcomponent role_data={record} setdata={setRoleData} />
			// 			<Popconfirm
			// 				title="Are you sure？"
			// 				onConfirm={() => this.handleOk(record.id)}
			// 				okText="Yes"
			// 				cancelText="No"
			// 			>
			// 				<DeleteTwoTone twoToneColor="#d11a2a" style={{ fontSize: '20px' }} />
			// 			</Popconfirm>
			// 		</Space>
			// 	)
			// }
		];
		return (
			<React.Fragment>
				<div className="main_top">
					<h2>Role List</h2>
					<div className="bottom_border"></div>
				</div>
				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={Role} pagination={false} bordered style={{width: '500px'}} />
				</Spin>

			</React.Fragment>
		);
	}
}

export default Rolecomponent;
