import React, { Component } from 'react';
import { SearchOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin, Skeleton } from 'antd';
import Highlighter from 'react-highlight-words';
import { PaymentmodeContext } from '../../context/Paymentmodecontext';
// import AddPaymentmodemodels from '../Paymentmode/AddPaymentmodeModel/';
// import {deletePaymentmodes} from '../../handler/LocalDB_handler/PaymentmodeServiceHandler'
import { delete_role_details } from '../../handler/api_handler';
// import Updatemodelcomponent from '../../components/Paymentmode/UpdatePaymentmodeModel';
import { ReloadOutlined } from '@ant-design/icons';

class Paymentmodecomponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};
	static contextType = PaymentmodeContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [ e.target.value ] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
					>
						Search
					</Button>
					<Button type="danger" onClick={() => this.handleReset(clearFilters)}>
						<ReloadOutlined />
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[ this.state.searchText ]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleOk = (id) => {
		delete_role_details(id)
			.then(() => {
				this.context.setPaymentmodeData();
			})
			.catch(() => {});
	};

	render() {
		const { Paymentmode, loading, setPaymentmodeData, setVisibles, visible } = this.context;
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				width: '30%'
			},
			{
				title: 'description',
				dataIndex: 'description',
				key: 'description',
				width: '30%'
			}

		];
		return (
			<React.Fragment>
				<div className="main_top">
					<h2>Payment Mode</h2>
					<div className="bottom_border"></div>
				</div>
				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={Paymentmode} pagination={false} bordered style={{width: '500px'}} />
				</Spin>
			</React.Fragment>
		);
	}
}

export default Paymentmodecomponent;
