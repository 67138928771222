import Dashboard from "./components/dashboard";
import StoreComponent from "./components/Store/StoreComponent";
import RoleComponent from "./components/Role/RoleComponent";
import Bulkorder from "./components/Bulkorder/BulkorderComponent";
import Freebill from "./components/Freebill/FreebillComponent";
import Wrongbill from "./components/Wrongbill/WrongbillComponent";
import ProductComponent from "./components/product/ProductComponent";
import MappingComponent from "./components/ProductMapping/Productmappingcomponent";
import ProductpriceComponent from "./components/ProductPrice/ProductPriceComponent";
import Complaintcomponent from "./components/Complaint/ComplaintComponent";
import CategoryComponent from "./components/Category/CategoryComponent";
// import UserComponent from "./components/Users/UserComponent";
import BranchemployeesComponent from "./components/Branchemployees/BranchemployeesComponent";
import OfficeusersComponent from "./components/Officeusers/OfficeusersComponent";
import UnitComponent from "./components/Units/UnitComponent";
import TypeComponent from "./components/Type/TypeComponent";
import BranchComponent from "./components/Branch/Branchcomponent";
import AttendanceComponent from "./components/Attendance/AttendanceComponent";
import AttendanceReportComponent from "./components/AttendanceReport/AttendanceReportComponent";
import Freebillreport from "./components/Freebillreport/Freebillreport";
import GstComponent from "./components/GST/GstComponent";
import Paymentmodecomponent from "./components/Paymentmode/Paymentcomponent";
import ComplaintStatusComponent from "./components/Complaintstatus/ComplaintStatusComponent";
import ComplaintCategoryComponent from "./components/ComplaintCategory/ComplaintCategoryComponent";
import IncentiveMasterComponent from "./components/IncentiveMaster/IncentiveMasterComponent";
import Subrolecomponent from "./components/Subrole/Subrolecomponent";
import ExpensescategoryComponent from "./components/Expensescategory/Expensescategorycomponent";
import Rawproductcomponent from "./components/Rawproduct/Rawproduct";
import OperationalProductComponent from "./components/Operationalproduct/OperationalProductComponent";
import SalesproductComponent from "./components/Salespeoduct/SalesproductComponent";
import SalaryComponent from "./components/SalaryDetails/SalaryComponent";
import SalaryReportComponent from "./components/SalaryDetails/Report/SalaryReportComponent";
import DailysheetComponent from "./components/DailySheet/";
import Vendorcomponent from "./components/Vendor/Vendorcomponent";
import StoreproductComponent from "./components/storeProduct/StoreProduct";
import Menucomponent from "./components/Menucomponent";
import VegListingcomponent from "./components/Vegproduct/Listingcomponent";
import InventoryComponent from "./components/Inventory/InventoryComponent";
import Inventorylistcomponent from "./components/Inventory/Inventorylistcomponent";
import VegInventoryComponent from "./components/VegInventory/InventoryComponent";
import VegInventorylistcomponent from "./components/VegInventory/Inventorylistcomponent";
import AdminDailysheetProductlist from "./components/AdminDailysheet/Productlist";
import AdminDailysheetBilllist from "./components/AdminDailysheet/Billlist";
import Ebmeter from "./components/EbMeter/EbmeterComponent";
import Departmentsalescount from "./components/AdminDailysheet/deptsalescountlist";
import AdminDailysheetCashdetails from "./components/AdminDailysheet/Cashdetails";
import customerlistcomponent from "./components/Customer/customerlistcomponent";
import AdminDailysheetfoodwastage from "./components/AdminDailysheet/Foodwastage";
import AdminDailysheetoilconsumption from "./components/AdminDailysheet/Oilconsumption";
import Creditcustomercomponent from "./components/Creditcustomer/Creditcustomercomponent";

// Context
import DashboardContext from "./context/Dashboardcontext";
import StoreContext from "./context/Storecontext";
import RoleContext from "./context/Rolecontext";
import ProductContext from "./context/Productcontext";
import ProductMappingContext from "./context/Mappingproductcontext";
import ProductPriceContext from "./context/Productpricecontext";
import ComplaintContext from "./context/Complaintcontext";
import CategoryContext from "./context/Categorycontext";
import Usercontext from "./context/Usercontext";
import Unitcontext from "./context/Unitcontext";
import Typecontext from "./context/Typecontext";
import BranchContext from "./context/Branchcontext";
import AttendanceContext from "./context/Attendancecontext";
import BulkorderContext from "./context/Bulkordercontext";
import FreebillContext from "./context/Freebillcontext";
import WrongbillContext from "./context/Wrongbillcontext";
import AttendanceReportContext from "./context/Attendancereportcontext";
import Gstcontext from "./context/Gstcontext";
import Paymentmodecontext from "./context/Paymentmodecontext";
import ComplaintStatusContext from "./context/ComplaintStatuscontext";
import ComplaintCategoryContext from "./context/ComplaintCategoryContext";
import IncentiveMasterContext from "./context/IncentiveMasterContext";
import Subrolecontext from "./context/Subrolecontext";
import Expensescategorycontext from "./context/Expensescategorycontext";
import Rawproductcontext from "./context/Rawproductcontext";
import Operationalproductcontext from "./context/Operationalproductcontext";
import Salespeoductcontext from "./context/Salseproductcontext";
import SalarylistContext from "./context/SalarylistContext";
import SalaryreportlistContext from "./context/SalaryreportlistContext";
import Officeuserscontext from "./context/Officeuserscontext";
import Branchemployeescontext from "./context/Branchemployeescontext";
import Dailysheetcontext from "./context/Dailysheetcontext";
import Vendorcontext from "./context/Vendorcontext";
import VegitableproductContext from "./context/Vegitableproductcontext";
import Storeproductcontext from "./context/StoreProductcontext";
import InventoryContext from "./context/InventoryContext";
import InventoryListContext from "./context/InventoryListContext";
import VegInventoryContext from "./context/VegInventoryContext";
import VegInventoryListContext from "./context/VegInventoryListContext";
import Admindailysheetcontext from "./context/Admindailysheetcontext";
import EbMetercontext from "./context/Ebmetercontext";
import Departmentsalsecountcontext from "./context/DeptsalescountContext";
import Admindailysheetbillcontext from "./context/Admindailysheetbillcontext";
import Admincashdetailscontext from "./context/Admincashdetailscontext";
import CustomerListContext from "./context/CustomerListContext";
import Adminfoodwastagecontext from "./context/Adminfoodwastagecontext";
import Adminoilconsumptioncontext from "./context/Adminoilconsumptioncontext";
import Creditcustomercontext from "./context/Creditcustomercontext";

const routes = [
  {
    component: Dashboard,
    path: "/dashboard",
    subMenu: false,
    title: "Dashboard",
    key: "1",
    showmenucomponent: true,
    incharge: false,
    admin: true,
    provider: DashboardContext,
  },
  {
    path: "/Bill",
    title: "Bill",
    key: "4",
    subMenu: true,
    incharge: false,
    admin: true,
    showmenucomponent: false,
    submenuOptions: [
      {
        component: Bulkorder,
        path: "/bulkorder",
        subMenu: false,
        title: "Bulk Order",
        key: "5",
        showmenucomponent: false,
        provider: BulkorderContext,
      },
      {
        component: Wrongbill,
        path: "/wrongbill",
        subMenu: false,
        title: "Wrongbill",
        key: "7",
        showmenucomponent: true,
        provider: WrongbillContext,
      },
      {
        component: Freebill,
        path: "/freebill",
        subMenu: false,
        title: "Free Bills",
        key: "8",
        showmenucomponent: true,
        provider: FreebillContext,
      },
    ],
  },
  {
    component: OfficeusersComponent,
    path: "/officeusers",
    subMenu: false,
    title: "Office users",
    incharge: false,
    admin: true,
    key: "9",
    showmenucomponent: false,
    provider: Officeuserscontext,
  },
  {
    component: BranchemployeesComponent,
    path: "/branchemployees",
    subMenu: false,
    title: "Branch employees",
    incharge: false,
    admin: true,
    key: "10",
    showmenucomponent: false,
    provider: Branchemployeescontext,
  },

  {
    component: MappingComponent,
    path: "/product_mapping/",
    subMenu: false,
    title: "Product Mapping",
    key: "11",
    incharge: true,
    admin: false,
    showmenucomponent: true,
    provider: ProductMappingContext,
  },
  {
    path: "/master",
    title: "Master",
    key: "MasterSection",
    subMenu: true,
    incharge: false,
    admin: true,
    key: "12",
    showmenucomponent: true,
    submenuOptions: [
      {
        component: BranchComponent,
        path: "/subbranch/:storeid",
        subMenu: false,
        title: "Branch",
        key: "13",
        incharge: false,
        admin: true,
        showmenucomponent: false,
        provider: BranchContext,
      },
      {
        component: Subrolecomponent,
        path: "/subrole",
        subMenu: false,
        title: "Sub Role",
        key: "14",
        incharge: false,
        admin: true,
        showmenucomponent: true,
        provider: Subrolecontext,
      },
      {
        component: RoleComponent,
        path: "/Role",
        subMenu: false,
        title: "Role",
        key: "15",
        incharge: false,
        admin: true,
        showmenucomponent: true,
        provider: RoleContext,
      },
      {
        component: GstComponent,
        path: "/gst",
        subMenu: false,
        title: "GST",
        key: "16",
        showmenucomponent: true,
        incharge: false,
        admin: true,
        provider: Gstcontext,
      },
      {
        component: StoreComponent,
        path: "/branch",
        subMenu: false,
        title: "Branch",
        key: "17",
        incharge: false,
        admin: true,
        showmenucomponent: true,
        provider: StoreContext,
      },
      {
        component: Vendorcomponent,
        path: "/vendorlist",
        subMenu: false,
        title: "Vendor",
        key: "vendorlist",
        incharge: true,
        admin: true,
        showmenucomponent: true,
        provider: Vendorcontext,
      },
      {
        component: Paymentmodecomponent,
        path: "/paymentmode",
        subMenu: false,
        title: "Payment Mode",
        key: "18",
        showmenucomponent: true,
        incharge: false,
        admin: true,
        provider: Paymentmodecontext,
      },
      {
        component: ComplaintStatusComponent,
        path: "/complaintstatus",
        subMenu: false,
        title: "Complaint Status",
        key: "19",
        incharge: false,
        admin: true,
        showmenucomponent: true,
        provider: ComplaintStatusContext,
      },
      {
        component: ComplaintCategoryComponent,
        path: "/complaintcategory",
        subMenu: false,
        title: "Complaint Category",
        key: "ComplaintCategory",
        admin: true,
        key: "20",
        incharge: false,
        showmenucomponent: true,
        provider: ComplaintCategoryContext,
      },
      {
        component: ExpensescategoryComponent,
        path: "/expensescategory",
        subMenu: false,
        title: "Expenses Category",
        key: "Expenses Category",
        incharge: false,
        admin: true,
        key: "21",
        showmenucomponent: true,
        provider: Expensescategorycontext,
      },
      {
        component: CategoryComponent,
        path: "/clasification",
        subMenu: false,
        title: "Product Clasification",
        key: "22",
        showmenucomponent: true,
        provider: CategoryContext,
      },
      {
        component: UnitComponent,
        path: "/unit",
        subMenu: false,
        title: "Product Unit",
        key: "23",
        showmenucomponent: true,
        provider: Unitcontext,
      },
      {
        component: TypeComponent,
        path: "/department",
        subMenu: false,
        title: "Product Department",
        key: "24",
        showmenucomponent: true,
        provider: Typecontext,
      },
      {
        component: IncentiveMasterComponent,
        path: "/incentivemaster",
        subMenu: false,
        title: "Incentive Master",
        key: "25",
        admin: true,
        admin: true,
        showmenucomponent: true,
        provider: IncentiveMasterContext,
      },
      {
        component: Ebmeter,
        path: "/admin/dailysheet/Ebmeter",
        subMenu: false,
        title: "Eb meter",
        key: "27",
        showmenucomponent: true,
        provider: EbMetercontext,
      },
    ],
  },
  {
    path: "/Product",
    title: "Product",
    key: "26",
    subMenu: true,
    incharge: false,
    admin: true,
    showmenucomponent: true,
    submenuOptions: [
      {
        component: ProductComponent,
        path: "/product",
        subMenu: false,
        title: "Product",
        key: "27",
        showmenucomponent: false,
        provider: ProductContext,
      },
      {
        component: SalesproductComponent,
        path: "/Salesproduct",
        subMenu: false,
        title: "Sales Product",
        key: "28",
        showmenucomponent: true,
        provider: Salespeoductcontext,
      },
      {
        component: Rawproductcomponent,
        path: "/raw_product",
        subMenu: false,
        title: "Raw Material",
        key: "29",
        showmenucomponent: true,
        provider: Rawproductcontext,
      },
      {
        component: OperationalProductComponent,
        path: "/maintenance product",
        subMenu: false,
        title: "Maintenance Product",
        key: "30",
        showmenucomponent: true,
        provider: Operationalproductcontext,
      },
      {
        component: VegListingcomponent,
        path: "/veglisting",
        subMenu: false,
        title: "Veg Listing",
        key: "veglisting",
        showmenucomponent: true,
        provider: VegitableproductContext,
      },
      {
        component: ProductpriceComponent,
        path: "/product_price",
        subMenu: false,
        title: "Product Price",
        key: "31",
        showmenucomponent: false,
        provider: ProductPriceContext,
      },
    ],
  },
  {
    component: Complaintcomponent,
    path: "/complaint",
    subMenu: false,
    title: "Complaint",
    key: "32",
    incharge: false,
    admin: true,
    showmenucomponent: false,
    provider: ComplaintContext,
  },
  {
    component: AttendanceComponent,
    path: "/attendance",
    subMenu: false,
    title: "Attendance",
    key: "33",
    incharge: true,
    admin: false,
    showmenucomponent: true,
    provider: AttendanceContext,
  },
  {
    component: AttendanceReportComponent,
    path: "/attendance_report",
    subMenu: false,
    title: "Attendance Report",
    key: "34",
    incharge: false,
    admin: true,
    showmenucomponent: true,
    provider: AttendanceReportContext,
  },
  {
    component: Freebillreport,
    path: "/freebill_report",
    subMenu: false,
    title: "Freebill Report",
    key: "35",
    incharge: false,
    admin: false,
    showmenucomponent: false,
    provider: FreebillContext,
  },
  {
    path: "/staff",
    title: "Staff",
    subMenu: true,
    incharge: false,
    admin: true,
    key: "36",
    showmenucomponent: true,
    submenuOptions: [
      {
        component: OfficeusersComponent,
        path: "/officeusers",
        subMenu: false,
        title: "Office users",
        incharge: false,
        admin: true,
        key: "37",
        showmenucomponent: true,
        provider: Officeuserscontext,
      },
      {
        component: BranchemployeesComponent,
        path: "/branchemployees",
        subMenu: false,
        title: "Branch employees",
        incharge: false,
        admin: true,
        key: "38",
        showmenucomponent: true,
        provider: Branchemployeescontext,
      },
      {
        component: SalaryComponent,
        path: "/salarylist",
        subMenu: false,
        admin: true,
        key: "39",
        title: "Assign Salary",
        showmenucomponent: true,
        provider: SalarylistContext,
      },
      {
        component: SalaryReportComponent,
        path: "/staffsalaryreport",
        subMenu: false,
        admin: true,
        key: "40",
        title: "Staff Salary Report",
        showmenucomponent: true,
        provider: SalaryreportlistContext,
      },
    ],
  },

  {
    component: StoreproductComponent,
    path: "/store_product",
    subMenu: false,
    admin: false,
    key: "41",
    title: "Store Product",
    incharge: true,
    showmenucomponent: true,
    provider: Storeproductcontext,
  },
  {
    component: DailysheetComponent,
    path: "/dailysheet",
    subMenu: false,
    admin: false,
    incharge: true,
    key: "42",
    title: "Daily Sheet",
    showmenucomponent: true,
    provider: Dailysheetcontext,
  },
  {
    component: InventoryComponent,
    path: "/dailyinward/add",
    subMenu: false,
    admin: false,
    key: "inventorycomponent",
    title: "Daily-Inward Add",
    incharge: true,
    showmenucomponent: false,
    provider: InventoryContext,
  },
  {
    component: Inventorylistcomponent,
    path: "/dailyinward/list",
    subMenu: false,
    admin: false,
    key: "inventorycomponent",
    title: "Daily-inward List",
    incharge: true,
    showmenucomponent: true,
    provider: InventoryListContext,
  },
  // {
  //     component: customerlistcomponent,
  //     path: "/customer/list",
  //     subMenu: false,
  //     admin: false,
  //     key: "/customer/list",
  //     title: "Customer List",
  //     incharge: true,
  //     showmenucomponent: true,
  //     provider: CustomerListContext
  // },
  {
    component: VegInventoryComponent,
    path: "/veginward/add",
    subMenu: false,
    admin: false,
    key: "inventorycomponent",
    title: "Veg-Inward Add",
    incharge: true,
    showmenucomponent: false,
    provider: VegInventoryContext,
  },
  {
    component: VegInventorylistcomponent,
    path: "/veginward/list",
    subMenu: false,
    admin: false,
    key: "veginventorycomponent",
    title: "Veg-Inward List",
    incharge: true,
    showmenucomponent: true,
    provider: VegInventoryListContext,
  },
  {
    component: Creditcustomercomponent,
    path: "/customer/list",
    title: "customer list",
    key: "/customer/list",
    subMenu: false,
    incharge: true,
    admin: false,
    showmenucomponent: true,
    provider: Creditcustomercontext,
  },
  {
    path: "/admin/dailysheet",
    title: "Daily Sheet",
    key: "admin/dailysheet",
    subMenu: true,
    incharge: false,
    admin: true,
    showmenucomponent: true,
    submenuOptions: [
      {
        component: AdminDailysheetProductlist,
        path: "/admin/dailysheet/product",
        subMenu: false,
        title: "Product",
        key: "/admin/dailysheet/product",
        showmenucomponent: true,
        provider: Admindailysheetcontext,
      },
      {
        component: AdminDailysheetBilllist,
        path: "/admin/dailysheet/bill",
        subMenu: false,
        title: "Bill",
        key: "/admin/dailysheet/bill",
        showmenucomponent: true,
        provider: Admindailysheetbillcontext,
      },
      {
        component: Departmentsalescount,
        path: "/admin/dailysheet/salsecount",
        subMenu: false,
        title: "Salse count",
        key: "28",
        showmenucomponent: true,
        provider: Departmentsalsecountcontext,
      },
      {
        component: AdminDailysheetCashdetails,
        path: "/admin/dailysheet/cashdetails",
        subMenu: false,
        title: "Cashdetails",
        key: "/admin/dailysheet/cashdetails",
        showmenucomponent: true,
        provider: Admincashdetailscontext,
      },
      {
        component: AdminDailysheetfoodwastage,
        path: "/admin/dailysheet/foodwastage",
        subMenu: false,
        title: "food wastage",
        key: "/admin/dailysheet/foodwastage",
        showmenucomponent: true,
        provider: Adminfoodwastagecontext,
      },
      {
        component: AdminDailysheetoilconsumption,
        path: "/admin/dailysheet/oilconsumption",
        subMenu: false,
        title: "Oil consumption",
        key: "/admin/dailysheet/oilconsumption",
        showmenucomponent: true,
        provider: Adminoilconsumptioncontext,
      },
    ],
  },
];

export default routes;
