import React, { useState, useEffect } from 'react';
import { Button, Form, Input, InputNumber, Select, message } from 'antd';
import moment from 'moment';
import { DeleteTwoTone, CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { post_sales_count_create_api, get_user_role_list_api } from '../../handler/api_handler';
import { validNumber } from '../../handler/helpers.js';

const Departmentsalescountcreateform = (props) => {

	const { Option } = Select;

	const InitialData = { product: "", quantity: 1 };
	const [Lising, setLising] = useState([]);
	const [Currentdate, setCurrentdate] = useState(moment().format('YYYY-MM-DD').toString());
	const [Selectedperson, setSelectedperson] = useState("");
	const [Selecteddepartment, setSelecteddepartment] = useState("");
	const [Alldepartmentlist, setAlldepartmentlist] = useState([]);


	const loopProduct = (data) => {
        let Newarray = [];

        data.forEach((item) => { 
            let Newobj = { 
                product: item.id , 
                productname : item.name,
                quantity: 0
            };

            Newarray.push(Newobj);
        });
        setLising(Newarray);
    }

	const onQuantirychange = (quantity, index) => {
		if (!isNaN(quantity)) {
			let Listingdata = [...Lising];
			Listingdata[index].quantity = quantity;
			setLising(Listingdata);
		}
	}

	const Addnewrow = () => {
		let Listingdata = [...Lising];
		Listingdata.push(InitialData);
		setLising(Listingdata);
	}

	const Resetrow = () => {
		setSelectedperson(""); setSelecteddepartment("");
		loopProduct(props.Allproductlist);
	}

	const Removerow = (index) => {
		let Listingdata = [...Lising];
		Listingdata.splice(index, 1);
		setLising(Listingdata);
	}

	const Formsubmit = () => {
		let isSubmit = true;

		if (Selectedperson === "") {
			message.warning('Please select employee'); return false;
		}

		if (Selecteddepartment === "") {
			message.warning('Please select department'); return false;
		}

		if (Lising.length === 0) {
			message.warning('Please add quantity'); return false;
		}

		let Newarrayobj = [...Lising].filter(item => Number(item.quantity) !== 0);

		Newarrayobj.forEach((list) => {
			if (!validNumber(list.quantity)) {
				isSubmit = false;
				return false;
			}
		});

		if (isSubmit) {
			let SubmitData = Newarrayobj.map((item) => {
				item.date = Currentdate + 'T00:00:00';
				item.employee = Selectedperson;
				item.section = Selecteddepartment;
				return item
			});

			post_sales_count_create_api(SubmitData).then(({ data }) => {
				props.callTableapi();
				props.hideChildrenDrawer();
				Resetrow();
			}).catch((err) => {

			});
		} else {
			message.warning('Please fill all fields with valid data');
		}

	}

	const onProductChange = (productid, index) => {
		const isExist = productid !== "" ? Lising.some(el => el.product === productid) : false;
		if (!isExist) {
			let Listingdata = [...Lising];
			Listingdata[index].product = productid;
			setLising(Listingdata);
		} else {
			message.warning('Product already exist');
			let Listingdata = [...Lising];
			Listingdata[index] = InitialData;
			setLising(Listingdata);
		}
	}

	const onEmployeechange = (userid) => {
		setSelectedperson(userid);
		call_user_role(userid);
	}

	const call_user_role = (userid) => {
		get_user_role_list_api(userid).then(({ data }) => {
			setSelecteddepartment("");
			setAlldepartmentlist(data);
		}).catch(err => {

		});
	}

	useEffect(() => {
		Resetrow();
	}, [props.childrenDrawer]);

	return (
		<div className="main_top">


			<Select
				value={Selectedperson}
				width={200}
				onChange={(value) => { onEmployeechange(value); }}
			>
				<Option value="" > Select Employee</Option>
				{
					props.Branchspecificuserlist.map((item) => <Option value={item.id}>{item.first_name}</Option>)
				}
			</Select>


			<Select
				value={Selecteddepartment}
				width={200}
				style={{ marginLeft: 10 }}
				onChange={(value) => { setSelecteddepartment(value) }}
			>
				<Option value="" > Select Department</Option>
				{
					Alldepartmentlist.map((item) => <Option value={item.id}>{item.name}</Option>)
				}
			</Select>


			<table className="inventory_table">

				<tr style={{ textAlign: 'center' }}>
					<th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>Product Name</th>
					<th style={{ padding: '8px 8px 8px 15px', textAlign: 'left', backgroundColor: '#fafafa', color: '#000' }}>Quantity</th>
				</tr>

				{Lising.map((item, index) => {
					return (
						<tr key={"Table" + index}>
							<td>{item.productname}</td>
							<td><InputNumber onChange={(value) => {
								onQuantirychange(value, index)
							}} min={0} value={item.quantity} /></td>
						</tr>
					)
				})}

			</table>

			<div style={{ width: '100%', float: 'left', backgroundColor: '#fff', padding: '20px' }}>
				<div className="edit_button_block">
					{/* <Button onClick={Addnewrow} style={{ marginRight: '20px' }}><PlusCircleOutlined /> Add Row </Button> */}
					<Button onClick={Formsubmit}><CheckCircleOutlined /> Save </Button>
				</div>
			</div>

		</div>
	);
};

export default Departmentsalescountcreateform;
