import React from 'react';
import { Drawer, InputNumber, Button } from 'antd';

function Denominationtableview(props) {

    return (
        <React.Fragment>
            <table className="inventory_table" style={{ padding: '10px' }}>
                <thead>
                    <tr>
                        <th>Denomination</th>
                        <th></th>
                        <th>Count</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.denominationList.map((item, index) =>
                            <tr>
                                <td>{item.amount}</td>
                                <td> &#215; </td>
                                <td>
                                    <InputNumber min={0} value={item.quantity} onChange={(value) => props.onQuantitychange(value, index)} />
                                </td>
                                <td>{Number(item.quantity) * Number(item.amount)}</td>
                            </tr>
                        )
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td>Total</td>
                        <td></td>
                        <td></td>
                        <td>{props.Total}</td>
                    </tr>
                </tfoot>
            </table>
        </React.Fragment>
    );
}

export default Denominationtableview;