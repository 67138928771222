import React, { useState } from 'react';
import { Button, Modal, Form, Input, Select, Row, Col } from 'antd';
import { post_store_details } from '../../../handler/api_handler';
import { message } from 'antd';
import { PlusOutlined ,CheckCircleOutlined} from '@ant-design/icons';

const Storemodel = ({ visible, onCreate, onCancel, state, onchangeState, city, errors }) => {
	const [ form ] = Form.useForm();

	const { Option } = Select;
	console.log(city, 'value');
	return (
		<Modal
			visible={visible}
			title="Create a new Branch"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						// form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form form={form} layout="vertical" name="form_in_modal" className="edit_form">
				<Row style={{marginBottom: '0'}}>
					<Col span={12}>
						<Form.Item
							name="name"
							label="Name"
							rules={[
								{
									required: true,
									message: 'Please input the name of store!',
									whitespace : true,
								}
							]}
						>
							<Input />
						</Form.Item>
						<h4 style={{color:'red'}}>{errors.name !==undefined? errors.name[0]:""}</h4>
					</Col>
					<Col span={12}>
						<Form.Item
							name="state"
							label="state"
							rules={[
								{
									required: true,
									message: 'Please input the state of store!',
								}
							]}
						>
							<Select
								onChange={onchangeState}
								showSearch
								autocomplete={false}

								placeholder="Search to Select"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							>
								{state.map((state) => {
									return <Option value={state.id} key={"Addstatevalue_"+state.id}>{state.name}</Option>;
								})}
							</Select>
						</Form.Item>
						<h4 style={{color:'red'}}>{errors.state !==undefined? errors.state[0]:""}</h4>
					</Col>
				</Row>

				<Row style={{marginBottom: '0'}}>
					<Col span={12}>
						<Form.Item
							name="city"
							label="city"
							rules={[
								{
									required: true,
									message: 'Please input the city of store!',
								}
							]}
						>
							<Select
								showSearch
								autocomplete={false}

								placeholder="Search to Select"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							>
								{/* {console.log(city)} */}
								{city.map((data) => {
									return <Option value={data.id} key={'Addcity_'+data.id}>{data.name}</Option>;
								})}
							</Select>
						</Form.Item>
						<h4 style={{color:'red'}}>{errors.city !==undefined? errors.city[0]:""}</h4>
					</Col>
					<Col span={12}>
						<Form.Item
							name="pincode"
							label="pincode"
							rules={[
								{
									required: true,
									message: 'Please input the pincode of store!',
									whitespace : true,
								}
							]}
						>
							<Input type="textarea" />
						</Form.Item>
						<h4 style={{color:'red'}}>{errors.pincode !==undefined? errors.pincode[0]:""}</h4>
					</Col>
				</Row>
				<Row style={{marginBottom: '0'}}>
					<Col span={12}>
						<Form.Item
							name="latitude"
							label="latitude"
							rules={[
								{
									required: true,
									message: 'Please input the latitude of store!',
									whitespace : true,
								}
							]}
						>
							<Input type="textarea" />
						</Form.Item>
						<h4 style={{color:'red'}}>{errors.latitude !==undefined? errors.latitude[0]:""}</h4>
					</Col>
					<Col span={12}>
						<Form.Item
							name="longitude"
							label="longitude"
							rules={[
								{
									required: true,
									message: 'Please input the longitude of store!',
									whitespace : true,
								}
							]}
						>
							<Input type="textarea" />
						</Form.Item>
						<h4 style={{color:'red'}}>{errors.longitude !==undefined? errors.longitude[0]:""}</h4>
					</Col>
				</Row>
				<Row style={{marginBottom: '0'}}>
					<Col span={12}>
						<Form.Item
							name="address"
							label="address"
							rules={[
								{
									required: true,
									message: 'Please input the address of store!',
									whitespace : true,
								}
							]}
						>
							<Input type="textarea" />
						</Form.Item>
						<h4 style={{color:'red'}}>{errors.address !==undefined? errors.address[0]:""}</h4>
					</Col>

				</Row>



			</Form>
		</Modal>
	);
};

const Addstoremodel = (props) => {
	const [ visible, setVisible ] = useState(false);
	const [ errors, setErrors ] = useState([]);
	const onCreate = (values) => {
		post_store_details(values)
			.then(() => {
				message.success('Added successfully');
				props.setdata();
				setVisible(false);
			})
			.catch((err) => {
				setErrors(err.response.data);
			});
	};
	console.log(visible);
	return (
		<div className="main_top">
			<h2>Branch</h2>
			<div className="bottom_border"></div>
			<Button
				className="addbtn"
				style={{ marginTop: 0, marginRight: '0' }}
				// style={{"float":"inline-end"}}

				onClick={() => {
					// alert("yygui")
					setVisible(true);
				}}
			>
				<PlusOutlined />
				Add Branch
			</Button>
			<Storemodel
				visible={visible}
				onCreate={onCreate}
				state={props.state}
				errors={errors}
				setErrors={setErrors}
				city={props.city}
				onchangeState={props.onchangeState}
				onCancel={() => {
					setVisible(false);

				}}
			/>
		</div>
	);
};

export default Addstoremodel;
