import React from 'react';
import { Drawer } from 'antd';
import Creditstatementtable from './CreditStatementtable';
import { DailysheetContext } from '../../context/Dailysheetcontext.js';

class Ebbill extends React.Component {
	state = { visible: true, childrenDrawer: false };

	static contextType = DailysheetContext;

	showDrawer = () => {
		this.setState({
			visible: false
		});
	};

	onClose = () => {
		this.context.callDailysheetapi();
		this.props.setCreditStatementdrawerVisible(!this.props.CreditStatementdrawerVisible);
	};

	render() {
		return (
			<React.Fragment>
				<Drawer
					title="Credit settlement"
					width={'100%'}
					closable={true}
					onClose={this.onClose}
					visible={this.props.CreditStatementdrawerVisible}
				>
					<div>
						<Creditstatementtable visible={this.props.CreditStatementdrawerVisible} />
					</div>
					
				</Drawer>
			</React.Fragment>
		);
	}
}

export default Ebbill;
