import React, { useContext } from 'react';
import { DatePicker, Select, Tabs } from 'antd';
import moment from "moment";
import Billlisttable from "./Billlisttable";
import {Admindailysheetbillcontext} from "../../../src//context/Admindailysheetbillcontext";



function BillList(props) {


    const Billcontext = useContext(Admindailysheetbillcontext);
    const { Option } = Select;
    const { TabPane } = Tabs;

    return (
        <React.Fragment>
            <div className="main_top">
                <h2>Bill list</h2>
                <div className="bottom_border"></div>
            </div>

            <DatePicker
                defaultValue={moment(Billcontext.SelectedDate, Billcontext.Dateformat)} format={Billcontext.Dateformat}
                onChange={(value, event) => Billcontext.onDatechange(value, event)}
            />

            <Select
                value={Billcontext.SelectedBranch}
                showSearch
                style={{ width: 150, marginLeft: 20 }}
                onChange={Billcontext.onBranchchange}
            >
                <Option value="">Select Branch</Option>
                {Billcontext.BranchList.map((item) => <Option value={item.id}>{item.name}</Option>)}
            </Select>

            <Tabs defaultActiveKey={Billcontext.SelectedTab} onChange={Billcontext.onTabchange} className="Tab_dailysheet">
                <TabPane tab="Wrong Bill" key="1"></TabPane>
                <TabPane tab="Free Bill" key="2"></TabPane>
                <TabPane tab="EB Bill" key="3"></TabPane>
            </Tabs>

            <Billlisttable column={Billcontext.Selectedcolumn} data={Billcontext.Tabledata}/>
        </React.Fragment>
    );
}

export default BillList;