import React from 'react';
import { Drawer } from 'antd';
import Billtablecomponent from './BillTableComponent';

class Drawerdual extends React.Component {
	state = { visible: true, childrenDrawer: false };

	showDrawer = () => {
		this.setState({
			visible: false
		});
	};

	onClose = () => {
		this.props.setdrawerdrawerdualVisible(!this.props.drawerdualVisible);
		this.props.callDailysheetapi();
	};

	render() {
		return (
			<React.Fragment>
				<Drawer
					title="Wrong Bill"
					width={'100%'}
					closable={true}
					onClose={this.onClose}
					visible={this.props.drawerdualVisible}
				>
					<div>
						<Billtablecomponent visible={this.props.drawerdualVisible} />
					</div>
					
				</Drawer>
			</React.Fragment>
		);
	}
}

export default Drawerdual;
