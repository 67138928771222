import React, { createContext, useState, useEffect } from 'react';
import {get_store_branch} from '../handler/api_handler';
import { useParams } from "react-router-dom";

export const BranchContext = createContext();

const BranchContextProvider = (props) => {
    const [Branch, setBranch] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState([]);

    useEffect(() => {
        setBranchData();
    }, [])

    let {storeid} = useParams();


    const setVisibles = () => {
        setVisible(!visible);
    }

    const setBranchData = () => {
// console.log('location',storeid);
        get_store_branch(storeid).then((details) => {
           
            // setBranch(details.length === undefined ?[details] :details);
            setBranch(details.data);

            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <BranchContext.Provider value={{ Branch, loading,storeid, setBranchData, setVisibles, visible, updateData, setUpdateData }}>
            {props.children}
        </BranchContext.Provider>
    );
};

export default BranchContextProvider;