import React from 'react';
import { Button, Table, Tag } from 'antd';

function TableComponent(props) {
    return (
        <div>
            <Table pagination={false} columns={props.columns} dataSource={props.data} bordered style={{width: '800px', marginTop: '30px', float: 'left'}} />
        </div>
    );
}

export default TableComponent;