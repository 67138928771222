import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio , InputNumber} from 'antd';
import { message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { post_free_bill_api, branch_specific_wrongbill_api } from '../../handler/api_handler';

const Ebbillcreateform = (props) => {

	const [form] = Form.useForm();

	const onsubmit = (data) => {
		data.date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		post_free_bill_api(data).then(() => {
			props.callTableapi();
			props.onChildrenDrawerClose();
			form.resetFields();
		}).catch(({ response }) => {
			const { data, status } = response;
			if (status === 400) {
				message.error(data.bill_no[0]);
			}
		});
	};

	return (
		<div className="main_top">
			<Form
				form={form}
				className="edit_form"
				onFinish={onsubmit} layout="horizontal" name="form_in_modal">
				<Form.Item
					name="bill_no"
					label="bill no"
					rules={[
						{
							required: true,
							message: 'Please input the bill no!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="amount"
					label="Amount"
					rules={[
						{
							required: true,
							message: 'Please input the amount of bill!'
						}
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="description"
					label="Remark"
					rules={[
						{
							required: true,
							message: 'Please input the description of Bill!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default Ebbillcreateform;
