import API from "./axios_handler";

export function login_api(values) {
  return API.post("login/", values);
}

export function get_store_details() {
  return API.get("branch/");
}

export function get_user_by_store() {
  return API.get("store_user/");
}
export function post_store_details(values) {
  return API.post("branch/", values);
}

export function update_store_details(data) {
  return API.patch(`branch/${data.id}/`, data);
}

export function delete_store_details(id) {
  return API.delete(`branch/${id}/`);
}

export function status_store_update(id, status) {
  return API.patch(`branch/${id}/`, { status: !status });
}

export function get_role_details() {
  return API.get("role_list/");
}

export function post_role_details(values) {
  return API.post("role/", values);
}

export function post_user_details(values) {
  return API.post("user/", values);
}

export function delete_role_details(id) {
  return API.delete(`role/${id}/`);
}

export function update_role_details(data) {
  return API.patch(`role/${data.id}/`, data);
}

export function get_product_type() {
  return API.get("department_list");
}

export function get_product_details() {
  return API.get("store_product");
}

export function get_product_classification() {
  return API.get("classification_list");
}

export function update_classification_list_details(data) {
  return API.patch(`classification_list/${data.id}/`, data);
}

export function get_all_product_category() {
  return API.get("all_category");
}

export function post_product_category(data) {
  return API.post("product_category/", data);
}

export function delete_category_details(id) {
  return API.delete(`product_category/${id}/`);
}

export function status_category_update(id, status) {
  return API.patch(`product_category/${id}/`, { status: !status });
}

export function get_product_unit() {
  return API.get("unit_list");
}

export function post_product_details(data) {
  return API.post("store_product/", data);
}

export function delete_product_details(id) {
  return API.delete(`store_product/${id}/`);
}

export function status_product_toggle(id) {
  return API.patch(`store_product/${id}/`);
}

export function status_product_update(id, status) {
  return API.patch(`store_product/${id}/`, { status: !status });
}

// export function update_product_details(data) {
// 	return API.patch(`store_product/${data.id}/`, data);
// }

export function get_complaint_details() {
  return API.get("complaint");
}

export function post_complaint_details(data) {
  return API.post("complaint/", data);
}

export function get_complaint_status() {
  return API.get("complaint_status");
}

export function get_complaint_type() {
  return API.get("complaint_type");
}

export function getUsers(BranchId) {
  return API.get(`user_list/${BranchId}/`);
}

export function getalladminUsers() {
  return API.get(`admin_user`);
}

export function getUsersbyId(id) {
  return API.get(`user/${id}/`);
}

export function getBilledfor() {
  return API.get("billedfor/");
}

export function delete_user_details(id) {
  return API.delete(`user/${id}/`);
}

export function update_user_details(data) {
  return API.patch(`user/${data.id}/`, data);
}

export function get_Unit_details() {
  return API.get("unit_list/");
}

export function delete_Unit_details(id) {
  return API.delete(`unit_list/${id}/`);
}

export function update_Unit_details(data) {
  return API.patch(`unit_update/${data.id}/`, data);
}

export function post_Unit_details(values) {
  return API.post("unit_list/", values);
}

export function get_Type_details() {
  return API.get("department_list/");
}

export function delete_Type_details(id) {
  return API.delete(`product_type/${id}/`);
}

export function post_Type_details(values) {
  return API.post("department_list/", values);
}

export function update_Type_details(data) {
  return API.patch(`department_list/${data.id}/`, data);
}

export function get_store_branch(id) {
  return API.get(`sub_branch/${id}/`);
}

export function update_branch_status(id, status) {
  return API.patch(`sub_branch_update/${id}/`, { status: !status });
}

export function delete_branch_details(id) {
  return API.delete(`sub_branch/${id}/`);
}

export function post_branch_details(id, data) {
  return API.patch(`sub_branch_create/${id}/`, data);
}

export function update_branch_details(data) {
  return API.patch(`sub_branch_update/${data.id}/`, data);
}

export function get_state_details() {
  return API.get(`states/`);
}

export function post_check_in_details(data) {
  return API.post(`user_in_attendance/`, data);
}

export function post_check_out_details(id, data) {
  return API.patch(`user_out_attendance/${id}/`, data);
}

export function post_user_in_attendance_break_details(data) {
  return API.post(`user_in_attendance_break/`, data);
}

export function post_break_out_details(id, data) {
  return API.patch(`user_out_attendance_break/${id}/`, data);
}

export function get_city_details(id) {
  return API.get(`cities/${id}/`);
}
export function get_product_mapping_details(id) {
  return API.get(`product_list_mapping/${id}`);
}

export function put_product_mapping_details(id, data) {
  return API.post(`product_mapping_create/`, data);
}

export function get_attendance_details(date) {
  return API.get(`attendance_list/${date}`);
}

export function post_attendance_details(data) {
  return API.post(`attendance_bulk_create/`, data);
}

export function delete_Bulkorder_details(id) {
  return API.delete(`bulk_order/${id}/`);
}

export function post_Bulkorder_details(values) {
  return API.post("bulk_order/", values);
}

export function update_Bulkorder_details(data) {
  return API.patch(`bulk_order/${data.id}/`, data);
}

export function get_Bulkorder_details() {
  return API.get("bulk_order/");
}

export function delete_Freebill_details(id) {
  return API.delete(`free_bill/${id}/`);
}

export function post_Freebill_details(values) {
  return API.post("free_bill/", values);
}

export function update_Freebill_details(data) {
  return API.patch(`free_bill/${data.id}/`, data);
}

export function get_Freebill_details() {
  return API.get("free_bill/");
}

export function get_Freebill_customer() {
  return API.get("free_bill_customer/");
}

export function delete_Wrongbill_details(id) {
  return API.delete(`role/${id}/`);
}

export function post_Wrongbill_details(values) {
  return API.post("wrong_bill/", values);
}

export function update_Wrongbill_details(data) {
  return API.patch(`wrong_bill/${data.id}/`, data);
}

export function getWrongbills() {
  return API.get("wrong_bill/");
}

export function getOrderstatus() {
  return API.get("order_status/");
}

export function filterFreebill(data) {
  // console.log('Request ata', data);
  return API.get(
    "store_specific_wrongbill/" +
      data.currentStore +
      "/?start=" +
      data.startDate +
      "&stop=" +
      data.toDate
  );
}
// store_specific_wrongbill/0/?start=1996-01-25&stop=2021-02-09
export function get_gst_details() {
  return API.get("gst");
}
export function get_paymentmode_details() {
  return API.get("payment_mode");
}

export function post_complaint_status_details(data) {
  return API.post("complaint_status/", data);
}

export function update_complaint_status_details(data) {
  return API.patch(`complaint_status/${data.id}/`, data);
}

export function post_complaint_category_details(data) {
  return API.post("complaint_category/", data);
}
export function update_complaint_category_details(data) {
  return API.patch(`complaint_category/${data.id}/`, data);
}
export function get_complaint_category_details() {
  return API.get(`complaint_category`);
}

export function get_incentive_master_details(BranchId) {
  return API.get(`branch_incentive_list/${BranchId}`);
}

export function update_incentive_master_details(BranchId, data) {
  return API.put(`branch_incentive_update/${BranchId}/`, data);
}

export function update_subrole_details(data) {
  return API.patch(`role_update/${data.id}/`, data);
}

export function get_branch_expenses_details() {
  return API.get(`branch_expenses`);
}

export function get_sales_product_details() {
  return API.get(`product_list/1`);
}

export function get_raw_product_details() {
  return API.get(`product_list/3`);
}

export function update_product_details(data) {
  return API.patch(`product/${data.id}/`, data);
}

export function get_operational_product_details() {
  return API.get(`product_list/2`);
}

export function get_admin_vegitable_product_list() {
  return API.get(`product_list/4`);
}

export function get_vegitable_product_list() {
  return API.get(`branch_product/4`);
}

export function get_branch_user(BranchId) {
  return API.get(`user_list/${BranchId}`);
}

export function get_particular_user_salary_list(UserId) {
  return API.get(`salary_list/${UserId}`);
}

export function create_particular_user_salary_increment(PostData) {
  return API.post(`user_salary/`, PostData);
}

export function update_particular_user_salary_list(PostData) {
  return API.patch(`user_salary/${PostData.id}/`, PostData);
}

export function create_product(data) {
  return API.post(`product_create/`, data);
}

export function salary_report_listing(salary_report_url) {
  return API.get(salary_report_url);
}

export function salary_user_report(salary_user_report_url) {
  return API.get(salary_user_report_url);
}

export function salary_attendance_user_report_api(
  salary_attendance_user_report_url
) {
  return API.get(salary_attendance_user_report_url);
}

export function previous_date_api() {
  return API.get("previous_date/");
}

export function user_punch_attendance_api(data) {
  return API.patch(`user_punch_attendance/`, data);
}

export function export_report_attendance_api(data, BranchId) {
  console.log(BranchId);

  window.open(
    process.env.REACT_APP_BASE_URL +
      "/salary_attendance_user_report_list_to_excel/" +
      BranchId +
      data[0] +
      "/" +
      data[1] +
      "/"
  );
  //   return API.get(
  //     "/salary_attendance_user_report_list_to_excel/" +
  //       "1/" +
  //       data[0] +
  //       "/" +
  //       data[1] +
  //       "/"
  //   );
}

export function product_maping_get_api(classification, product) {
  return API.get(`product_mapping_list/${classification}/`, product);
}

export function get_store_product_api(id) {
  return API.get(`branch_product/${id}/`);
}
export function delete_store_product_api(id) {
  return API.delete(`product_mapping_delete/${id}/`);
}
export function get_vendor_list_api(data) {
  return API.get(`vendor/`, data);
}

export function create_vendor_api(data) {
  return API.post(`vendor/`, data);
}

export function update_vendor_api(data) {
  return API.patch(`vendor/${data.id}/`, data);
}

export function get_daily_sheet_structire_api(date) {
  return API.get(`daily_sheet/${date}/`);
}

export function get_product_inventory_control_api(date, id) {
  return API.get(`product_inventory_control/${date}/${id}/`);
}

export function post_product_inventory_control_create_api(date, data) {
  return API.post(`product_inventory_control_create/${date}/`, data);
}

export function combined_product_api() {
  return API.get(`branch_product/0`);
}

export function store_product_instock_count_api(productid) {
  return API.get(`store_product_instock_count/${productid}/`);
}

export function branch_product_inventory_create_api(Formdata) {
  return API.post(`branch_product_inventory_create/`, Formdata);
}

export function branch_product_inventory_list_api(Apiurl) {
  return API.get(Apiurl);
}

export function branch_specific_wrongbill_api(date) {
  return API.get(`branch_specific_wrongbill/${date}`);
}

export function post_branch_specific_wrongbill_api(data) {
  return API.post(`wrong_bill/`, data);
}

export function patch_branch_specific_wrongbill_api(data) {
  return API.patch(`wrong_bill/${data.id}/`, data);
}

export function get_oil_consumption_list_api(date) {
  return API.get(`oil_consumption_list/${date}/`);
}

export function post_oil_consumption_api(data) {
  return API.post(`oil_consumption_create/`, data);
}

export function patch_oil_consumption_api(data) {
  return API.patch(`oil_consumption_update/${data.id}/`, data);
}

export function get_specific_freebill_list_api(date) {
  return API.get(`branch_specific_freebill/${date}/`);
}

export function post_free_bill_api(data) {
  return API.post(`free_bill/`, data);
}

export function patch_free_bill_api(data) {
  return API.patch(`free_bill/${data.id}/`, data);
}

export function get_raw_operational_product_list_api() {
  return API.get(`raw_operational_product_list`);
}

export function get_food_wastage_list_api(date) {
  return API.get(`food_wastage_list/${date}/`);
}

export function post_foodwastage_api(data) {
  return API.post(`foodwastage_bill/`, data);
}

export function get_electric_bill_meter_list_list_api(date) {
  return API.get(`electric_bill_meter_list/${date}/`);
}
export function create_electric_bill_meter_list_list_api(date, data) {
  return API.post(`electricbill_create/${date}/`, data);
}
export function patch_foodwastage_bill_api(data) {
  return API.patch(`foodwastage_bill/${data.id}/`, data);
}

export function branch_specific_user_list_api() {
  return API.get(`branch_specific_user_list`);
}

export function all_product_list_api() {
  return API.get(`all_product_list`);
}

export function get_sales_count_create_api(id, date) {
  return API.get(`sales_count_list/${date}/${id}/`);
}

export function post_sales_count_create_api(data) {
  return API.post(`sales_count_create/`, data);
}

export function destroy_sales_count_create_api(id) {
  return API.delete(`sales_count_delete/${id}`);
}

export function credit_sale_list_api(date) {
  return API.get(`credit_sale_list/${date}/`);
}
export function credit_sale_customer_list_api(date) {
  return API.get(`credit_sale_customer`);
}

export function credit_sale_create_api(data) {
  return API.post(`credit_sale/`, data);
}

export function credit_sale_update_api(data) {
  return API.patch(`credit_sale/${data.id}/`, data);
}
export function credit_statement_list_api(date) {
  return API.get(`credit_settlement_list/${date}/`);
}

export function credit_satatement_update_api(data) {
  return API.patch(`credit_settlement/${data.id}/`, data);
}

export function credit_statement_create_api(data) {
  return API.post(`credit_settlement/`, data);
}

export function bank_cash_received_details_list_api(date) {
  return API.get(`bank_cash_received_details_list/${date}/`);
}

export function bank_cash_received_details_create_api(data) {
  return API.post(`bank_cash_received_details/`, data);
}

export function bank_cash_received_details_update_api(data) {
  return API.patch(`bank_cash_received_details/${data.id}/`, data);
}

export function get_user_role_list_api(id) {
  return API.get(`user_role_list/${id}/`);
}

export function save_denomination(data) {
  return API.post(`denomination/`, data);
}

export function get_denomination_list(date) {
  return API.get(`denomination_list/${date}`);
}

export function get_cash_management(date) {
  return API.get(`branch_cash_management_list/${date}`);
}

export function saveCashmanagement(data) {
  return API.post(`branch_cash_management/`, data);
}

export function updateCashmanagement(data) {
  return API.patch(`branch_cash_management/${data.id}/`, data);
}
export function patchupdate_denomination(data) {
  return API.patch(`denomination_update/`, data);
}

export function get_cash_handover_list(date) {
  return API.get(`cash_handover_list/${date}`);
}

export function save_cash_handover(data) {
  return API.post(`cash_handover/`, data);
}

export function delete_cash_handover(id) {
  return API.delete(`cash_handover/${id}`);
}

export function petty_cash_list_api(date) {
  return API.get(`petty_cash_list/${date}`);
}

export function opening_petty_cash_api(date) {
  return API.get(`opening_petty_cash_previous_list/${date}`);
}

export function delete_petty_cash_expense_api(id) {
  return API.delete(`petty_cash_remark_delete/${id}`);
}

export function save_petty_cash_api(data) {
  return API.post(`petty_cash/`, data);
}

export function product_inventory_control_list_api(
  date,
  classification,
  branch
) {
  return API.get(
    `product_inventory_control_list/${date}/${classification}/${branch}/`
  );
}

export function get_sub_branch_api(id) {
  return API.get(`sub_branch_list/${id}/`);
}

export function get_ebmeter_api(id) {
  return API.get(`branch_ebmeter_list/${id}/`);
}

export function create_ebmeter_api(id, data) {
  return API.post(`eb_meter_create/${id}/`, data);
}

export function update_ebmeter_api(data) {
  return API.patch(`eb_meter_update/${data.id}/`, data);
}

export function delete_ebmeter_api(id) {
  return API.delete(`eb_meter_destroy/${id}/`);
}

export function Adminbilllist_api(date, branch, type) {
  return API.get(`branch_specific_bill_list/${date}/${branch}/${type}`);
}

export function Creditsalecustomer() {
  return API.get(`credit_sale_customer`);
}

export function Creditsalecustomercreate(data) {
  return API.post(`credit_sale_customer/`, data);
}

export function Creditsalecustomerupdate(data) {
  return API.patch(`credit_sale_customer/${data.id}/`, data);
}

export function Cashdetailslist(date, branch, type) {
  return API.get(`cash_details_list/${date}/${branch}/${type}`);
}

export function product_stock_in_list(classification) {
  return API.get(`product_stock_in_list/${classification}`);
}

export function admin_branch_specific_food_wastage_list(branch, date) {
  return API.get(`branch_specific_food_wastage_list/${date}/${branch}/`);
}

export function admin_branch_specific_oil_consumption_list(date, branch) {
  return API.get(`branch_specific_oil_consumption_list/${date}/${branch}/`);
}

export function fetch_sales_product_from_slick_pos() {
  return API.get(`slickpos_product_fetch`);
}

export function branch_expenses() {
  return API.get(`branch_expenses`);
}

export function fetch_vendor_category() {
  return API.get(`vendor_category`);
}
