import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Row,
  Col,
} from "antd";
// import {addRawproducts} from '../../../handler/LocalDB_handler/RawproductServiceHandler'
import { create_product } from "../../../handler/api_handler";
import { message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const Rawproductmodel = ({ visible, onCreate, onCancel, unit, department }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Create Raw Material"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        className="edit_form"
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <Row style={{ marginBottom: "0" }}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input product name!",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="unit" label="unit">
              <Select>
                {unit.map((data) => {
                  return <Option value={data.id}>{data.name}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: "0" }}>
          <Col span={12}>
            <Form.Item
              name="reorder_level"
              label="reorder level"
              rules={[
                {
                  required: true,
                  message: "Please input reorder level!",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const AddRawproductmodel = (props) => {
  const [visible, setVisible] = useState(false);

  const onCreate = (values) => {
    values.classification = 3;
    create_product(values)
      .then(() => {
        alert(" alert(err)");
        message.success("Added successfully");
        props.setdata();
      })
      .catch((err) => {
        alert(err);
      });
    setVisible(false);
  };

  return (
    <div className="main_top">
      <h2>Raw Material</h2>
      <div className="bottom_border"></div>
      <Button
        className="addbtn"
        style={{ marginTop: 0, marginRight: "0" }}
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      >
        <PlusOutlined />
        Add Raw Material
      </Button>
      <Rawproductmodel
        visible={visible}
        unit={props.unit}
        department={props.department}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

export default AddRawproductmodel;
