import React, { Component } from 'react';
import { SearchOutlined, EditTwoTone, DeleteTwoTone, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { ProductContext } from '../../context/Productcontext';
import Addproductmodel from './AddProductModel';
import Updateproductmodel from './UpdateProductModel';
import { delete_product_details, status_product_update } from '../../handler/api_handler';

class ProductComponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};
	static contextType = ProductContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [ e.target.value ] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[ this.state.searchText ]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};
	handleStatus = (id, status) => {
		status_product_update(id, status)
			.then(() => {
				this.context.setProductData();
			})
			.catch(() => {});
	};

	handleOk = (id) => {
		delete_product_details(id)
			.then(() => {
				this.context.setProductData();
			})
			.catch(() => {});
	};

	render() {
		const { Product, setProductData, category, unit, type } = this.context;
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'id',
				width: '30%',
				...this.getColumnSearchProps('name')
			},
			{
				title: 'Product_category',
				dataIndex: 'category',
				key: 'id',
				width: '20%',
				// ...this.getColumnSearchProps('Product_category'),
				render: (data, record) => {
					return record.category.name;
				}
			},
			{
				title: 'unit',
				dataIndex: 'product_unit',
				key: 'id',
				render: (data, record) => {
					return record.product_unit.name;
				}
			},
			{
				title: 'item_type',
				dataIndex: 'product_type',
				key: 'id',
				// ...this.getColumnSearchProps('item_type'),
				render: (data, record) => {
					return record.product_type.name;
				}
			},

			{
				title: 'status',
				dataIndex: 'status',
				key: 'id',
				render: (text, record) => (
					<Space>
						<Popconfirm
							title="Are you sure to change status？"
							onConfirm={() => this.handleStatus(record.id, record.status)}
							okText="Yes"
							cancelText="No"
						>
							{record.status ? (
								<EyeOutlined style={{ fontSize: '20px', color: 'blue' }} />
							) : (
								<EyeInvisibleOutlined style={{ fontSize: '20px', color: 'red' }} />
							)}
						</Popconfirm>
					</Space>
				)
			},

			{
				title: 'Action',
				dataIndex: 'action',
				key: 'id',
				render: (text, record) => (
					<Space>
						<Updateproductmodel
							text={text}
							product_data={record}
							setdata={setProductData}
							unit={unit}
							category={category}
							type={type}
						/>

						<Popconfirm
							title="Are you sure to delete？"
							onConfirm={() => this.handleOk(record.id)}
							okText="Yes"
							cancelText="No"
						>
							<DeleteTwoTone style={{ fontSize: '20px' }} />
						</Popconfirm>
					</Space>
				)
			}
		];
		return (
			<React.Fragment>
				<Addproductmodel setdata={setProductData} unit={unit} category={category} type={type} />
				<Spin spinning={this.context.loading} delay={500}>
					<Table columns={columns} dataSource={Product} />
				</Spin>
			</React.Fragment>
		);
	}
}

export default ProductComponent;
