import React, { Component } from 'react';
import { SearchOutlined, EditTwoTone, DeleteTwoTone, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Table, Input, Button, Space, Popconfirm, Spin, Skeleton } from 'antd';
import Highlighter from 'react-highlight-words';
import { CategoryContext } from '../../context/Categorycontext';
import AddCategorymodels from '../Category/AddCategoryModel/';
import { delete_category_details, status_category_update } from '../../handler/api_handler';
import Updatemodelcomponent from '../../components/Category/UpdateCategoryModel';

class Categorycomponent extends Component {
	state = {
		searchText: '',
		searchedColumn: ''
	};
	static contextType = CategoryContext;

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [ e.target.value ] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"

					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small">
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[ this.state.searchText ]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	handleStatus = (id, status) => {
		status_category_update(id, status).then((

        ) => {
            this.context.setCategoryData();
        }).catch(() => {});
	};

	handleOk = (id) => {
		delete_category_details(id)
			.then(() => {
				this.context.setCategoryData();
			})
			.catch(() => {});
	};

	render() {
		const { Category, loading, setCategoryData, setVisibles, visible } = this.context;
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name'
			},

			{
				title: 'description',
				dataIndex: 'description',
				key: 'description'
			}
		];
		return (
			<React.Fragment>
				<AddCategorymodels setdata={setCategoryData} />
				<Spin spinning={loading} delay={500}>
					<Table columns={columns} dataSource={Category} bordered style={{width: '600px'}} pagination={false}/>
				</Spin>
			</React.Fragment>
		);
	}
}

export default Categorycomponent;
