import React, { Component } from 'react';

class DisplayPriceComponent extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    (this.props.priceValue !== "" && this.props.priceValue !== null) ? (<React.Fragment> &#8377; {this.props.priceValue} </React.Fragment>) : (<React.Fragment> &#8377; 0 </React.Fragment>)
                }
            </React.Fragment>

        );
    }
}

export default DisplayPriceComponent;